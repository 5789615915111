import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Select from 'components/molecules/Select'
import { FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { FormProps, FormValues } from './index'

export const StyledFields = styled.fieldset`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  text-align: left;
  width: 300px;
`
export const StyledName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
export const StyledEmail = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
export const StyledSeats = styled(Select)`
  width: 100px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const StyledIndustry = styled(Select)`
  width: 300px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const StyledModalTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  && {
    width: 50%;
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NewPartnerForm = ({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onClose,
}: FormProps & FormikProps<FormValues>): React.ReactElement => (
  <form
    onSubmit={handleSubmit}
    noValidate
    autoComplete="off"
    className={className}>
    <StyledModalTitle variant="h2">New partner</StyledModalTitle>
    <StyledFields>
      <StyledName
        id="title"
        name="title"
        label="Partner name"
        type="text"
        value={values.title || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.title && !!errors.title}
        helperText={touched.title ? errors.title : ''}
        fullWidth
      />
    </StyledFields>
    <StyledActions>
      <StyledButton
        size="small"
        variant="outlined"
        color="primary"
        onClick={onClose}>
        Cancel
      </StyledButton>
      <StyledButton
        size="small"
        variant="contained"
        type="submit"
        color="primary">
        {'Create'}
      </StyledButton>
    </StyledActions>
  </form>
)

export default NewPartnerForm
