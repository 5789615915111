import { Input } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import { useUserMessages } from '../../../hooks/useUserMessages'
import ProgressLoader from '../../atoms/ProgressLoader'

import { InnerFormProps } from './index'

const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.mdlg}px;
  display: flex;
  justify-content: space-between;
`

export const Date = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const VerticallyCentered = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`

const Reply = styled.div`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`

const ReplyLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    font-size: 11px;
  }
`

const ReplyTextArea = styled(TextField)`
  div {
    padding-top: 0;
  }
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }
`

export const CharCount = styled(Typography)`
  && {
    margin-left: auto;
    display: flex;
    align-self: end;
    font-size: 8px;
  }

  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ActionsLeft = styled.div``
const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child:last-child {
        /* this is a more specific only-child, it will win for a single button */
        margin-left: auto;
        width: 149px;
      }
    }
  }
`

export function AdminMessageForm({
  data,
  onCancel,
  onSuccess,
}: InnerFormProps): React.ReactElement {
  const {
    sendRequest: { data: newMessage, isLoading, error },
    sendMessage,
  } = useUserMessages()

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    resetForm,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      userFingerprint: data?.userFingerprint || '',
      message: '',
    },
    onSubmit: (values) => {
      sendMessage({
        message: values.message,
        userFingerprint: values.userFingerprint,
      })
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required('Please enter your reply')
        .max(700, 'Please keep your message within the 700 character limit'),
    }),
  })

  useEffect(() => {
    if (newMessage) {
      onSuccess()
      resetForm()
      setValues({ userFingerprint: '', message: values.message })
    }
  }, [onSuccess, resetForm, newMessage, setValues, values.message])

  return (
    <>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        {data?.userFingerprint ? (
          <Typography>
            Sending to {data?.userFingerprint.substring(0, 7)}.
          </Typography>
        ) : (
          <>
            Fingerprint (only one a time please):{' '}
            <Input
              id="userFingerprint"
              name="userFingerprint"
              type="text"
              value={values.userFingerprint}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.userFingerprint && !!errors.userFingerprint}
              fullWidth
              disabled={isSubmitting}
            />
          </>
        )}
        <Reply>
          <VerticallyCentered>
            <ReplyLabel variant="body2">Your message</ReplyLabel>
            <CharCount variant="body2">
              Character count: {values.message.length || 0} / 700
            </CharCount>
          </VerticallyCentered>
          <ReplyTextArea
            id="message"
            name="message"
            type="text"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.message && !!errors.message}
            helperText={touched.message ? errors.message : ''}
            fullWidth
            multiline
            rows={6}
            InputProps={{
              disableUnderline: true,
            }}
            disabled={isSubmitting}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            variant={'standard' as any} // Types for textarea are a bit broken
          />
        </Reply>

        {error && (
          <Reply>
            <ErrorText>
              There was an error while sending your message. Please check the
              fingerprint.
            </ErrorText>
          </Reply>
        )}
        {isLoading ? (
          <ProgressLoader fullWidth />
        ) : (
          <Actions>
            <ActionsLeft />
            <ActionsRight>
              <Button
                variant="outlined"
                type="button"
                color="primary"
                fullWidth
                size="small"
                disabled={isLoading}
                onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                size="small"
                disabled={isLoading || !isValid}>
                Send message
              </Button>
            </ActionsRight>
          </Actions>
        )}
      </form>
    </>
  )
}
