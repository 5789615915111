import SettingsPageContainer from 'components/molecules/SettingsPageContainer'
import ChangePasswordForm from 'components/organisms/ChangePasswordForm'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Password({ handleSubmit }) {
  return (
    <ChnnlDashboardLayout>
      <SettingsPageContainer title="Change password">
        <ChangePasswordForm onSubmit={handleSubmit} />
      </SettingsPageContainer>
    </ChnnlDashboardLayout>
  )
}

Password.propTypes = {
  handleSubmit: PropTypes.func,
}

export default Password
