export const JournalThemingOptions = [
  { label: 'Working on day off', value: 'WORKING_ON_DAY_OFF' },
  {
    label: 'Negative interaction with family friends',
    value: 'NEGATIVE_INTERACTION_WITH_FAMILY_FRIENDS',
  },
  { label: 'Harassment from client', value: 'HARASSMENT_FROM_CLIENT' },
  { label: 'Personal health concerns', value: 'PERSONAL_HEALTH_CONCERNS' },
  { label: 'Fatigue', value: 'FATIGUE' },
  { label: 'Lack of resources', value: 'LACK_OF_RESOURCES' },
  {
    label: 'Negative interactions with co-workers',
    value: 'NEGATIVE_INTERACTIONS_WITH_CO_WORKERS',
  },
  { label: 'Interpersonal conflict', value: 'INTERPERSONAL_CONFLICT' },
  {
    label:
      'Negative interaction with external stakeholder (patient/customer/student)',
    value: 'NEGATIVE_INTERACTIONS_WITH_EXTERNAL_STAKEHOLDER',
  },
  {
    label: 'Negative interactions with leaders',
    value: 'NEGATIVE_INTERACTIONS_WITH_LEADERS',
  },
  { label: 'Uncertainty at work', value: 'UNCERTAINTY_AT_WORK' },
  { label: 'Sleep', value: 'SLEEP' },
  {
    label: 'Concern about workload',
    value: 'CONCERN_ABOUT_WORKLOAD',
  },
  { label: 'Concern about work design', value: 'CONCERN_ABOUT_WORK_DESIGN' },
  {
    label: 'Knowledge skills gap identified',
    value: 'KNOWLEDGE_SKILLS_GAP_IDENTIFIED',
  },
  {
    label: 'Bullying/harassment of others',
    value: 'BULLYING_HARASSMENT_OTHERS',
  },
  {
    label: 'Bullying/harassment personal',
    value: 'BULLYING_HARASSMENT_PERSONAL',
  },
  {
    label: 'Difficult or traumatic experience(s) at work',
    value: 'DIFFICULT_OR_TRAUMATIC_EXPERIENCE_WORK',
  },
  {
    label: 'Sleep concerns',
    value: 'SLEEP_CONCERNS',
  },
  { label: 'Work stress', value: 'WORK_STRESS' },
  {
    label: 'Negative interaction with customer',
    value: 'NEGATIVE_INTERACTION_WITH_CUSTOMER',
  },
  { label: 'Poor health/wellbeing', value: 'POOR_HEALTH_WELLBEING' },
  { label: 'Sadness or concern', value: 'SADNESS_OR_CONCERN' },
  { label: 'Grief or loss', value: 'GRIEF_OR_LOSS' },
  { label: 'Call for help', value: 'CALL_FOR_HELP' },
  { label: 'Other', value: 'OTHER_NEGATIVE' },
  {
    label: 'Low perception of organisation support',
    value: 'LOW_PERCEIVED_ORGANISATIONAL_SUPPORT',
  },
  { label: 'Productivity', value: 'PRODUCTIVITY' },
  {
    label: 'Positive interaction with co-worker/s',
    value: 'POSITIVE_INTERACTION_WITH_CO-WORKERS',
  },
  {
    label: 'Positive experience, training',
    value: 'POSITIVE_EXPERIENCE_TRAINING',
  },
  {
    label:
      'Positive interaction with external stakeholder (patient/customer/student)',
    value: 'POSITIVE_INTERACTIONS_WITH_EXTERNAL_STAKEHOLDER',
  },
  {
    label: 'Positive interaction with leader/s',
    value: 'POSITIVE_INTERACTION_WITH_LEADERS',
  },
  {
    label: 'Exercise (positive)',
    value: 'POSITIVE_INTERACTION_WITH_SPORT_HOBBY_RECREATION',
  },
  {
    label: 'Positive perceived organisation support',
    value: 'POSITIVE_PERCEIVED_ORGANISATIONAL_SUPPORT',
  },
  {
    label: 'Positive interaction with family/friends',
    value: 'POSITIVE_INTERACTION_WITH_FAMILY_FRIENDS',
  },
  {
    label: 'General positive reflection',
    value: 'GENERAL_POSITIVE_REFLECTION',
  },
  {
    label: 'Positive reflection on flexible working',
    value: 'POSITIVE_REFLECTION_ON_FLEXIBLE_WORKING',
  },
  {
    label: 'Positive reflection on study',
    value: 'POSITIVE_REFLECTION_ON_STUDY',
  },
  { label: 'Confidence in skills', value: 'CONFIDENCE_IN_SKILLS' },
  {
    label: 'Positive reflection on work place culture',
    value: 'POSITIVE_REFLECTION_ON_WORK_PLACE_CULTURE',
  },
  {
    label: 'Confidence in skills',
    value: 'CONFIDENCE_IN_SKILLS',
  },
  {
    label: 'Hobby or Activity (positive)',
    value: 'HOBBY_OR_ACTIVITY_POSITIVE',
  },
]
