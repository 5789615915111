import initialState from 'reducers/initialState'

import { API_GET_JOURNAL_REPLY_SUCCESS } from '../journals/actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function notificationsReducer(
  state = initialState.notifications,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  action: any, // TODO: Convert journals/actions to TS
) {
  switch (action.type) {
    case API_GET_JOURNAL_REPLY_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.notifications,
        },
      }

    default:
      return state
  }
}
