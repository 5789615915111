import Dialog from '@weareroam/cake-ui-v1/Dialog'
import React from 'react'
import styled from 'styled-components'

import { CloseCross } from '../../atoms/Icons/CloseCross'

const paperClass = 'paper-class'

const StyledPaper = styled(Dialog).attrs(() => ({
  classes: {
    paper: paperClass,
  },
}))`
  .${paperClass} {
    background: transparent;
    position: relative;
    text-align: center;
    box-shadow: none;
    overflow: visible;
  }
`

const StyledIcon = styled(CloseCross)`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const StyledCloseButton = styled.button`
  position: absolute;
  background: ${({ theme }) => theme.palette.primary.main};
  ${({ disabled }) =>
    disabled &&
    `
    background:  rgba(0, 0, 0, 0.26);
  `}
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  z-index: 5;
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Tabs = styled.div`
  position: absolute;
  transform: translate(0%, -73%);
  z-index: 0;
  top: 0;
  left: 0;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

interface TabProps {
  active?: boolean
}

const Tab = styled.div<TabProps>`
  min-width: 127px;
  height: 66px;
  padding: 14px 15px;
  line-height: 22px;
  font-size: 15px;
  font-weight: 600;
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : theme.palette.tertiary.dark};
  background: ${({ active, theme }) =>
    active ? theme.palette.background.active : theme.palette.background.main};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  position: ${({ active }) => (active ? 'relative' : 'initial')};
  box-shadow: ${({ active }) =>
    active ? '0px 4px 8px rgba(0, 0, 0, 0.16)' : 'none'};
`

const PaperInner = styled.div`
  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.xxl}px;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 8px;
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

interface Props {
  open: boolean
  onClose?: () => void
  children: React.ReactNode
  hasCloseButton?: boolean
  closeButtonDisabled?: boolean
  className?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  // @deprecated use TabbedModal
  tabs?: Array<{ id: string; title: string }>
  activeTab?: string
  handleTabClick?: (id: string) => void
}

export const Modal = ({
  open,
  onClose,
  children,
  hasCloseButton,
  closeButtonDisabled,
  className,
  maxWidth,
  tabs,
  activeTab, // Tab navigation logic comes from the consumer to support varying business rules
  handleTabClick,
}: Props): React.ReactElement => (
  <StyledPaper onClose={onClose} open={open} maxWidth={maxWidth}>
    {tabs && (
      <Tabs>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            active={activeTab === tab.id}
            onClick={() => handleTabClick?.(tab.id)}>
            {tab.title}
          </Tab>
        ))}
      </Tabs>
    )}
    {hasCloseButton && (
      <StyledCloseButton
        type="button"
        disabled={closeButtonDisabled}
        onClick={onClose}>
        <StyledIcon />
      </StyledCloseButton>
    )}
    <PaperInner className={className}>{children}</PaperInner>
  </StyledPaper>
)
