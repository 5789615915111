import { IOrg } from 'models/Organisation'
import * as organisationsActions from 'modules/organisations/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import { RequestProgressState } from 'modules/progress'
import { RootState } from 'modules/RootState'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

import { IProps } from './DistressedUsers.header'

export interface InjectedProps {
  actions: {
    getOrganisations: typeof organisationsActions.getOrganisations
  }
  getOrgsProgress: RequestProgressState
  organisations: IOrg[]
}

export interface DistressedUserOuterProps {
  onOrganistionsChange: (val: string[]) => void
}

// Which props define our new component : IProps - IInjectedProps
type IContainerProps = Omit<IProps, keyof InjectedProps>

export const enhance = compose<IProps, IContainerProps>(
  connect(
    (state: RootState) => ({
      organisations: getOrganisationsList(state),
      getOrgsProgress: state.progress.getOrganisations,
    }),
    (dispatch) => {
      const actions = {
        getOrganisations: organisationsActions.getOrganisations,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
)
