import { JOURNAL_TASK_DATE, JOURNAL_TASK_TIME } from 'constants/date'

import Input from '@material-ui/core/Input'
import Button from '@weareroam/cake-ui-v1/Button'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Autosuggest from 'components/molecules/Autosuggest'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { toTitleCase } from 'utils/formatting'

export const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  justify-content: space-between;
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.md}px;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

const OptionsLeft = styled.div`
  display: flex;
  justify-content: 'flex-start';
`
const OptionsRight = styled.div`
  /* flex-grow: 1; */
  display: flex;
`

export const Date = styled(Typography)`
  && {
    display: flex;
    align-items: center;
  }
`

export const Timezone = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const OriginalEntry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
  text-align: left;
`

export const OriginalEntryLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledTextArea = styled(TextField)`
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }

  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const ActionsLeft = styled.div`
  justify-content: flex-start;
`

export const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const checkboxLabelClass = 'checkbox-label-class'
export const StyledCheckboxControl = styled(FormControlLabel).attrs({
  classes: {
    label: checkboxLabelClass,
  },
})`
  width: 100%;
  font-weight: inherit;
  .${checkboxLabelClass} {
    font-weight: inherit;
    white-space: nowrap;
  }
`

export const IsoCategoryError = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  text-align: left;
`

export const StyledInput = styled(Input)`
  display: flex;
  background-color: white;
  color: #e4e4ea;
  border-top-right-radius: ${({ theme }) => theme.spacing.sm}px;
  border-bottom-right-radius: ${({ theme }) => theme.spacing.sm}px;
  padding: ${({ theme }) => theme.spacing.sm}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function JournalEntryForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onCancel,
  organisationName,
  groupName,
  date,
  createdAt,
  originalEntry,
  onRevert,
  // eslint-disable-next-line react/prop-types
  setFieldValue,
  onAddChip,
  onDeleteChip,
}) {
  const handleOnAutosuggestChange = React.useCallback((e, update) => {
    if (update && update.newValue) {
      if (update.method === 'click') {
        onAddChip(update.newValue)
      } else {
        setFieldValue('textInput', update.newValue)
      }
    } else {
      handleChange(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredOptions = React.useMemo(
    () =>
      values.categoryOptions.filter(
        (t) => !values.tags.some((s) => s.tag === t.value),
      ),
    [values.categoryOptions, values.tags],
  )

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledHeader>
        <Typography variant="body2">
          <strong>{organisationName}</strong> &mdash; {groupName}
        </Typography>
        <Date variant="body2" component="div">
          {format(date, JOURNAL_TASK_DATE)}
          <Timezone variant="body2">
            {format(createdAt, JOURNAL_TASK_DATE)}{' '}
            {format(createdAt, JOURNAL_TASK_TIME)}
          </Timezone>
        </Date>
      </StyledHeader>
      <StyledTextArea
        id="entry"
        name="entry"
        type="text"
        value={values.entry}
        InputProps={{
          disableUnderline: true,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.entry && !!errors.entry}
        helperText={touched.entry ? errors.entry : ''}
        fullWidth
        multiline
        rows={5}
      />
      {originalEntry && (
        <OriginalEntry>
          <OriginalEntryLabel variant="body2">Original</OriginalEntryLabel>
          <Typography variant="body2">{originalEntry}</Typography>
        </OriginalEntry>
      )}
      <Options>
        <OptionsLeft>
          <StyledCheckboxControl
            label="Red flag"
            onChange={() => setFieldValue('redFlagged', !values.redFlagged)}
            control={
              <Checkbox
                value="red"
                color="primary"
                checked={values.redFlagged}
              />
            }
          />
          <StyledCheckboxControl
            label="Yellow flag"
            onChange={() => {
              setFieldValue('yellowFlagged', !values.yellowFlagged)
            }}
            control={
              <Checkbox
                value="yellow"
                color="primary"
                checked={values.yellowFlagged}
              />
            }
          />
        </OptionsLeft>
        <OptionsRight>
          <Autosuggest
            variant="chip"
            value={values.textInput}
            chips={values.tags.map(({ tag }) => toTitleCase(tag))}
            onDeleteChip={onDeleteChip}
            onAddChip={onAddChip}
            error={touched.tags && !!errors.tags}
            onChange={handleOnAutosuggestChange}
            placeholder="Search for a theme.."
            name="textInput"
            onBlur={handleBlur}
            suggestions={filteredOptions}
            noneSelectedHelperText="This theme does not exist"
            hideinput={values.tags.length >= 3}
            labelFormatter={(val) => toTitleCase(val)}
          />
        </OptionsRight>
      </Options>
      <>
        <StyledHeader>
          {touched.tags && errors.tags && (
            <IsoCategoryError variant="body2">{errors.tags}</IsoCategoryError>
          )}
        </StyledHeader>
      </>
      <Actions>
        <ActionsLeft>
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={onRevert}>
            Revert to original
          </Button>
        </ActionsLeft>
        <ActionsRight>
          <Button
            variant="outlined"
            type="button"
            color="primary"
            fullWidth
            size="small"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            size="small">
            Save changes
          </Button>
        </ActionsRight>
      </Actions>
    </form>
  )
}

JournalEntryForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  onCancel: PropTypes.func,
  organisationName: PropTypes.string,
  groupName: PropTypes.string,
  date: PropTypes.string,
  createdAt: PropTypes.string,
  onRevert: PropTypes.func,
  originalEntry: PropTypes.string,
  redFlagged: PropTypes.bool,
  yellowFlagged: PropTypes.bool,
  iso45003Category: PropTypes.string,
  setFieldValue: PropTypes.func,
  onAddChip: PropTypes.func,
  onDeleteChip: PropTypes.func,
}

JournalEntryForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default JournalEntryForm
