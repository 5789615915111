import { TOAST_TYPE_ERROR } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'
import { getApiErrorMessage } from 'utils/api'

import { entitlementListSchema } from './schema'

// Actions
export const API_GET_ENTITLEMENTS_REQUEST = 'API_GET_ENTITLEMENTS_REQUEST'
export const API_GET_ENTITLEMENTS_SUCCESS = 'API_GET_ENTITLEMENTS_SUCCESS'
export const API_GET_ENTITLEMENTS_ERROR = 'API_GET_ENTITLEMENTS_ERROR'

export const API_UPDATE_ENTITLEMENT_REQUEST = 'API_UPDATE_ENTITLEMENT_REQUEST'
export const API_UPDATE_ENTITLEMENT_SUCCESS = 'API_UPDATE_ENTITLEMENT_SUCCESS'
export const API_UPDATE_ENTITLEMENT_ERROR = 'API_UPDATE_ENTITLEMENT_ERROR'

export const API_RESET_ENTITLEMENTS_REQUEST = 'API_RESET_ENTITLEMENTS_REQUEST'
export const API_RESET_ENTITLEMENTS_SUCCESS = 'API_RESET_ENTITLEMENTS_SUCCESS'
export const API_RESET_ENTITLEMENTS_ERROR = 'API_RESET_ENTITLEMENTS_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getEntitlements({ orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ENTITLEMENTS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/entitlement`,
      )
      const data = normalize(response.data.data, entitlementListSchema)
      dispatch({
        type: API_GET_ENTITLEMENTS_SUCCESS,
        data: {
          orgUuid: orgUuid,
          entitlements: data.entities.entitlements,
        },
      })
    } catch (error) {
      dispatch({ type: API_GET_ENTITLEMENTS_ERROR, error })
      dispatch(
        addToast({
          message: `There was an error fetching the organisation entitlements. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateEntitlement({ orgUuid, entitlementUuid, isEnabled }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_ENTITLEMENT_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/entitlement/${entitlementUuid}`,
        {
          isEnabled,
        },
      )

      if (response.data.status !== 200) {
        throw new Error('Error while updating an entitlement.')
      }

      const data = normalize(response.data.data, entitlementListSchema)

      dispatch({
        type: API_UPDATE_ENTITLEMENT_SUCCESS,
        data: {
          orgUuid: orgUuid,
          entitlements: data.entities.entitlements,
        },
      })
    } catch (error) {
      dispatch({ type: API_UPDATE_ENTITLEMENT_ERROR })
      dispatch(
        addToast({
          message: `There was an error updating entitlement. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resetEntitlements({ orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_ENTITLEMENTS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/entitlement/reset`,
      )

      dispatch({ type: API_RESET_ENTITLEMENTS_SUCCESS })

      return response
    } catch (error) {
      dispatch({ type: API_RESET_ENTITLEMENTS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error resetting the organisation entitlements.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
