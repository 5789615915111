import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledEmptyState = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.xxl}px auto;
`
export const StyledTitle = styled(Typography)`
  opacity: 0.2;

  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`
export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const StyledIcon = styled.span``

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function EmptyState({ title, subtitle, text, childComponent, icon }) {
  return (
    <StyledEmptyState>
      {title && <StyledTitle variant="h5">{title}</StyledTitle>}
      {subtitle && <StyledSubtitle variant="h4">{subtitle}</StyledSubtitle>}
      {text && <StyledText variant="body1">{text}</StyledText>}
      {childComponent && (
        <StyledText variant="body1">{childComponent}</StyledText>
      )}
      {icon && <StyledIcon>{icon}</StyledIcon>}
    </StyledEmptyState>
  )
}

EmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  childComponent: PropTypes.node,
  icon: PropTypes.node,
}

export default EmptyState
