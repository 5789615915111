import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { orgCountryListSchema } from './schema'

// Actions
export const API_GET_ORG_COUNTRIES_REQUEST = 'API_GET_ORG_COUNTRIES_REQUEST'
export const API_GET_ORG_COUNTRIES_SUCCESS = 'API_GET_ORG_COUNTRIES_SUCCESS'
export const API_GET_ORG_COUNTRIES_ERROR = 'API_GET_ORG_COUNTRIES_ERROR'

export const API_ADD_ORG_COUNTRY_REQUEST = 'API_ADD_ORG_COUNTRY_REQUEST'
export const API_ADD_ORG_COUNTRY_SUCCESS = 'API_ADD_ORG_COUNTRY_SUCCESS'
export const API_ADD_ORG_COUNTRY_ERROR = 'API_ADD_ORG_COUNTRY_ERROR'

export const API_DELETE_ORG_COUNTRY_REQUEST = 'API_DELETE_ORG_COUNTRY_REQUEST'
export const API_DELETE_ORG_COUNTRY_SUCCESS = 'API_DELETE_ORG_COUNTRY_SUCCESS'
export const API_DELETE_ORG_COUNTRY_ERROR = 'API_DELETE_ORG_COUNTRY_ERROR'

export const API_REORDER_ORG_COUNTRIES_REQUEST =
  'API_REORDER_ORG_COUNTRIES_REQUEST'
export const API_REORDER_ORG_COUNTRIES_SUCCESS =
  'API_REORDER_ORG_COUNTRIES_SUCCESS'
export const API_REORDER_ORG_COUNTRIES_ERROR = 'API_REORDER_ORG_COUNTRIES_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOrgCountries({ orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORG_COUNTRIES_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/countries`,
      )
      const data = normalize(response.data.data, orgCountryListSchema)

      dispatch({
        type: API_GET_ORG_COUNTRIES_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_ORG_COUNTRIES_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the organisation countries.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addOrgCountry({ orgUuid, isoCode, preferenceOrder }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_ADD_ORG_COUNTRY_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/countries`,
        {
          preferenceOrder,
          isoCode,
        },
      )

      if (!response.data.data[0].success) {
        throw new Error(response.data.data[0].message)
      }

      dispatch(
        addToast({
          message: 'Country was was successfully added!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_ADD_ORG_COUNTRY_ERROR })
      dispatch(
        addToast({
          message: 'There was an error adding the country. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteOrgCountry({ orgUuid, isoCode }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_ORG_COUNTRY_REQUEST })

    try {
      const response = await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/countries/${isoCode}`,
      )

      if (!response.data.data[0].success) {
        throw new Error(response.data.data[0].message)
      }

      dispatch(
        addToast({
          message: 'Country was deleted!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_ORG_COUNTRY_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting this country. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reorderOrgCountries({ orgUuid, newOrder }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_REORDER_ORG_COUNTRIES_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/countries/`,
        newOrder,
      )

      if (!response.data.data[0].success) {
        throw new Error(response.data.data[0].message)
      }

      dispatch(
        addToast({
          message: 'Countries were reordered!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_REORDER_ORG_COUNTRIES_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error reordering countries. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
