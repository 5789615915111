import initialState from 'reducers/initialState'

import {
  API_GET_GROUP_ADMINS_REQUEST,
  API_GET_ORG_ADMINS_REQUEST,
  API_GET_GROUP_ADMINS_SUCCESS,
  API_REMOVE_GROUP_ADMIN_SUCCESS,
  API_UPDATE_ADMIN_SUCCESS,
  API_CHANGE_ADMIN_ROLE_SUCCESS,
  API_GET_ORG_ADMINS_SUCCESS,
  API_INVITE_ORG_ADMIN_SUCCESS,
  API_INVITE_GROUP_ADMIN_TO_GROUPS_SUCCESS,
  API_INVITE_GROUP_ADMINS_TO_GROUP_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function adminsReducer(state = initialState.admins, action) {
  switch (action.type) {
    case API_GET_GROUP_ADMINS_REQUEST:
    case API_GET_ORG_ADMINS_REQUEST:
      return {
        ...state,
        entities: {},
      }
    case API_UPDATE_ADMIN_SUCCESS:
    case API_CHANGE_ADMIN_ROLE_SUCCESS:
    case API_GET_ORG_ADMINS_SUCCESS:
    case API_INVITE_ORG_ADMIN_SUCCESS:
    case API_INVITE_GROUP_ADMINS_TO_GROUP_SUCCESS:
    case API_INVITE_GROUP_ADMIN_TO_GROUPS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.admins,
        },
      }
    case API_GET_GROUP_ADMINS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.admins,
        },
      }
    case API_REMOVE_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.uuid]: {
            ...state.entities[action.data.uuid],
            assignedGroups: [],
          },
        },
      }
    default:
      return state
  }
}
