import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import MenuItem from 'components/molecules/MenuItem'
import Select from 'components/molecules/Select'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { PartnersConstants } from '../../../constants/PartnersConstantsEnum'

export const StyledFields = styled.fieldset`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  text-align: left;
  width: 300px;
`
export const StyledName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
export const StyledEmail = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
export const StyledSeats = styled(Select)`
  width: 100px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const StyledIndustry = styled(Select)`
  width: 300px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const StyledModalTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  && {
    width: 50%;
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NewOrganisationForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onClose,
  partner,
  partners,
  selectPartner,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledModalTitle variant="h2">New organisation</StyledModalTitle>
      <StyledFields>
        <StyledName
          id="name"
          name="name"
          label="Organisation name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.name && !!errors.name}
          helperText={touched.name ? errors.name : ''}
          fullWidth
        />
        <StyledEmail
          id="email"
          name="email"
          label="Organisation owner email address"
          type="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.email && !!errors.email}
          helperText={touched.email ? errors.email : ''}
          fullWidth
        />

        <StyledName
          id="incidentsContactName"
          name="incidentsContactName"
          label="Contact person for incidents"
          type="text"
          value={values.incidentsContactName}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.incidentsContactName && !!errors.incidentsContactName}
          helperText={
            touched.incidentsContactName ? errors.incidentsContactName : ''
          }
          fullWidth
        />
        <StyledEmail
          id="incidentsContactEmail"
          name="incidentsContactEmail"
          label="Contact email for incidents"
          type="email"
          value={values.incidentsContactEmail}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            touched.incidentsContactEmail && !!errors.incidentsContactEmail
          }
          helperText={
            touched.incidentsContactEmail ? errors.incidentsContactEmail : ''
          }
          fullWidth
        />

        <StyledIndustry
          name="industry"
          value={values.industry}
          label="Industry"
          onChange={handleChange}>
          <MenuItem value={'General'}>General</MenuItem>
          <MenuItem value={'Healthcare'}>Healthcare</MenuItem>
          <MenuItem value={'Fuel'}>Fuel</MenuItem>
          <MenuItem value={'Sports'}>High Performance Sports</MenuItem>
        </StyledIndustry>
        <StyledIndustry
          name="country"
          value={values.country}
          label="Country"
          onChange={handleChange}>
          <MenuItem value={'NZ'}>New Zealand</MenuItem>
          <MenuItem value={'AU'}>Australia</MenuItem>
          <MenuItem value={'IN'}>India</MenuItem>
          <MenuItem value={'DE'}>Germany</MenuItem>
          <MenuItem value={'AE'}>United Arab Emirates</MenuItem>
          <MenuItem value={'US'}>USA</MenuItem>
        </StyledIndustry>

        {partner ? (
          <StyledIndustry
            name="partner"
            value={values.partnerUuid}
            label="Partner"
            onChange={handleChange}>
            {/* <MenuItem
              key={partners[values.partnerUuid].uuid}
              value={partners[values.partnerUuid].uuid}>
              {partners[values.partnerUuid].title}
            </MenuItem> */}
            <MenuItem key={partner.uuid} value={partner.uuid}>
              {partner.title}
            </MenuItem>
          </StyledIndustry>
        ) : (
          <StyledIndustry
            name="partner"
            value={values.partnerUuid}
            label="Partner"
            onChange={(e) => selectPartner(e.target.value)}>
            <MenuItem
              key={PartnersConstants.NO_PARTNER_SELECTED}
              value={PartnersConstants.NO_PARTNER_SELECTED}>
              None
            </MenuItem>
            {Object.keys(partners).map((partnerUuid) => (
              <MenuItem key={partnerUuid} value={partnerUuid}>
                {partners[partnerUuid].title}
              </MenuItem>
            ))}
          </StyledIndustry>
        )}

        <StyledSeats
          name="numberOfSeats"
          value={values.numberOfSeats}
          label="Number of users"
          onChange={handleChange}>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={300}>300</MenuItem>
          <MenuItem value={400}>400</MenuItem>
          <MenuItem value={500}>500</MenuItem>
        </StyledSeats>
      </StyledFields>
      <StyledActions>
        <StyledButton
          size="small"
          variant="outlined"
          color="primary"
          onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          size="small"
          variant="contained"
          type="submit"
          color="primary">
          {values.email ? 'Send invite' : 'Create'}
        </StyledButton>
      </StyledActions>
    </form>
  )
}

NewOrganisationForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  onClose: PropTypes.func,
  partner: PropTypes.object,
  partners: PropTypes.object,
  selectPartner: PropTypes.func,
}

NewOrganisationForm.defaultProps = {
  errors: {},
  touched: {},
  values: {},
}

export default NewOrganisationForm
