import initialState from 'reducers/initialState'

import {
  API_GET_CHATHISTORY_SUCCESS,
  API_GET_REDFLAGCHAT_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function chatbotReducer(state = initialState.chatbot, action) {
  switch (action.type) {
    case API_GET_REDFLAGCHAT_SUCCESS:
      return {
        ...state,
        redFlagChats: {
          ...action.data.entities.redFlagChat,
        },
      }
    case API_GET_CHATHISTORY_SUCCESS:
      return {
        ...state,
        chatHistory: {
          ...action.data,
        },
      }

    default:
      return state
  }
}
