import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import { Reflection } from 'modules/journals/schema'
import React from 'react'
import styled from 'styled-components'

interface TagProps {
  variant: ReflectionSentimentEnum
}

const Tag = styled.div<TagProps>`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme, variant }) =>
    variant === ReflectionSentimentEnum.POSITIVE
      ? theme.palette.quaternary.main
      : theme.palette.primary.main};
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
`

export const ReflectionTag = ({
  reflection,
}: {
  reflection: Reflection
}): React.ReactElement => (
  <Tag variant={reflection.sentiment}>{reflection.title}</Tag>
)
