import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Partners() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4998 13.0136C15.1168 13.0136 17.2383 10.8895 17.2383 8.26924C17.2383 5.64901 15.1168 3.5249 12.4998 3.5249C9.88275 3.5249 7.76123 5.64901 7.76123 8.26924C7.76123 10.8895 9.88275 13.0136 12.4998 13.0136Z"
        stroke="#828797"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4757 22C21.4757 17.0369 17.4573 13.0135 12.5002 13.0135C7.54314 13.0135 3.52417 17.0369 3.52417 22"
        stroke="#828797"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Partners
