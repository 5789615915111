import { NormalizedSchema, schema } from 'normalizr'

import { IPartnerAdmin } from '../../models/PartnerAdmin'
import { RequestProgressState } from '../progress'

export interface PartnerAdminsState {
  progress: {
    getPartnerAdmins: RequestProgressState
  }
  partnerAdmins: NormalizedSchema<{ [key: string]: IPartnerAdmin }, string>
}

// Schema
export const partnerAdminSchema = new schema.Entity(
  'partnerAdmins',
  {},
  { idAttribute: 'uuid' },
)
export const partnerAdminListSchema = [partnerAdminSchema]
