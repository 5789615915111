import SettingsPageContainer from 'components/molecules/SettingsPageContainer'
import EditProfileForm from 'components/organisms/EditProfileForm'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Profile({ handleSubmit, user }) {
  return (
    <ChnnlDashboardLayout>
      <SettingsPageContainer title="Edit profile">
        <EditProfileForm onSubmit={handleSubmit} initialValues={user} />
      </SettingsPageContainer>
    </ChnnlDashboardLayout>
  )
}

Profile.propTypes = {
  user: PropTypes.object,
  handleSubmit: PropTypes.func,
}

export default Profile
