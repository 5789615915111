import { NormalizedSchema, schema } from 'normalizr'

import { RequestProgressState } from '../progress'

export interface Organisation {
  uuid: string
  name: string
  createdAt: string
}

export interface OrganisationsState {
  progress: {
    getOrganisations: RequestProgressState
  }
  organisations: NormalizedSchema<{ [key: string]: Organisation }, string>
}

// Schema
export const organisationSchema = new schema.Entity(
  'organisations',
  {},
  { idAttribute: 'uuid' },
)
export const organisationListSchema = [organisationSchema]
