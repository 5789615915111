import * as undoActions from 'modules/undo/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      undoItems: state.undo.past,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...undoActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    onUndo:
      ({ actions }) =>
      (undo) => {
        actions.applyUndo(undo)
      },
    onUndoCancel:
      ({ actions }) =>
      () => {
        actions.cancelUndo()
      },
  }),
  lifecycle({
    shouldComponentUpdate(nextProps) {
      const { undoItems } = this.props
      return undoItems.length !== nextProps.undoItems.length
    },
  }),
)
