import { HumanReadableDatetime } from 'constants/date'

import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { DistressedUserInfo } from 'components/molecules/DistressedUserInfo'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { useSurveyMessage } from 'hooks/useSurveyMessage'
import { Notification } from 'modules/notifications/schema'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import ProgressLoader from '../../atoms/ProgressLoader'

import { InnerFormProps } from './index'

const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.mdlg}px;
  display: flex;
  justify-content: space-between;
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const Date = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const VerticallyCentered = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`

const Reply = styled.div`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`

const ReplyLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    font-size: 11px;
  }
`

const ReplyText = styled(Typography)`
  overflow-y: visible;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

const ReplyTextArea = styled(TextField)`
  div {
    padding-top: 0;
  }
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }
`

export const CharCount = styled(Typography)`
  && {
    margin-left: auto;
    display: flex;
    align-self: end;
    font-size: 8px;
  }

  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ActionsLeft = styled.div``
const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child:last-child {
        /* this is a more specific only-child, it will win for a single button */
        margin-left: auto;
        width: 149px;
      }
    }
  }
`

const Tags = styled.div`
  margin-left: auto;
  display: flex;
  align-self: end;

  && {
    > * {
      margin: 0 3px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const GreenTag = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.quaternary.main};
  font-size: 8px;
  padding: 4px;
  border-radius: 4px;
`
const BlueTag = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.primary.main};
  font-size: 8px;
  padding: 4px;
  border-radius: 4px;
`

export const SentLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 215px;
  width: '100%';
  overflow: scroll;
  margin-top: ${({ theme }) => theme.spacing.sm}px;
`

export function InterventionForm({
  data,
  onCancel,
  onSuccess,
  currentUser,
}: InnerFormProps): React.ReactElement {
  const [messages, setMessages] = useState<Notification[]>(data.messages)

  const {
    request: { data: newMessage, isLoading, error },
    sendMessage,
  } = useSurveyMessage()

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values) => {
      sendMessage({
        message: values.message,
        surveyId: data.mostRecentDistressedSurveyUuid,
      })
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required('Please enter your reply')
        .max(700, 'Please keep your message within the 700 character limit'),
    }),
  })

  /*
    This effect is fine for now, 
    but if this form grows we probably want 
    to reorganise the way the above hooks are composed
  */
  useEffect(() => {
    if (newMessage) {
      onSuccess()
      setMessages((messages) => [newMessage, ...messages])
      resetForm()
    }
  }, [onSuccess, resetForm, newMessage])

  return (
    <>
      <DistressedUserInfo data={data} messages={messages} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <ScrollableDiv>
          {messages.map((message, i) => (
            <Reply data-testid="message-row" key={i}>
              <StyledHeader>
                <div></div>
                <Date variant="body2" component="div">
                  <SentLabel>Sent&nbsp;</SentLabel>
                  <strong>
                    {format(message.createdAt, HumanReadableDatetime)}
                  </strong>
                </Date>
              </StyledHeader>
              <VerticallyCentered>
                <ReplyLabel variant="body2">Reply</ReplyLabel>
                <Tags>
                  {message.acknowledged && <BlueTag>{'Read'}</BlueTag>}
                  <GreenTag>
                    {currentUser.uuid === message.authorUuid
                      ? 'By yourself'
                      : 'By someone else'}
                  </GreenTag>
                </Tags>
              </VerticallyCentered>
              <ReplyText variant="body2">{message.message}</ReplyText>
            </Reply>
          ))}
        </ScrollableDiv>
        <Reply>
          <VerticallyCentered>
            <ReplyLabel variant="body2">Your message</ReplyLabel>
            <CharCount variant="body2">
              Character count: {values.message.length || 0} / 700
            </CharCount>
          </VerticallyCentered>
          <ReplyTextArea
            id="message"
            name="message"
            type="text"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.message && !!errors.message}
            helperText={touched.message ? errors.message : ''}
            fullWidth
            multiline
            rows={6}
            InputProps={{
              disableUnderline: true,
            }}
            disabled={isSubmitting}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            variant={'standard' as any} // Types for textarea are a bit broken
          />
        </Reply>

        {error && (
          <Reply>
            <ErrorText>
              There was an error while sending your message. Please try again.
            </ErrorText>
          </Reply>
        )}
        {isLoading ? (
          <ProgressLoader fullWidth />
        ) : (
          <Actions>
            <ActionsLeft />
            <ActionsRight>
              <Button
                variant="outlined"
                type="button"
                color="primary"
                fullWidth
                size="small"
                disabled={isLoading}
                onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                size="small"
                disabled={isLoading || !isValid}>
                Send message
              </Button>
            </ActionsRight>
          </Actions>
        )}
      </form>
    </>
  )
}
