import * as authActions from 'modules/authentication/actions'
import * as exportActions from 'modules/export/actions'
import * as organisationsActions from 'modules/organisations/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import * as partnersActions from 'modules/partners/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      organisations: getOrganisationsList(state),
      getOrganisationsProgress: state.progress.getOrganisations,
      organisationEntities: state.organisations.entities,
      partnerEntities: state.partners.entities,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...exportActions,
        ...organisationsActions,
        ...partnersActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState(
    'isNewOrganisationModalOpen',
    'setIsNewOrganisationModalOpen',
    false,
  ),
  withState(
    'isNewDemoOrganisationModalOpen',
    'setIsNewDemoOrganisationModalOpen',
    false,
  ),
  withState('isResendModalOpen', 'setIsResendModalOpen', false),
  withState(
    'isDeleteOrganisationModalOpen',
    'setIsDeleteOrganisationModalOpen',
    false,
  ),
  withState('modalOrganisationId', 'setModalOrganisationId', null),
  lifecycle({
    componentDidMount() {
      this.props.actions.getOrganisations()
      this.props.actions.getPartners()
    },
  }),
  withHandlers({
    onExport:
      ({ actions }) =>
      () => {
        actions.exportAllOrgData()
      },
    onOrgExport:
      ({ actions }) =>
      (orgUuid) => {
        actions.exportOrgData({ orgUuid })
      },
    onInviteNewOrganisation:
      ({ actions, setIsNewOrganisationModalOpen }) =>
      ({
        name,
        email,
        incidentsContactName,
        incidentsContactEmail,
        numberOfSeats,
        industry,
        country,
      }) => {
        actions.createOrganisation({
          name,
          orgOwnerEmail: email,
          incidentsContactName,
          incidentsContactEmail,
          seats: numberOfSeats,
          industry,
          country,
        })

        setIsNewOrganisationModalOpen(false)
      },
    onInviteNewDemoOrganisation:
      ({ actions, setIsNewDemoOrganisationModalOpen }) =>
      ({ name, email }) => {
        actions.createDemoOrganisation({
          name,
          email,
        })

        setIsNewDemoOrganisationModalOpen(false)
      },
    onResendInvitation:
      ({ actions, setIsResendModalOpen, modalOrganisationId }) =>
      ({ email }) => {
        actions.resendOrganisationInvitation({
          uuid: modalOrganisationId,
          email,
        })
        setIsResendModalOpen(false)
      },
    onDeleteOrganisation:
      ({ actions, modalOrganisationId, setIsDeleteOrganisationModalOpen }) =>
      (e) => {
        e.preventDefault()
        actions.deleteOrganisation(modalOrganisationId)
        setIsDeleteOrganisationModalOpen(false)
      },
  }),
)
