import React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
function Active() {
  return (
    <svg width="25px" height="25px" viewBox="0 0 54.34 87.49">
      <g data-name="Layer 2">
        <g data-name="WHO-5 categories">
          <g>
            <path
              fill="none"
              stroke="#5c8368"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
              d="M36.06 11.98L32.32 31.1 42.05 30.77"
              className="cls-1"
            />
            <path
              d="M53.09 38.66L27.62 77.5"
              fill="none"
              stroke="#5c8368"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
            />
            <path
              d="M32.79 0a1.59 1.59 0 00-1.31.74l-31.24 50a1.55 1.55 0 00.5 2.15 1.51 1.51 0 00.93.24L23 51.7l-7.34 33.91a1.56 1.56 0 002.85 1.16l31.26-50a1.57 1.57 0 00-.5-2.15 1.55 1.55 0 00-.93-.24L27 35.8l7.33-33.91A1.55 1.55 0 0033.14 0a1.45 1.45 0 00-.35 0zm-3.41 10l-5.91 27.17A1.55 1.55 0 0024.68 39a1.53 1.53 0 00.43 0l20.39-1.3-24.88 39.8 5.91-27.16a1.59 1.59 0 00-1.63-1.9L4.51 49.79z"
              fill="#aad5b7"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default React.memo(Active)
