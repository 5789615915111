/* eslint-disable @typescript-eslint/naming-convention, no-undef */
export const ENV = process.env.REACT_APP_ENV

export const APP_SALT = process.env.REACT_APP_SALT
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const CHNNL_API_URL = process.env.REACT_APP_CHNNL_API_URL
export const SAUCE_API_URL = process.env.REACT_APP_SAUCE_URL
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const BRANCH_KEY = process.env.REACT_APP_BRANCH_KEY
export const FEATURE_KEY = process.env.REACT_APP_FEATURE_KEY
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID
export const SESSION_KEY = 'session'
export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_REGEX =
  /^(?=.*[!@#$%^&*]|.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
export const REFRESH_TOKEN_KEY = 'refresh_token'
export const ROLE_KEY = 'user-role'
export const ORGANISATION_KEY = 'user-organisation'
export const PARTNER_KEY = 'user-partner'
export const MAXIMUM_REFLECTION_LENGTH = 180
export const MINIMUM_SAMPLE_SIZE = 5
