import { createSelector } from 'reselect'

import initialState from '../../reducers/initialState'

import { Notification } from './schema'

export const getNotificationsList = createSelector(
  (state: typeof initialState) =>
    state.notifications.entities as Notification[],
  (notifications) => Object.values(notifications),
)

export const getJournalReplies = createSelector(
  [
    getNotificationsList,
    (state: typeof initialState, journalUuid: string) => journalUuid,
  ],
  (notifications, journalUuid) =>
    notifications.filter(
      (notification) => notification.originalJournalUuid === journalUuid,
    ),
)
