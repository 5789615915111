import initialState from 'reducers/initialState'

import {
  API_RESET_PATHWAY_SUCCESS,
  API_GET_PATHWAYS_SUCCESS,
  API_UPDATE_PATHWAY_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function pathwaysReducer(state = initialState.pathways, action) {
  switch (action.type) {
    case API_GET_PATHWAYS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.pathways,
        },
      }
    case API_RESET_PATHWAY_SUCCESS:
    case API_UPDATE_PATHWAY_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.pathways,
        },
      }
    default:
      return state
  }
}
