import { CHNNL_DASHBOARD_ORGANISATIONS } from 'constants/routes'

import { useFeature } from '@optimizely/react-sdk'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import NavIcon from '@weareroam/cake-ui-v1-icons/Apps'
import Logo from 'components/atoms/Logo'
import StatusTag from 'components/atoms/StatusTag'
import NavItem from 'components/molecules/NavItem'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  height: 60px;
  align-items: stretch;
`

export const Left = styled.div`
  align-self: center;
  padding: 0 ${({ theme }) => theme.spacing.lg}px;
  width: 300px;
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 300px;
`

export const Welcome = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding: 0 ${({ theme }) => theme.spacing.lg}px;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  white-space: nowrap;
  height: 100%;
`

export const StyledButton = styled(Button)`
  width: 105px;
  height: 100%;
`

export const StyledNav = styled.nav`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const StyledLogo = styled(Logo)``

export const StyledStatusTag = styled(StatusTag)`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChnnlAdminHeader({ user, className, onLogout }) {
  //the following creates a memory leak https://github.com/optimizely/react-sdk/issues/17
  const [envEnabled, variables] = useFeature('environment_key')
  const isEnabled = React.useMemo(
    () => envEnabled && variables.env !== 'PROD',
    [envEnabled, variables],
  )
  return (
    <StyledHeader className={className}>
      <Left>
        <StyledLogo />
        {isEnabled && (
          <StyledStatusTag themeColor="success">
            {variables.env}
          </StyledStatusTag>
        )}
      </Left>
      <StyledNav>
        <NavItem
          to={CHNNL_DASHBOARD_ORGANISATIONS}
          text="View organisations"
          icon={<NavIcon />}
        />
      </StyledNav>
      <Right>
        <Welcome>
          <Typography variant="body1">Welcome, {user.firstName}</Typography>
        </Welcome>
        <StyledButton onClick={onLogout} color="primary" variant="text">
          Log out
        </StyledButton>
      </Right>
    </StyledHeader>
  )
}

ChnnlAdminHeader.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  onLogout: PropTypes.func,
}

ChnnlAdminHeader.defaultProps = {
  user: {},
}

export default ChnnlAdminHeader
