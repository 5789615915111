import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { JournalApproval } from '../../../modules/journals/schema'
import { Notification } from '../../../modules/notifications/schema'
import { RequestProgressState } from '../../../modules/progress'
import { User } from '../../../modules/user/schema'
import { Modal } from '../../molecules/Modal'
import JournalEntryForm from '../JournalEntryForm'
import { JournalReplyForm } from '../JournalReplyForm'

export const EntryModal = styled(Modal)`
  width: 700px;
`

export interface JournalEntryModalProps {
  open: boolean
  journal: JournalApproval
  replies: Notification[]
  currentUser: User
  getJournalReplyProgress: RequestProgressState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any // TODO: Convert journals/actions to TS
  onCancel: () => void
  onClose: () => void
  onRevert: () => void
  onSubmit: () => void
  onSendPushNotification: () => Promise<void>
}

type TabIds = 'review' | 'reply'

const tabs: {
  id: TabIds
  title: string
}[] = [
  { id: 'review', title: 'Review journal' },
  { id: 'reply', title: 'Reply to journal' },
]

export const JournalEntryModal = ({
  open,
  journal,
  replies,
  currentUser,
  getJournalReplyProgress,
  actions,
  onCancel,
  onClose,
  onRevert,
  onSubmit,
  onSendPushNotification,
}: JournalEntryModalProps): React.ReactElement => {
  const [activeTab, setActiveTab] = useState<TabIds>('review')
  const handleTabClick = (id: string) => {
    // TODO: Need to add a generic interface to the Modal component to support tab ids properly
    setActiveTab(id as TabIds)
  }

  const [savedReply, setSavedReply] = useState('')

  useEffect(() => {
    actions.getJournalReply({ journalUuid: journal.originalUuid })
  }, [actions, journal])

  return (
    <EntryModal
      maxWidth={'md'}
      hasCloseButton={true}
      open={open}
      onClose={onClose}
      tabs={tabs}
      activeTab={activeTab}
      handleTabClick={handleTabClick}>
      {activeTab === 'review' && (
        <JournalEntryForm
          groupName={journal.group.name}
          organisationName={journal.organisation.name}
          date={journal.forDate}
          createdAt={journal.createdAt}
          originalEntry={journal.originalEntry}
          initialEntry={journal.editedEntry || journal.originalEntry}
          yellowFlagged={journal.yellowFlagged}
          redFlagged={journal.redFlagged}
          tags={journal.tags}
          onCancel={onCancel}
          onRevert={onRevert}
          onSubmit={onSubmit}
        />
      )}
      {activeTab === 'reply' && (
        <JournalReplyForm
          journal={journal}
          replies={replies}
          currentUser={currentUser}
          progress={getJournalReplyProgress}
          initialReply={savedReply}
          storeReply={setSavedReply}
          onCancel={onCancel}
          onSubmit={onSendPushNotification}
        />
      )}
    </EntryModal>
  )
}
