import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { partnerOrganisationListSchema } from './schema'

// Actions
export const API_GET_PARTNER_ORGS_REQUEST = 'API_GET_PARTNER_ORGS_REQUEST'
export const API_GET_PARTNER_ORGS_SUCCESS = 'API_GET_PARTNER_ORGS_SUCCESS'
export const API_GET_PARTNER_ORGS_ERROR = 'API_GET_PARTNER_ORGS_ERROR'
export const API_CREATE_PARTNER_ORG_REQUEST = 'API_CREATE_PARTNER_ORG_REQUEST'
export const API_CREATE_PARTNER_ORG_SUCCESS = 'API_CREATE_PARTNER_ORG_SUCCESS'
export const API_CREATE_PARTNER_ORG_ERROR = 'API_CREATE_PARTNER_ORG_ERROR'
export const API_UPDATE_PARTNER_ORG_REQUEST = 'API_UPDATE_PARTNER_ORG_REQUEST'
export const API_UPDATE_PARTNER_ORG_SUCCESS = 'API_UPDATE_PARTNER_ORG_SUCCESS'
export const API_UPDATE_PARTNER_ORG_ERROR = 'API_UPDATE_PARTNER_ORG_ERROR'
export const API_DELETE_PARTNER_ORG_REQUEST = 'API_DELETE_PARTNER_ORG_REQUEST'
export const API_DELETE_PARTNER_ORG_SUCCESS = 'API_DELETE_PARTNER_ORG_SUCCESS'
export const API_DELETE_PARTNER_ORG_ERROR = 'API_DELETE_PARTNER_ORG_ERROR'
export const LIST_EMPTY_PARTNER_ORGANISATION = 'LIST_EMPTY_PARTNER_ORGANISATION'
export const CLEAN_OUT_EMPTY_PARTNER_ORGANISATIONS =
  'CLEAN_OUT_EMPTY_PARTNER_ORGANISATIONS'

// Action creators

export function getPartnerOrganisations(partnerUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_PARTNER_ORGS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/partner/${partnerUuid}/organisation`,
      )

      const data = normalize(
        response.data.data[0].output,
        partnerOrganisationListSchema,
      )

      dispatch({
        type: API_GET_PARTNER_ORGS_SUCCESS,
        data,
      })
      // Since we don't have access to a newly created partner organisation
      // an empty state partner org is appended to the state and requires a cleanup once it's no longer needed
      dispatch({ type: CLEAN_OUT_EMPTY_PARTNER_ORGANISATIONS })
    } catch (error) {
      dispatch({ type: API_GET_PARTNER_ORGS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error while fetching the partner orgs. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function unlinkOrganisationFromPartner(
  partnerUuid,
  partnerOrganisationUuid,
) {
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_DELETE_PARTNER_ORG_REQUEST })
    const state = getState()
    const partner = state.partnerOrganisations.entities[partnerOrganisationUuid]

    try {
      await axios.delete(
        `${SAUCE_API_URL}/partner/${partnerUuid}/organisation/${partnerOrganisationUuid}`,
      )

      dispatch({
        type: API_DELETE_PARTNER_ORG_SUCCESS,
        data: {
          uuid: partnerOrganisationUuid,
        },
      })

      dispatch(
        addToast({
          message: `'${partner.organisation.name}' was successfully unliked from ${partner.partner.title}.`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_PARTNER_ORG_ERROR, error })
      dispatch(
        addToast({
          message: `'${partner.organisation.name}' was unable to be unliked.`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function linkOrganisationToPartner(
  partnerUuid,
  partnerOrganisationUuid,
) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_PARTNER_ORG_REQUEST })

    try {
      await axios.post(
        `${SAUCE_API_URL}/partner/${partnerUuid}/organisation/${partnerOrganisationUuid}`,
      )

      dispatch({
        type: API_UPDATE_PARTNER_ORG_SUCCESS,
        data: {
          uuid: partnerUuid,
        },
      })

      dispatch(
        addToast({
          message: `Organisation was successfully linked to a partner.`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_PARTNER_ORG_ERROR, error })
      dispatch(
        addToast({
          message: `Organisation was unable to be linked to a partner.`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function cleanOutEmptyStatePartnerOrganisations() {
  return async function dispatcher(dispatch) {
    dispatch({ type: CLEAN_OUT_EMPTY_PARTNER_ORGANISATIONS })
  }
}

export const partnerOrganisationActions = {
  getPartnerOrganisations,
  unlinkOrganisationFromPartner,
  linkOrganisationToPartner,
  cleanOutEmptyStatePartnerOrganisations,
}
