import { schema } from 'normalizr'
import { ObjectSchema, object, string } from 'yup'

export interface Group {
  uuid: string
  name: string
}

//TODO: fill in
// GroupUser on the backend
export interface GroupMember {
  uuid: string
}

export const GroupValidation: ObjectSchema<Group> = object({
  uuid: string().uuid().required(),
  name: string().required(),
})

// Schema
export const groupSchema = new schema.Entity<Group>(
  'groups',
  {},
  { idAttribute: 'uuid' },
)
export const groupListSchema = [groupSchema]

export const groupMemberSchema = new schema.Entity<GroupMember>(
  'members',
  {},
  { idAttribute: 'uuid' },
)
