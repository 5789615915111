import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import idx from 'idx'
import { changeOrgDetails } from 'modules/organisations/actions'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { groupListSchema, groupMemberSchema } from './schema'

// Actions
export const API_GET_GROUPS_REQUEST = 'API_GET_GROUPS_REQUEST'
export const API_GET_GROUPS_SUCCESS = 'API_GET_GROUPS_SUCCESS'
export const API_GET_GROUPS_ERROR = 'API_GET_GROUPS_ERROR'
export const API_GET_GROUP_MEMBERS_REQUEST = 'API_GET_GROUP_MEMBERS_REQUEST'
export const API_GET_GROUP_MEMBERS_SUCCESS = 'API_GET_GROUP_MEMBERS_SUCCESS'
export const API_GET_GROUP_MEMBERS_ERROR = 'API_GET_GROUP_MEMBERS_ERROR'
export const API_DELETE_GROUP_REQUEST = 'API_DELETE_GROUP_REQUEST'
export const API_DELETE_GROUP_SUCCESS = 'API_DELETE_GROUP_SUCCESS'
export const API_DELETE_GROUP_ERROR = 'API_DELETE_GROUP_ERROR'
export const API_CREATE_GROUP_REQUEST = 'API_CREATE_GROUP_REQUEST'
export const API_CREATE_GROUP_SUCCESS = 'API_CREATE_GROUP_SUCCESS'
export const API_CREATE_GROUP_ERROR = 'API_CREATE_GROUP_ERROR'
export const API_UPDATE_GROUP_REQUEST = 'API_UPDATE_GROUP_REQUEST'
export const API_UPDATE_GROUP_SUCCESS = 'API_UPDATE_GROUP_SUCCESS'
export const API_UPDATE_GROUP_ERROR = 'API_UPDATE_GROUP_ERROR'
export const API_INVITE_MEMBERS_TO_GROUP_RESET =
  'API_INVITE_MEMBERS_TO_GROUP_RESET'
export const API_INVITE_MEMBERS_TO_GROUP_REQUEST =
  'API_INVITE_MEMBERS_TO_GROUP_REQUEST'
export const API_INVITE_MEMBERS_TO_GROUP_SUCCESS =
  'API_INVITE_MEMBERS_TO_GROUP_SUCCESS'
export const API_INVITE_MEMBERS_TO_GROUP_ERROR =
  'API_INVITE_MEMBERS_TO_GROUP_ERROR'
export const API_RESEND_MEMBER_INVITATION_REQUEST =
  'API_RESEND_MEMBER_INVITATION_REQUEST'
export const API_RESEND_MEMBER_INVITATION_SUCCESS =
  'API_RESEND_MEMBER_INVITATION_SUCESS'
export const API_RESEND_MEMBER_INVITATION_ERROR =
  'API_RESEND_MEMBER_INVITATION_ERROR'
export const API_RESEND_MULTI_MEMBER_INVITATION_REQUEST =
  'API_RESEND_MULTI_MEMBER_INVITATION_REQUEST'
export const API_RESEND_MULTI_MEMBER_INVITATION_SUCCESS =
  'API_RESEND_MULTI_MEMBER_INVITATION_SUCESS'
export const API_RESEND_MULTI_MEMBER_INVITATION_ERROR =
  'API_RESEND_MULTI_MEMBER_INVITATION_ERROR'
export const API_MOVE_MEMBERS_REQUEST = 'API_MOVE_MEMBERS_REQUEST'
export const API_MOVE_MEMBERS_SUCCESS = 'API_MOVE_MEMBERS_SUCCESS'
export const API_MOVE_MEMBERS_ERROR = 'API_MOVE_MEMBERS_ERROR'
export const API_REMOVE_MULTI_MEMBER_REQUEST = 'API_REMOVE_MULTI_MEMBER_REQUEST'
export const API_REMOVE_MULTI_MEMBER_SUCCESS = 'API_REMOVE_MULTI_MEMBER_SUCCESS'
export const API_REMOVE_MULTI_MEMBER_ERROR = 'API_REMOVE_MULTI_MEMBER_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getGroups(organisationUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUPS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/group`,
      )
      const data = normalize(response.data.data, groupListSchema)

      dispatch({
        type: API_GET_GROUPS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_GROUPS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the groups for this organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getGroupMembers(organisationUuid, groupUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUP_MEMBERS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/group/${groupUuid}/members`,
      )
      const responseData = idx(response, (_) => _.data.data[0])
      const data = {
        ...responseData,
        members: normalize(responseData.members, [groupMemberSchema]).entities
          .members,
      }
      dispatch({ type: API_GET_GROUP_MEMBERS_SUCCESS, data })
    } catch (error) {
      dispatch({ type: API_GET_GROUP_MEMBERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching this particular group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createGroup({ orgUuid, name, newAdmins, admins }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_GROUP_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group`,
        {
          name,
          admins: {
            email: newAdmins,
            userUuid: admins,
          },
        },
      )
      const data = normalize(response.data.data, groupListSchema)

      dispatch({ type: API_CREATE_GROUP_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The group was successfully created!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_CREATE_GROUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error creating the group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteGroup({ orgUuid, groupUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_GROUP_REQUEST })

    try {
      await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}`,
      )

      dispatch({
        type: API_DELETE_GROUP_SUCCESS,
        data: {
          uuid: groupUuid,
        },
      })

      dispatch(
        addToast({
          message: 'The group was successfully deleted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )

      return true
    } catch (error) {
      dispatch({ type: API_DELETE_GROUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error deleting the group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resendInvitation({ groupUuid, orgUuid, userUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch) => {
    dispatch({ type: API_RESEND_MEMBER_INVITATION_REQUEST })
    try {
      const { data } = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/resend/${userUuid}`,
      )
      const result = data.data[0]
      if (result && result.success) {
        dispatch({ type: API_RESEND_MEMBER_INVITATION_SUCCESS })
        dispatch(
          addToast({
            message: 'The user reinvitation successfully sent.',
            type: TOAST_TYPE_SUCCESS,
          }),
        )

        return result.success
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      dispatch({ type: API_RESEND_MEMBER_INVITATION_ERROR, error })
      dispatch(
        addToast({
          message:
            error.message ||
            'There was an error reinviting the user. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function broadcastInvitations({ groupUuid, orgUuid, userUuids }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch) => {
    dispatch({ type: API_RESEND_MULTI_MEMBER_INVITATION_REQUEST })
    try {
      const { data } = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/resend/`,
        {
          userUuids,
        },
      )
      const result = data.data[0]
      if (result && result.success) {
        dispatch({ type: API_RESEND_MULTI_MEMBER_INVITATION_SUCCESS })
        dispatch(
          addToast({
            message: `Successfully sent ${userUuids.length} invitations.`,
            type: TOAST_TYPE_SUCCESS,
          }),
        )
        return result.success
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      dispatch({ type: API_RESEND_MULTI_MEMBER_INVITATION_ERROR, error })
      dispatch(
        addToast({
          message:
            error.message ||
            'There was an error reinviting users. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function moveMembers({
  userUuids,
  sourceGroupUuid,
  targetGroupUuid,
  orgUuid,
  undoing = false,
}) {
  return async (dispatch) => {
    dispatch({ type: API_MOVE_MEMBERS_REQUEST })
    try {
      const { data, statusText } = await axios.post(
        `${SAUCE_API_URL}/group-user/move-groups/${sourceGroupUuid}/${targetGroupUuid}/`,
        {
          userUuids,
        },
      )
      const result = data?.data[0]
      if (!result) {
        throw new Error(statusText)
      }

      dispatch({ type: API_MOVE_MEMBERS_SUCCESS, data: result.entities }) // noop we just refresh on the next line

      // state.selected containing current group members is rather tragic
      // should be an actual selector...
      if (undoing) {
        dispatch(getGroupMembers(orgUuid, targetGroupUuid))
      } else {
        dispatch(getGroupMembers(orgUuid, sourceGroupUuid))
      }

      if (result.errors?.length) {
        throw new Error(
          'There was an error when moving users. ' +
            result.errors.map((err) => err.message).join('. '),
        )
      } else {
        dispatch(
          addToast({
            message: `Successfully moved ${result.entities.length} users.`,
            type: TOAST_TYPE_SUCCESS,
          }),
        )
      }
      return true
    } catch (error) {
      dispatch({ type: API_MOVE_MEMBERS_ERROR, error })
      dispatch(
        addToast({
          message:
            error.message ||
            'There was an error when moving users. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeGroupMembers({ groupUuid, orgUuid, userUuids }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch) => {
    dispatch({ type: API_REMOVE_MULTI_MEMBER_REQUEST })
    try {
      const { data } = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/members/delete`,
        {
          userUuids,
        },
      )
      const result = data.data[0]
      if (result && result.success) {
        dispatch(getGroupMembers(orgUuid, groupUuid))
        dispatch({ type: API_REMOVE_MULTI_MEMBER_SUCCESS })
        dispatch(
          addToast({
            message: `Successfully removed ${userUuids.length} group users.`,
            type: TOAST_TYPE_SUCCESS,
          }),
        )
        return result.success
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      dispatch({ type: API_REMOVE_MULTI_MEMBER_ERROR, error })
      dispatch(
        addToast({
          message:
            error.message ||
            'There was an error removing users. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function inviteMembersToGroupReset() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return function dispatcher(dispatch) {
    dispatch({ type: API_INVITE_MEMBERS_TO_GROUP_RESET })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function inviteMembersToGroup({ groupUuid, numbers, orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_INVITE_MEMBERS_TO_GROUP_REQUEST })

    try {
      const state = getState()
      const seatsOccupied = state.organisations.entities[orgUuid].seatsOccupied
      const contactCount = numbers.length
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/invite`,
        {
          groupUuid,
          numbers: numbers,
        },
      )
      const failedInvites = response.data.data.filter(
        (invite) => !invite.success,
      )
      const successfulInvites = response.data.data.filter(
        (invite) => invite.success,
      )
      if (failedInvites.length > 0) {
        throw new Error(
          failedInvites
            .map((invite) => `${invite.message}: ${invite.value}`)
            .join(', '),
        )
      } else {
        dispatch({
          type: API_INVITE_MEMBERS_TO_GROUP_SUCCESS,
        })
        dispatch(
          changeOrgDetails({
            orgUuid,
            seatsOccupied:
              seatsOccupied + contactCount - Object.keys(failedInvites).length,
          }),
        )
      }

      if (successfulInvites.length > 0) {
        dispatch(
          addToast({
            message: `${successfulInvites.length} invitation${
              successfulInvites.length === 1 ? ' was' : 's were'
            } sent.`,
            type: TOAST_TYPE_SUCCESS,
          }),
        )
      }
    } catch (error) {
      dispatch({ type: API_INVITE_MEMBERS_TO_GROUP_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error inviting the members to this group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return { failedInvites: [], successfulInvites: [] }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateGroup({ groupUuid, name, orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_GROUP_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}`,
        {
          name,
        },
      )

      const data = normalize(response.data.data, groupListSchema)

      dispatch({ type: API_UPDATE_GROUP_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The group has been successfully updated',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_GROUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error updating this group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export const groupsActions = {
  getGroups,
  getGroupMembers,
  createGroup,
  deleteGroup,
  resendInvitation,
  broadcastInvitations,
  moveMembers,
  removeGroupMembers,
  inviteMembersToGroupReset,
  inviteMembersToGroup,
  updateGroup,
}
