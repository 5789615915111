/* eslint-disable @typescript-eslint/naming-convention */
export enum FeatureKeys {
  AutoReply = 'dashboard_auto_reply',
  BoosterThemeToggle = 'dashboard_booster_theme_toggle',
  DistressedUsers = 'dashboard_distressed_users',
  Entitlements = 'dashboard_entitlements',
  NoticeBoard = 'dashboard_team_noticeboard',
  Partners = 'dashboard_partners',
  ReflectionSubQuestions = 'dashboard_reflection_sub_questions',
  ShowJournalGroups = 'dashboard_show_journal_groups',
  SpeakUp = 'dashboard_speakup',
  TipsNavigation = 'dashboard_tips_navigation',
  VideoUpdates = 'dashboard_video_updates',
}
