import Typography from '@weareroam/cake-ui-v1/Typography'
import DragIcon from 'components/atoms/Icons/DragIndicator'
import EditActions from 'components/molecules/EditActions'
import PropTypes from 'prop-types'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

export const StyledBox = styled.div`
  background: ${({ theme }) => theme.palette.secondary.main};
  border: 1px solid
    ${({ theme, errors }) =>
      errors.length ? theme.palette.error.light : theme.palette.tertiary.light};
  box-shadow: inset 0 1px 1px 0 white;
  border-radius: 4px;
`
export const StyledHeader = styled.header`
  position: relative;
  padding: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
export const StyledContent = styled.div`
  position: relative;
`

export const StyledFooter = styled.footer`
  margin-top: -1px;
  border-top: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const StyledDragIcon = styled(DragIcon)`
  cursor: move;
  margin-right: ${({ theme }) => theme.spacing.md}px;
`

export const HeaderContent = styled.div`
  flex-grow: 1;
`

export const StyledError = styled.div`
  background-color: ${({ theme }) => theme.palette.error.light};
  color: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.lg}px;
`

export const DraggableBox = styled.div`
  box-shadow: ${({ isDragging }) =>
    isDragging
      ? '2px 6px 7px 0 rgba(197,197,197,0.25), 0 2px 3px 0 rgba(35,38,43,0.05), 0 0 1px 0 rgba(28,35,50,0.14), inset 0 1px 1px 0 #FFFFFF'
      : ''};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Box({
  headerContent,
  footerContent,
  children,
  isDraggable,
  className,
  isEditable,
  onEditClick,
  onDeleteClick,
  draggableId,
  draggableIndex,
  errors,
}) {
  const content = (dragHandleProps) => (
    <StyledBox className={className} errors={errors}>
      {(headerContent || isDraggable) && (
        <StyledHeader>
          {isDraggable && dragHandleProps && (
            <span {...dragHandleProps}>
              <StyledDragIcon />
            </span>
          )}
          <HeaderContent>{headerContent}</HeaderContent>
          {isEditable && (
            <EditActions onEdit={onEditClick} onDelete={onDeleteClick} />
          )}
        </StyledHeader>
      )}
      {children && <StyledContent>{children}</StyledContent>}
      {footerContent && <StyledFooter>{footerContent}</StyledFooter>}
      {errors.length ? (
        <StyledError>
          {errors.map((error, index) => (
            <Typography variant="body2" key={index}>
              {error}
            </Typography>
          ))}
        </StyledError>
      ) : null}
    </StyledBox>
  )

  // If it is not draggable, just return the default content
  if (!isDraggable || !draggableId) {
    return content()
  }

  // If it is draggable, wrap it in a Draggable component.
  // NOTE: Also requires a DragDropContext as a parent
  return (
    <Draggable
      draggableId={draggableId}
      index={draggableIndex}
      isDragDisabled={!isDraggable}>
      {(provided, snapshot) => (
        <DraggableBox
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
          style={provided.draggableProps.style}>
          {content(provided.dragHandleProps)}
        </DraggableBox>
      )}
    </Draggable>
  )
}

Box.propTypes = {
  draggableId: PropTypes.string,
  draggableIndex: PropTypes.number,
  headerContent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  isDraggable: PropTypes.bool,
  isEditable: PropTypes.bool,
  footerContent: PropTypes.node,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  errors: PropTypes.array,
}

Box.defaultProps = {
  errors: [],
}

export default Box
