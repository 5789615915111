import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { reflectionListSchema } from './schema'

// Actions
export const API_GET_REFLECTIONS_REQUEST = 'API_GET_REFLECTIONS_REQUEST'
export const API_GET_REFLECTIONS_SUCCESS = 'API_GET_REFLECTIONS_SUCCESS'
export const API_GET_REFLECTIONS_ERROR = 'API_GET_REFLECTIONS_ERROR'

export const API_ADD_ORGANISATION_REFLECTION_REQUEST =
  'API_ADD_ORGANISATION_REFLECTION_REQUEST'
export const API_ADD_ORGANISATION_REFLECTION_SUCCESS =
  'API_ADD_ORGANISATION_REFLECTION_SUCCESS'
export const API_ADD_ORGANISATION_REFLECTION_ERROR =
  'API_ADD_ORGANISATION_REFLECTION_ERROR'

export const API_CREATE_REFLECTION_REQUEST = 'API_CREATE_REFLECTION_REQUEST'
export const API_CREATE_REFLECTION_SUCCESS = 'API_CREATE_REFLECTION_SUCCESS'
export const API_CREATE_REFLECTION_ERROR = 'API_CREATE_REFLECTION_ERROR'

export const API_UPDATE_REFLECTION_REQUEST = 'API_UPDATE_REFLECTION_REQUEST'
export const API_UPDATE_REFLECTION_SUCCESS = 'API_UPDATE_REFLECTION_SUCCESS'
export const API_UPDATE_REFLECTION_ERROR = 'API_UPDATE_REFLECTION_ERROR'

export const API_DELETE_REFLECTION_REQUEST = 'API_DELETE_REFLECTION_REQUEST'
export const API_DELETE_REFLECTION_SUCCESS = 'API_DELETE_REFLECTION_SUCCESS'
export const API_DELETE_REFLECTION_ERROR = 'API_DELETE_REFLECTION_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getReflections(orgUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_REFLECTIONS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/v2/reflection`,
      )
      const data = normalize(response.data.data, reflectionListSchema)

      dispatch({
        type: API_GET_REFLECTIONS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_REFLECTIONS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the reflections. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

/**
 * @deprecated Please use the CRUD API instead
 * @param orgUuid
 * @param reflections
 * @returns {(function(*): Promise<void>)|*}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateOrganisationReflections({ orgUuid, reflections }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_ADD_ORGANISATION_REFLECTION_REQUEST })
    try {
      const response = await axios.patch(
        `${SAUCE_API_URL}/organisation/reflection`,
        {
          orgUuid,
          reflections,
        },
      )
      const data = normalize(response.data.data, reflectionListSchema)
      dispatch({
        type: API_ADD_ORGANISATION_REFLECTION_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Reflection successfully added.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_ADD_ORGANISATION_REFLECTION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error adding the reflection for this organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createReflection({ orgUuid, reflection }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_REFLECTION_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/v2/reflection`,
        reflection,
      )
      const data = normalize(response.data.data, reflectionListSchema)
      dispatch({ type: API_CREATE_REFLECTION_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The reflection was successfully created!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_CREATE_REFLECTION_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error creating reflection. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateReflection({ orgUuid, reflectionUuid, reflection }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_REFLECTION_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/v2/reflection/${reflectionUuid}`,
        reflection,
      )
      const data = normalize(response.data.data, reflectionListSchema)
      dispatch({ type: API_UPDATE_REFLECTION_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The reflection has been successfully updated',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_REFLECTION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error updating this reflection. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteReflection({ orgUuid, reflectionUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_REFLECTION_REQUEST })

    try {
      await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/v2/reflection/${reflectionUuid}`,
      )

      dispatch({
        type: API_DELETE_REFLECTION_SUCCESS,
        data: {
          uuid: reflectionUuid,
        },
      })

      dispatch(
        addToast({
          message: 'The reflection was successfully deleted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )

      return true
    } catch (error) {
      dispatch({ type: API_DELETE_REFLECTION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the reflection. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}
