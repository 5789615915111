import { HumanReadableDatetime } from 'constants/date'

import { Typography } from '@weareroam/cake-ui-v1'
import { format } from 'date-fns'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

export const JournalNotification = styled.div`
  flex: 1;
`

const NotificationArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 10.5rem;
`

const NotificationIndicatorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NotificationSentDate = styled(Typography)`
  width: 10.5rem;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

interface Props {
  displayText: string
  createdAt: Date | null
  acknowledged: boolean
}

export const NotificationCell = ({
  displayText,
  createdAt,
  acknowledged,
}: Props): ReactElement => (
  <JournalNotification>
    <NotificationArea>
      {createdAt && (
        <>
          <NotificationIndicatorRow>
            <Typography variant="body2">
              <strong>{displayText}</strong>
            </Typography>
          </NotificationIndicatorRow>
          <NotificationSentDate variant="body2">
            {format(createdAt, HumanReadableDatetime)} (
            {acknowledged ? 'acknowledged' : 'not acknowledged'})
          </NotificationSentDate>
        </>
      )}
    </NotificationArea>
  </JournalNotification>
)
