import MenuItemComponent from '@weareroam/cake-ui-v1/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import { toClass } from 'recompose'
import styled from 'styled-components'

export const StyledMenuItem = styled(MenuItemComponent)`
  && {
    color: ${({ theme, color }) => theme.palette[color].main};
    text-transform: capitalize;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function MenuItem({
  children,
  className,
  color,
  disabled,
  onClick,
  value,
}) {
  return (
    <StyledMenuItem
      className={className}
      color={color}
      disabled={disabled}
      onClick={onClick}
      value={value}>
      {children}
    </StyledMenuItem>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'quinary',
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.any,
}

MenuItem.defaultProps = {
  color: 'quinary',
  disabled: false,
}

// needs to be converted to a class so it can have a ref
export default toClass(MenuItem)
