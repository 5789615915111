import Typography from '@weareroam/cake-ui-v1/Typography'
import Active from 'components/atoms/Icons/Active'
import Calm from 'components/atoms/Icons/Calm'
import Cheerful from 'components/atoms/Icons/Cheerful'
import Interested from 'components/atoms/Icons/Interested'
import Restful from 'components/atoms/Icons/Restful'
import MoreActions from 'components/molecules/MoreActions'
import DOMPurify from 'dompurify'
import { Tip } from 'modules/tips/schema'
import React from 'react'
import styled from 'styled-components'

const StyledTipItem = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;
  align-items: space-between;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

const TipBody = styled.div`
  width: 80%;
`

const TipTags = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  width: 5%;
  text-align: left;
`

const TipIcon = styled.div`
  margin-top: -${({ theme }) => theme.spacing.xs}px;
`

const TipActions = styled.div`
  width: 5%;
  text-align: right;
  z-index: 2;
`

const TipText = styled(Typography)`
  && {
    display: inline;
  }
`

const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`

const getTipIcon = (tag: string): React.ReactNode => {
  switch (tag) {
    case 'ACTIVE':
      return <Active />
    case 'CALM':
      return <Calm />
    case 'CHEERFUL':
      return <Cheerful />
    case 'INTERESTED':
      return <Interested />
    case 'RESTFUL':
      return <Restful />
    default:
      return null
  }
}

/* 
  Bit of opinionated future proofing incase we extend the 
  tags at the service level to have multiple, comma delimited tags
*/
const getFirstTagFromTags = (tags: string) => {
  const pieces = tags.split(',')
  return pieces.length ? pieces[0] : ''
}

interface MoreActionsAction {
  key: string
  label: string
  onClick: () => void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function TipItem({
  uuid,
  body,
  tags,
  actions,
  onClick,
}: Tip & { actions: MoreActionsAction[]; onClick: (uuid: string) => void }) {
  const handleOnClick = React.useCallback(() => {
    if (onClick) {
      onClick(uuid)
    }
  }, [uuid, onClick])

  return (
    <StyledTipItem>
      <ClickArea onClick={handleOnClick} />
      <TipBody>
        <TipText
          // eslint-disable-next-line @typescript-eslint/naming-convention
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
          variant="body1"
          component="span"
        />
      </TipBody>
      <TipTags>
        <Typography variant="body2" component="span">
          <strong>{tags}</strong>
        </Typography>
      </TipTags>
      <TipIcon>{getTipIcon(getFirstTagFromTags(tags))}</TipIcon>
      <TipActions>
        <MoreActions direction="vertical" color="primary" actions={actions} />
      </TipActions>
    </StyledTipItem>
  )
}

export default TipItem
