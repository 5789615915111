import {
  ROLE_SUPER_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import {
  SIGN_UP_ORGANISATION,
  ORG_DASHBOARD_OVERVIEW,
  LOGIN,
  CHNNL_DASHBOARD_ORGANISATIONS,
  ORG_DASHBOARD_GROUPS,
  PARTNER_ADMIN_DASHBOARD_ORGANISATIONS,
} from 'constants/routes'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX, SAUCE_API_URL } from 'config'
import { addOrganisation } from 'modules/organisations/actions'
import { redirect } from 'modules/route'
import { addToast } from 'modules/toasts/actions'
import { MINIMUM_VIEWPORT_WIDTH } from 'styles/theme'
import { replaceRouteParams } from 'utils/routes'

import { getApiErrorMessage } from '../../utils/api'
import {
  getUserOrganisation,
  getUserPartner,
  getUserRole,
  hashPassword,
} from '../../utils/authentication'

// Actions
export const API_LOGIN_REQUEST = 'API_LOGIN_REQUEST'
export const API_LOGIN_SUCCESS = 'API_LOGIN_SUCCESS'
export const API_LOGIN_ERROR = 'API_LOGIN_ERROR'

export const API_LOGOUT_REQUEST = 'API_LOGOUT_REQUEST'
export const API_LOGOUT_SUCCESS = 'API_LOGOUT_SUCCESS'
export const API_LOGOUT_ERROR = 'API_LOGOUT_ERROR'

export const API_ROLE_REQUEST = 'API_ROLE_REQUEST'
export const API_ROLE_SUCCESS = 'API_ROLE_SUCCESS'
export const API_ROLE_ERROR = 'API_ROLE_ERROR'

export const API_RESET_PASSWORD_REQUEST_REQUEST =
  'API_RESET_PASSWORD_REQUEST_REQUEST'
export const API_RESET_PASSWORD_REQUEST_SUCCESS =
  'API_RESET_PASSWORD_REQUEST_SUCCESS'
export const API_RESET_PASSWORD_REQUEST_ERROR =
  'API_RESET_PASSWORD_REQUEST_ERROR'

export const API_RESET_PASSWORD_REQUEST = 'API_RESET_PASSWORD_REQUEST'
export const API_RESET_PASSWORD_SUCCESS = 'API_RESET_PASSWORD_SUCCESS'
export const API_RESET_PASSWORD_ERROR = 'API_RESET_PASSWORD_ERROR'

export const API_REFRESH_SESSION_REQUEST = 'API_REFRESH_SESSION_REQUEST'
export const API_REFRESH_SESSION_SUCCESS = 'API_REFRESH_SESSION_SUCCESS'
export const API_REFRESH_SESSION_ERROR = 'API_REFRESH_SESSION_ERROR'

export const API_VERIFY_ACCOUNT_REQUEST = 'API_VERIFY_ACCOUNT_REQUEST'
export const API_VERIFY_ACCOUNT_SUCCESS = 'API_VERIFY_ACCOUNT_SUCCESS'
export const API_VERIFY_ACCOUNT_ERROR = 'API_VERIFY_ACCOUNT_ERROR'

export const API_CHANGE_PASSWORD_REQUEST = 'API_CHANGE_PASSWORD_REQUEST'
export const API_CHANGE_PASSWORD_SUCCESS = 'API_CHANGE_PASSWORD_SUCCESS'
export const API_CHANGE_PASSWORD_ERROR = 'API_CHANGE_PASSWORD_ERROR'

export const SAVE_AUTH_INFO = 'SAVE_AUTH_INFO'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function saveAuthInfo({ token, role, organisationUuid, partnerUuid }) {
  return {
    type: SAVE_AUTH_INFO,
    data: {
      token,
      role,
      organisationUuid,
      partnerUuid,
    },
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function login({ email, password }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGIN_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/auth/login`, {
        email,
        password: hashPassword(password),
      })

      const { token, refreshToken, role, organisation, partner } =
        response.data.data[0]

      dispatch(saveAuthInfo({ token, refreshToken, role }))

      dispatch({
        type: API_LOGIN_SUCCESS,
      })

      switch (role) {
        case ROLE_SUPER_ADMIN:
          // Redirect super admins to the chnnl dashboard
          dispatch(redirect(CHNNL_DASHBOARD_ORGANISATIONS))
          break
        case ROLE_GROUP_ADMIN:
          // redirect group admins to the group dashboard
          if (organisation) {
            dispatch(
              redirect(
                replaceRouteParams(ORG_DASHBOARD_GROUPS, {
                  orgUuid: organisation.uuid,
                }),
              ),
            )
          }
          break
        case ROLE_PARTNER_ADMIN:
          // Redirect partner admins to the partner organisation page
          dispatch(
            redirect(
              replaceRouteParams(PARTNER_ADMIN_DASHBOARD_ORGANISATIONS, {
                partnerUuid: partner.uuid,
              }),
            ),
          )
          break
        default:
          // Redirect all other users to the organisation dashboard
          if (organisation) {
            dispatch(
              redirect(
                replaceRouteParams(ORG_DASHBOARD_OVERVIEW, {
                  orgUuid: organisation.uuid,
                }),
              ),
            )
          }
          break
      }
    } catch (error) {
      dispatch({ type: API_LOGIN_ERROR })
      dispatch(
        addToast({
          message:
            'Your email address and password do not match, please try again or contact your administrator.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function authenticatedRedirect() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    try {
      const role = getUserRole()
      const organisationUuid = getUserOrganisation()
      const partnerUuid = getUserPartner()

      switch (role) {
        case ROLE_SUPER_ADMIN:
          // Redirect super admins to the chnnl dashboard
          dispatch(redirect(CHNNL_DASHBOARD_ORGANISATIONS))
          break
        case ROLE_GROUP_ADMIN:
          // redirect group admins to the group dashboard
          if (organisationUuid) {
            dispatch(
              redirect(
                replaceRouteParams(ORG_DASHBOARD_GROUPS, {
                  orgUuid: organisationUuid,
                }),
              ),
            )
          }
          break
        case ROLE_PARTNER_ADMIN:
          // Redirect partner admins to the partner organisation page
          dispatch(
            redirect(
              replaceRouteParams(PARTNER_ADMIN_DASHBOARD_ORGANISATIONS, {
                partnerUuid: partnerUuid,
              }),
            ),
          )
          break
        default:
          // Redirect all other users to the organisation dashboard
          if (organisationUuid) {
            dispatch(
              redirect(
                replaceRouteParams(ORG_DASHBOARD_OVERVIEW, {
                  orgUuid: organisationUuid,
                }),
              ),
            )
          }
          break
      }
    } catch (error) {
      dispatch(
        addToast({
          message:
            'Failed to establish your user role. Please try again or contact your administrator.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function verifyAccount({ firstName, lastName, password, token }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_VERIFY_ACCOUNT_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/verify`,
        {
          token,
          firstName,
          lastName,
          password: hashPassword(password),
        },
      )

      dispatch({ type: API_VERIFY_ACCOUNT_SUCCESS })

      const {
        organisation,
        token: verifiedToken,
        role,
        refreshToken,
        partner,
      } = response.data.data[0]

      dispatch(saveAuthInfo({ token: verifiedToken, refreshToken, role }))

      if (role === ROLE_PARTNER_ADMIN && partner) {
        dispatch(
          redirect(
            replaceRouteParams(PARTNER_ADMIN_DASHBOARD_ORGANISATIONS, {
              partnerUuid: partner.uuid,
            }),
          ),
        )
      } else if (
        role === ROLE_ORG_OWNER &&
        organisation &&
        !organisation.logo
      ) {
        // org owners have a 2 step sign up process
        // prepopulate name for org on the next form then redirect
        dispatch(addOrganisation(organisation))
        dispatch(
          redirect(
            replaceRouteParams(SIGN_UP_ORGANISATION, {
              orgUuid: organisation.uuid,
            }),
          ),
        )
      } else if (role === ROLE_GROUP_ADMIN && organisation) {
        dispatch(
          redirect(
            replaceRouteParams(ORG_DASHBOARD_GROUPS, {
              orgUuid: organisation.uuid,
            }),
          ),
        )
      } else if (organisation) {
        // redirect other users to their org dashboard
        dispatch(
          redirect(
            replaceRouteParams(ORG_DASHBOARD_OVERVIEW, {
              orgUuid: organisation.uuid,
            }),
          ),
        )
      } else {
        throw new Error('Verification failed')
      }
    } catch (error) {
      dispatch({ type: API_VERIFY_ACCOUNT_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error verifying your account. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function logout() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGOUT_REQUEST })

    try {
      await axios.post(`${SAUCE_API_URL}/auth/logout`)
      dispatch({ type: API_LOGOUT_SUCCESS })
    } catch (error) {
      dispatch({ type: API_LOGOUT_ERROR })
    }

    dispatch(redirect(LOGIN))
  }
}

export function refreshSession() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_REFRESH_SESSION_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/auth/refresh`, {
        refreshToken: getState().authentication.refreshToken,
      })

      const { token, refreshToken } = response.data.data[0]

      dispatch(saveAuthInfo({ token, refreshToken }))

      dispatch({
        type: API_REFRESH_SESSION_SUCCESS,
      })

      return true
    } catch (error) {
      dispatch({
        type: API_REFRESH_SESSION_ERROR,
        error,
      })

      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resetPasswordRequest(email) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_PASSWORD_REQUEST_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/auth/requestnew`, {
        email,
      })
      dispatch({ type: API_RESET_PASSWORD_REQUEST_SUCCESS })
      return response
    } catch (error) {
      dispatch({ type: API_RESET_PASSWORD_REQUEST_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error sending a reset password email. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return null
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resetPassword(password, token) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_PASSWORD_REQUEST })

    try {
      // we hash the password, so we need to validate password strength before calling the api
      if (password.length < PASSWORD_MIN_LENGTH) {
        dispatch(
          addToast({
            message: `Your password must be at least ${PASSWORD_MIN_LENGTH} characters`,
            type: TOAST_TYPE_ERROR,
          }),
        )
        return
      }
      if (!password.match(PASSWORD_REGEX)) {
        dispatch(
          addToast({
            message:
              'Your password must contain one uppercase, lowercase and special character',
            type: TOAST_TYPE_ERROR,
          }),
        )
        return
      }

      await axios.post(`${SAUCE_API_URL}/auth/reset`, {
        token,
        passwordHash: hashPassword(password),
      })

      dispatch({ type: API_RESET_PASSWORD_SUCCESS })

      dispatch(
        addToast({
          message: 'Your password has successfully been reset!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )

      // pretty filthy but don't need to cater for resizing
      const WINDOW_WIDTH = window.innerWidth

      if (WINDOW_WIDTH > MINIMUM_VIEWPORT_WIDTH) {
        dispatch(redirect(LOGIN))
      }
    } catch (error) {
      dispatch({ type: API_RESET_PASSWORD_ERROR })
      dispatch(
        addToast({
          message: getApiErrorMessage(
            error,
            'There was an error resetting your password. Please try again',
          ),
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function changePassword(currentPass, newPass) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CHANGE_PASSWORD_REQUEST })

    try {
      await axios.put(`${SAUCE_API_URL}/auth/update`, {
        password: hashPassword(currentPass),
        passwordHash: hashPassword(newPass),
      })

      dispatch({ type: API_CHANGE_PASSWORD_SUCCESS })
      dispatch(
        addToast({
          message: 'Your password has successfully been changed!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_CHANGE_PASSWORD_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error changing your password. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export const authenticationActions = {
  saveAuthInfo,
  login,
  verifyAccount,
  logout,
  refreshSession,
  resetPasswordRequest,
  resetPassword,
  changePassword,
}
