import React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
function Interested() {
  return (
    <svg width="25px" height="25px" viewBox="0 0 82.25 102.33">
      <g data-name="Layer 2">
        <g data-name="WHO-5 categories">
          <g>
            <path
              d="M77.74 39.26c-32.67-3-35.1-5.39-38.11-37.76-3 32.37-5.45 34.77-38.13 37.76 32.68 3 35.11 5.4 38.13 37.76 3.01-32.36 5.44-34.79 38.11-37.76zM26.16 72C25 84.36 24.07 85.28 11.63 86.42c12.44 1.12 13.37 2.07 14.53 14.41 1.13-12.34 2.06-13.29 14.51-14.41C28.22 85.28 27.29 84.36 26.16 72zM71.3 67.22c.75-8 1.36-8.6 9.45-9.35-8.09-.74-8.7-1.33-9.45-9.34-.74 8-1.35 8.6-9.44 9.34 8.14.75 8.7 1.34 9.44 9.35z"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#aad5b7"
              strokeWidth="3px"
            />
            <path
              d="M52.2 49.88s-5.66 2.36-7.83 25.18M39.2 28.88s-1 9-10 9"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#5c8368"
              strokeWidth="2.5px"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default React.memo(Interested)
