import { DMYFull, HumanReadableDatetime } from 'constants/date'

import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { ReflectionTag } from 'components/molecules/ReflectionTag'
import { format } from 'date-fns'
import { Form, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { JournalSurvey } from '../../../modules/journals/schema'
import ProgressLoader from '../../atoms/ProgressLoader'

import { FormValues, InnerFormProps } from './index'

const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.mdlg}px;
  display: flex;
  justify-content: space-between;
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const Date = styled(Typography)`
  && {
    display: flex;
    align-items: center;
  }
`

const VerticallyCentered = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`

const OriginalEntry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
  text-align: left;
`

const OriginalEntryLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
    font-size: 11px;
  }
`
const ReflectionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  display: flex !important;
  justify-self: flex-start;
  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
    font-size: 11px;
  }
`

const SurveyScore = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

const OriginalEntryText = styled(Typography)`
  overflow-y: auto;
  height: 93px;
`

const Reply = styled.div`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`

const ReplyLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    font-size: 11px;
  }
`

const ReplyText = styled(Typography)`
  overflow-y: auto;
  height: 109px;

  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
`

const ReplyTextArea = styled(TextField)`
  div {
    padding-top: 0;
  }
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }
`

export const CharCount = styled(Typography)`
  && {
    margin-left: auto;
    display: flex;
    align-self: end;
    font-size: 8px;
  }

  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ActionsLeft = styled.div``
const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child:last-child {
        /* this is a more specific only-child, it will win for a single button */
        margin-left: auto;
        width: 149px;
      }
    }
  }
`

const Tags = styled.div`
  margin-left: auto;
  display: flex;
  align-self: end;

  && {
    > * {
      margin: 0 3px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Reflections = styled.div`
  margin-left: auto;
  display: flex;
  align-self: end;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;

  && {
    > * {
      margin: 3px 3px 3px 0;
    }
  }
`

const YellowTag = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.tags.yellow};
  font-size: 8px;
  padding: 4px;
  border-radius: 4px;
`

const RedTag = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.tags.red};
  font-size: 8px;
  padding: 4px;
  border-radius: 4px;
`

const BlackTag = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.tags.black};
  font-size: 8px;
  padding: 4px;
  border-radius: 4px;
`

export function JournalReplyInnerForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,

  journal,
  replies,
  currentUser,
  progress,
  storeReply,
  onCancel,
}: InnerFormProps & FormikProps<FormValues>): React.ReactElement {
  // Drill the current reply text up to the state of the modal on each change. So it's preserved between tab changes.
  useEffect(() => {
    storeReply?.(values.reply)
  }, [storeReply, values.reply])

  if (progress.inProgress) {
    return <ProgressLoader fullWidth />
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  function SurveyInfo({ survey }: { survey: JournalSurvey }) {
    if (survey === undefined || survey === null || survey?.skipped) {
      return (
        <ReflectionLabel variant="body2">
          No survey recorded for the day
        </ReflectionLabel>
      )
    }
    return (
      <div>
        <ReflectionLabel variant="body2">
          Survey score&nbsp;<SurveyScore>{survey.score * 20}</SurveyScore>
        </ReflectionLabel>
        <ReflectionLabel variant="body2">Reflections</ReflectionLabel>
        <Reflections>
          {(survey.userReflectionResponses ?? []).map((userReflection, i) => (
            <ReflectionTag key={i} reflection={userReflection.reflection} />
          ))}
        </Reflections>
      </div>
    )
  }

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <StyledHeader>
        <Typography variant="body2">
          <strong>{journal.organisation.name}</strong> &mdash;{' '}
          {journal.group.name}
        </Typography>
        <Date variant="body2" component="div">
          <strong>Journal date&nbsp;</strong>
          {format(journal.forDate, DMYFull)}
        </Date>
      </StyledHeader>

      <SurveyInfo survey={journal?.survey} />

      <OriginalEntry>
        <VerticallyCentered>
          <OriginalEntryLabel variant="body2">
            Original entry created on{' '}
            {format(journal.createdAt, HumanReadableDatetime)}
          </OriginalEntryLabel>
          <Tags>
            {journal.redFlagged && <RedTag>Red flag</RedTag>}
            {journal.yellowFlagged && <YellowTag>Yellow flag</YellowTag>}
          </Tags>
        </VerticallyCentered>
        <OriginalEntryText variant="body2">
          {journal.originalEntry}
        </OriginalEntryText>
      </OriginalEntry>

      {progress.hasError && (
        <Reply>
          <ErrorText>
            There was an error while fetching replies. Please try again.
          </ErrorText>
        </Reply>
      )}

      {!progress.hasError && replies.length > 0 && (
        <Reply>
          <StyledHeader>
            <div></div>
            <Date variant="body2" component="div">
              <strong>Sent&nbsp;</strong>
              {format(replies[0].createdAt, HumanReadableDatetime)}
              <strong>
                &nbsp;
                {replies[0].acknowledged ? 'Acknowledged' : 'Not acknowledged'}
              </strong>
            </Date>
          </StyledHeader>
          <VerticallyCentered>
            <ReplyLabel variant="body2">Reply</ReplyLabel>
            <Tags>
              <BlackTag>
                {currentUser.uuid === replies[0].authorUuid
                  ? 'By yourself'
                  : 'By someone else'}
              </BlackTag>
            </Tags>
          </VerticallyCentered>
          <ReplyText variant="body2">{replies[0].message}</ReplyText>
        </Reply>
      )}
      {!progress.hasError && replies.length === 0 && (
        <Reply>
          <VerticallyCentered>
            <ReplyLabel variant="body2">Your message</ReplyLabel>
            <CharCount variant="body2">
              Character count: {values.reply.length || 0} / 700
            </CharCount>
          </VerticallyCentered>
          <ReplyTextArea
            id="reply"
            name="reply"
            type="text"
            value={values.reply}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.reply && !!errors.reply}
            helperText={touched.reply ? errors.reply : ''}
            fullWidth
            multiline
            rows={6}
            InputProps={{
              disableUnderline: true,
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            variant={'standard' as any} // Types for textarea are a bit broken
          />
        </Reply>
      )}

      <Actions>
        <ActionsLeft />
        {(progress.hasError || replies.length > 0) && (
          <ActionsRight>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              disabled={isSubmitting}
              onClick={() => onCancel()}>
              Close
            </Button>
          </ActionsRight>
        )}
        {!progress.hasError && replies.length === 0 && (
          <ActionsRight>
            <Button
              variant="outlined"
              type="button"
              color="primary"
              fullWidth
              size="small"
              disabled={isSubmitting}
              onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              color="primary"
              size="small"
              disabled={isSubmitting || !isValid}>
              Send message
            </Button>
          </ActionsRight>
        )}
      </Actions>
    </Form>
  )
}
