import { TOGGLE_ALL } from 'constants/form'

import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import DropdownButton from 'components/molecules/DropdownButton'
import MenuItem from 'components/molecules/MenuItem'
import { QueryFilters } from 'hooks/useDistressedUsers'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { enhance, InjectedProps } from './DistressedUsersHeader.enhancer'

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const AllOrganisationsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

export interface IProps extends InjectedProps {
  onOrganistionsChange: (val: string[]) => void
  onFiltersChange: (filter: QueryFilters | null) => void
}

export const DistressedUsersHeaderComponent = ({
  organisations,
  getOrgsProgress,
  actions: { getOrganisations },
  onOrganistionsChange,
  onFiltersChange,
}: IProps): React.ReactElement => {
  useEffect(() => {
    if (!organisations.length) {
      getOrganisations()
    }
  }, [organisations, getOrganisations])

  const {
    filteredEntities: filteredOrganisations,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(
    organisations,
    'organisation',
    !getOrgsProgress.inProgress,
  )

  const [filter, setFilter] = useState<QueryFilters | null>(null)

  useEffect(() => {
    onOrganistionsChange(filteredOrganisations)
  }, [filteredOrganisations, onOrganistionsChange])

  return (
    <StyledHeader>
      <div>
        <DropdownButton
          name="organisations"
          value={filteredOrganisations}
          onChange={handleChangeFilteredEntities}
          renderValue={renderFilterLabel}>
          <MenuItem
            value={TOGGLE_ALL}
            className={undefined}
            disabled={false}
            color={undefined}
            onClick={undefined}>
            <Checkbox
              color="primary"
              checked={filteredOrganisations.length === organisations.length}
            />
            <AllOrganisationsLabel primary="All organisations" />
          </MenuItem>
          {organisations.map((organisation) => (
            <MenuItem
              key={organisation.uuid}
              value={organisation.uuid}
              className={undefined}
              disabled={false}
              color={undefined}
              onClick={undefined}>
              <Checkbox
                color="primary"
                checked={filteredOrganisations.includes(organisation.uuid)}
              />
              <ListItemText primary={organisation.name} />
            </MenuItem>
          ))}
        </DropdownButton>
      </div>
      <div>
        <Filters>
          <ButtonSwitch
            size="medium"
            value={null}
            name="journalType"
            isActive={filter === null}
            onClick={() => {
              setFilter(null)
              onFiltersChange(null)
            }}>
            All entries
          </ButtonSwitch>
          <ButtonSwitch
            size="medium"
            value={QueryFilters.TO_REVIEW}
            name="journalType"
            isActive={filter === QueryFilters.TO_REVIEW}
            onClick={() => {
              // TODO: utilise hook state and make this less imperative
              setFilter(QueryFilters.TO_REVIEW)
              onFiltersChange(QueryFilters.TO_REVIEW)
            }}>
            To Review
          </ButtonSwitch>
        </Filters>
      </div>
    </StyledHeader>
  )
}

export const DistressedUsersHeader = enhance(DistressedUsersHeaderComponent)
