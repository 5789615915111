import React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
function Cheerful() {
  return (
    <svg width="25px" height="25px" viewBox="0 0 87.54 87.54">
      <g data-name="Layer 2">
        <g data-name="WHO-5 categories">
          <g>
            <path
              d="M43.75 0a1.56 1.56 0 00-1.54 1.58V9.4a1.56 1.56 0 103.12 0V1.58A1.56 1.56 0 0043.79 0zM27.83 3.13a1.55 1.55 0 00-1.56 1.56 1.58 1.58 0 00.12.61l3 7.26a1.56 1.56 0 002.93-1.09.36.36 0 000-.09l-3-7.26a1.54 1.54 0 00-1.45-1zm31.84 0a1.54 1.54 0 00-1.41 1l-3 7.25a1.56 1.56 0 102.85 1.27.36.36 0 000-.09l3-7.25a1.56 1.56 0 00-.83-2 1.45 1.45 0 00-.65-.12zm-45.75 9.23a1.56 1.56 0 00-1.56 1.57 1.58 1.58 0 00.47 1.12l5.54 5.53a1.56 1.56 0 002.26-2.16l-.05-.05L15 12.84a1.52 1.52 0 00-1.12-.48zm59.65 0a1.5 1.5 0 00-1.07.48L67 18.37a1.57 1.57 0 000 2.21 1.55 1.55 0 002.21.05v-.05l5.54-5.53a1.57 1.57 0 00-1.14-2.69zm-29.8 1.72a29.69 29.69 0 1029.69 29.69 29.71 29.71 0 00-29.69-29.69zm0 3.13a26.56 26.56 0 11-26.56 26.56 26.54 26.54 0 0126.56-26.56zM4.69 26.27a1.56 1.56 0 00-.57 3l7.25 3a1.56 1.56 0 101.27-2.85h-.09l-7.25-3a1.62 1.62 0 00-.61-.12zm78.12 0a1.57 1.57 0 00-.57.12l-7.25 3a1.56 1.56 0 101.08 2.93h.09l7.26-3a1.57 1.57 0 00.89-2 1.55 1.55 0 00-1.5-1zM1.58 42.21a1.56 1.56 0 100 3.12H9.4a1.56 1.56 0 000-3.12H1.58zm76.57 0a1.56 1.56 0 10-.05 3.12H86a1.56 1.56 0 000-3.12h-7.85zm-66.21 13a1.31 1.31 0 00-.56.12l-7.26 3a1.56 1.56 0 001.08 2.86h.1l7.25-3a1.56 1.56 0 00-.61-3zm63.62 0a1.56 1.56 0 00-.57 3l7.25 3a1.56 1.56 0 101.27-2.85h-.09l-7.25-3a1.58 1.58 0 00-.61-.12zM19.45 66.49a1.58 1.58 0 00-1.08.47l-5.54 5.54A1.56 1.56 0 0015 74.75l5.54-5.54a1.56 1.56 0 000-2.21 1.52 1.52 0 00-1.17-.47zm48.6 0a1.56 1.56 0 00-1.57 1.56 1.59 1.59 0 00.52 1.12l5.54 5.54a1.56 1.56 0 002.25-2.17L69.17 67a1.58 1.58 0 00-1.12-.51zM56.73 74a1.55 1.55 0 00-1.55 1.56 1.58 1.58 0 00.12.61l3 7.25a1.56 1.56 0 102.93-1.08v-.09l-3-7.26a1.54 1.54 0 00-1.5-.99zm-26 0a1.57 1.57 0 00-1.41 1l-3 7.26a1.56 1.56 0 002.86 1.27v-.1l3-7.25a1.57 1.57 0 00-.83-2 1.82 1.82 0 00-.65-.12zm13 2.56a1.56 1.56 0 00-1.54 1.59V86a1.56 1.56 0 103.12 0v-7.85a1.56 1.56 0 00-1.54-1.59z"
              fill="#aad5b7"
              fillRule="evenodd"
            />
            <path
              d="M44.84 22.49s12 0 16.45 9.34"
              fill="none"
              stroke="#5c8368"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default React.memo(Cheerful)
