import { PAGINATION_LIMIT } from '../../constants/pagination'
import { PaginationParams } from '../../hooks/types'
import { initialProgressState, RequestProgressState } from '../progress'

import { InboxMessage } from './interfaces'

export interface MessagingState {
  entities: Record<string, InboxMessage>
  pagination: PaginationParams
  progress: {
    getMessages: RequestProgressState
    sendMessages: RequestProgressState
  }
}

export const messagingInitialState: MessagingState = {
  entities: {},
  pagination: {
    start: 0,
    count: 0,
    limit: PAGINATION_LIMIT,
  },
  progress: {
    getMessages: {
      ...initialProgressState,
    },
    sendMessages: {
      ...initialProgressState,
    },
  },
}
