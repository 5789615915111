import CloseIcon from '@weareroam/cake-ui-v1-icons/Clear'
import ChipInputComponent from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const chipContainerClass = 'chip-container-class'
const chipInputClass = 'chip-input-class'
export const StyledChipInput = styled(ChipInputComponent).attrs({
  classes: {
    chipContainer: chipContainerClass,
    input: chipInputClass,
  },
})`
  .${chipContainerClass} {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    overflow-y: auto;
    max-height: 178px;
    margin-bottom: 4px;
  }

  .${chipInputClass} {
    display: ${({ disableUnderline }) => (disableUnderline ? 'none' : 'block')};
    min-width: 300px;
  }
`

export const StyledChip = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.chip.main};
  padding: ${({ theme }) => theme.spacing.sm}px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 ${({ theme }) => theme.spacing.xs}px
    ${({ theme }) => theme.spacing.xs}px 0;
`

export const StyledIcon = styled(CloseIcon)`
  margin-left: ${({ theme }) => theme.spacing.sm}px;

  && {
    font-size: 20px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChipInput({
  onAdd,
  label,
  value,
  placeholder,
  onDelete,
  helperText,
  error,
  onBlur,
  name,
  textValue,
  onChange,
  className,
  hideinput,
  ...rest
}) {
  const inputProps =
    onChange && textValue !== undefined
      ? {
          onChange,
          value: textValue || '',
        }
      : {}
  return (
    <StyledChipInput
      label={label}
      onAdd={onAdd}
      allowDuplicates={false}
      onDelete={onDelete}
      value={value}
      className={className}
      fullWidth
      fullWidthInput
      helperText={helperText}
      error={error}
      placeholder={placeholder}
      disableUnderline={hideinput}
      {...rest}
      InputProps={{
        onBlur,
        name,
        ...inputProps,
      }}
      chipRenderer={({ text, handleDelete }, key) => (
        <StyledChip key={key} onClick={handleDelete}>
          {text}
          <StyledIcon />
        </StyledChip>
      )}
    />
  )
}

ChipInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  className: PropTypes.string,
  onAdd: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onDelete: PropTypes.func,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  textValue: PropTypes.string,
  helperText: PropTypes.string,
  hideinput: PropTypes.bool,
  inputRef: PropTypes.func,
}

export default ChipInput
