import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import { schema } from 'normalizr'

interface JournalTag {
  tag: string
  journalId: number
}

export interface JournalApproval {
  uuid: string
  forDate: Date
  createdAt: Date
  originalEntry: string
  originalUuid: string
  editedEntry: string
  yellowFlagged: boolean
  redFlagged: boolean
  tags: JournalTag[]
  organisation: {
    name: string
  }
  group: {
    name: string
  }
  survey: JournalSurvey
}

export interface JournalSurvey {
  score: number
  skipped: boolean
  userReflectionResponses: UserReflectionResponse[]
}
export interface Reflection {
  title: string
  sentiment: ReflectionSentimentEnum
}
interface UserReflectionResponse {
  reflection: Reflection
}

/** @deprecated Prefer JournalApproval, especially outside super admin access */
export interface UserJournal {
  uuid: string
  createdAt: Date
  updatedAt: Date | null

  completionDate: Date
  confidenceNegative: number
  confidenceNeutral: number
  confidencePositive: number
  entry: string
  forDate: Date
  iso45003Category: string
  redFlagged: boolean
  sentiment: string // TODO: Enum
  shared: boolean
  skipped: boolean
  yellowFlagged: boolean
}

export const journalSchema = new schema.Entity(
  'journals',
  {},
  { idAttribute: 'uuid' },
)
export const journalListSchema = [journalSchema]
