import initialState from 'reducers/initialState'

import {
  API_GET_GROUP_BOOSTERS_REQUEST,
  API_GET_GROUP_BOOSTERS_SUCCESS,
  API_GET_ORGANISATION_BOOSTERS_SUCCESS,
  API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function boostersReducer(state = initialState.boosters, action) {
  switch (action.type) {
    case API_GET_GROUP_BOOSTERS_REQUEST:
      return {
        ...state,
        entities: {},
      }
    case API_GET_GROUP_BOOSTERS_SUCCESS:
    case API_GET_ORGANISATION_BOOSTERS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.boosters,
        },
      }
    case API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS:
      return {
        ...state,
        entities: action.data.entities.boosters,
      }
    default:
      return state
  }
}
