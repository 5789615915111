import { withFormik } from 'formik'
import { compose, withHandlers } from 'recompose'
import * as Yup from 'yup'

import { PartnersConstants } from '../../../constants/PartnersConstantsEnum'

export default compose(
  withFormik({
    mapPropsToValues: ({ partner }) => ({
      email: '',
      name: '',
      incidentsContactName: '',
      incidentsContactEmail: '',
      numberOfSeats: 100,
      industry: 'General',
      country: 'NZ',
      partnerUuid: partner
        ? partner.uuid
        : PartnersConstants.NO_PARTNER_SELECTED,
    }),
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, 'Must be less than 50 characters long')
        .required('Please enter your organisation name'),
      email: Yup.string()
        .matches(
          /^((?!chnnl).)*$/, // ?! is a negative look ahead
          "Please don't use @chnnl.app emails. Leave empty if owner isn't known yet.",
        )
        .email('Please enter a valid email address'),
      incidentsContactName: Yup.string().required(
        "Please enter contact person's name",
      ),
      incidentsContactEmail: Yup.string()
        .required("Please enter contact person's email address")
        .email('Please enter a valid email address'),
      industry: Yup.string().required('Please select the industry'),
      country: Yup.string().required('Please select a country'),
      partnerUuid: Yup.string().required(
        'Please select if an organisation belongs with a partner',
      ),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
  withHandlers({
    selectPartner:
      ({ setFieldValue }) =>
      (partnerUuid) => {
        setFieldValue('partnerUuid', partnerUuid)
      },
  }),
)
