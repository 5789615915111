import initialState from 'reducers/initialState'

import { ADD_UNDO, APPLY_UNDO, CANCEL_UNDO } from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function undoReducer(state = initialState.undo, action) {
  switch (action.type) {
    case ADD_UNDO: {
      const { callback } = action.data

      return {
        ...state,
        past: [
          ...state.past,
          {
            callback,
          },
        ],
      }
    }
    case APPLY_UNDO:
      // remove the last item in the past
      return {
        ...state,
        past: state.past.filter(
          (item, index) => index !== state.past.length - 1,
        ),
      }
    case CANCEL_UNDO:
      return {
        ...state,
        past: [],
      }
    default:
      return state
  }
}
