import { PaginationParams } from '../../hooks/types'
import {
  errorProgressState,
  requestProgressState,
  successProgressState,
} from '../progress'

import { messagingInitialState, MessagingState } from './initialState'
import { InboxMessage } from './interfaces'

// Actions
export enum MessagingActionTypes {
  API_GET_MESSAGES_REQUEST = 'API_GET_MESSAGES_REQUEST',
  API_GET_MESSAGES_SUCCESS = 'API_GET_MESSAGES_SUCCESS',
  API_GET_MESSAGES_ERROR = 'API_GET_MESSAGES_ERROR',
  // API_GET_MESSAGES_RESET = 'API_GET_MESSAGES_RESET',

  PAGINATION_SET_MESSAGES = 'PAGINATION_SET_MESSAGES',

  API_SEND_MESSAGES_REQUEST = 'API_SEND_MESSAGES_REQUEST',
  API_SEND_MESSAGES_SUCCESS = 'API_SEND_MESSAGES_SUCCESS',
  API_SEND_MESSAGES_ERROR = 'API_SEND_MESSAGES_ERROR',
  // API_SEND_MESSAGES_RESET = 'API_SEND_MESSAGES_RESET',
}

// Payloads
export interface GetMessageHistoryRequest {
  type: typeof MessagingActionTypes.API_GET_MESSAGES_REQUEST
}

export interface GetMessageHistorySuccess {
  type: typeof MessagingActionTypes.API_GET_MESSAGES_SUCCESS
  payload: {
    entities: {
      messages: Record<string, InboxMessage>
    }
    pagination: PaginationParams
  }
}

export interface GetMessageHistoryError {
  type: typeof MessagingActionTypes.API_GET_MESSAGES_ERROR
  payload: {
    error: Error
  }
}

export interface SendMessagesRequest {
  type: typeof MessagingActionTypes.API_SEND_MESSAGES_REQUEST
}

export interface SendMessagesSuccess {
  type: typeof MessagingActionTypes.API_SEND_MESSAGES_SUCCESS
  payload: {
    entities: {
      messages: Record<string, InboxMessage>
    }
  }
}

export interface SendMessagesError {
  type: typeof MessagingActionTypes.API_SEND_MESSAGES_ERROR
  payload: {
    error: Error
  }
}

export interface SetMessagingPagination {
  type: typeof MessagingActionTypes.PAGINATION_SET_MESSAGES
  payload: {
    pagination: PaginationParams
  }
}

export default function messagesReducer(
  state = messagingInitialState,
  action:
    | GetMessageHistoryRequest
    | GetMessageHistorySuccess
    | GetMessageHistoryError
    | SendMessagesRequest
    | SendMessagesSuccess
    | SendMessagesError
    | SetMessagingPagination,
): MessagingState {
  switch (action.type) {
    case MessagingActionTypes.API_GET_MESSAGES_REQUEST:
      return {
        ...state,
        progress: {
          ...state.progress,
          getMessages: requestProgressState,
        },
      }
    case MessagingActionTypes.API_GET_MESSAGES_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.payload.entities.messages,
        },
        pagination: {
          ...action.payload.pagination,
        },
        progress: {
          ...state.progress,
          getMessages: successProgressState,
        },
      }
    case MessagingActionTypes.API_GET_MESSAGES_ERROR:
      return {
        ...state,
        progress: {
          ...state.progress,
          getMessages: {
            ...errorProgressState,
            error: action.payload.error,
          },
        },
      }
    case MessagingActionTypes.API_SEND_MESSAGES_REQUEST:
      return {
        ...state,
        progress: {
          ...state.progress,
          sendMessages: requestProgressState,
        },
      }
    case MessagingActionTypes.API_SEND_MESSAGES_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.payload.entities.messages,
          ...state.entities,
        },
        progress: {
          ...state.progress,
          sendMessages: successProgressState,
        },
      }
    case MessagingActionTypes.API_SEND_MESSAGES_ERROR:
      return {
        ...state,
        progress: {
          ...state.progress,
          sendMessages: {
            ...errorProgressState,
            error: action.payload.error,
          },
        },
      }
    case MessagingActionTypes.PAGINATION_SET_MESSAGES:
      return {
        ...state,
        pagination: {
          ...action.payload.pagination,
        },
      }
    default:
      return state
  }
}
