import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'
import { getApiErrorMessage } from 'utils/api'

import { LIST_EMPTY_PARTNER_ORGANISATION } from '../partnerOrganisations/actions'

import { organisationListSchema } from './schema'

// Actions
export const API_GET_ORGANISATIONS_REQUEST = 'API_GET_ORGANISATIONS_REQUEST'
export const API_GET_ORGANISATIONS_SUCCESS = 'API_GET_ORGANISATIONS_SUCCESS'
export const API_GET_ORGANISATIONS_ERROR = 'API_GET_ORGANISATIONS_ERROR'
export const API_GET_ORGANISATION_REQUEST = 'API_GET_ORGANISATION_REQUEST'
export const API_GET_ORGANISATION_SUCCESS = 'API_GET_ORGANISATION_SUCCESS'
export const API_GET_ORGANISATION_ERROR = 'API_GET_ORGANISATION_ERROR'
export const API_RESEND_ORGANISATION_INVITE_REQUEST =
  'API_RESEND_ORGANISATION_INVITE_REQUEST'
export const API_RESEND_ORGANISATION_INVITE_SUCCESS =
  'API_RESEND_ORGANISATION_INVITE_SUCCESS'
export const API_RESEND_ORGANISATION_INVITE_ERROR =
  'API_RESEND_ORGANISATION_INVITE_ERROR'
export const API_DELETE_ORGANISATION_REQUEST = 'API_DELETE_ORGANISATION_REQUEST'
export const API_DELETE_ORGANISATION_SUCCESS = 'API_DELETE_ORGANISATION_SUCCESS'
export const API_DELETE_ORGANISATION_ERROR = 'API_DELETE_ORGANISATION_ERROR'
export const API_CREATE_ORGANISATION_REQUEST = 'API_CREATE_ORGANISATION_REQUEST'
export const API_CREATE_DEMO_ORGANISATION_REQUEST =
  'API_CREATE_DEMO_ORGANISATION_REQUEST'
export const API_CREATE_ORGANISATION_SUCCESS = 'API_CREATE_ORGANISATION_SUCCESS'
export const API_CREATE_DEMO_ORGANISATION_SUCCESS =
  'API_CREATE_DEMO_ORGANISATION_SUCCESS'
export const API_CREATE_ORGANISATION_ERROR = 'API_CREATE_ORGANISATION_ERROR'
export const API_CREATE_DEMO_ORGANISATION_ERROR =
  'API_CREATE_DEMO_ORGANISATION_ERROR'
export const API_UPDATE_ORGANISATION_REQUEST = 'API_UPDATE_ORGANISATION_REQUEST'
export const API_UPDATE_ORGANISATION_SUCCESS = 'API_UPDATE_ORGANISATION_SUCCESS'
export const API_UPDATE_ORGANISATION_ERROR = 'API_UPDATE_ORGANISATION_ERROR'
export const API_UPLOAD_ORGANISATION_LOGO_REQUEST =
  'API_UPLOAD_ORGANISATION_LOGO_REQUEST'
export const API_UPLOAD_ORGANISATION_LOGO_SUCCESS =
  'API_UPLOAD_ORGANISATION_LOGO_SUCCESS'
export const API_UPLOAD_ORGANISATION_LOGO_ERROR =
  'API_UPLOAD_ORGANISATION_LOGO_ERROR'
export const API_COMPLETE_ORG_SETUP_REQUEST = 'API_COMPLETE_ORG_SETUP_REQUEST'
export const API_COMPLETE_ORG_SETUP_SUCCESS = 'API_COMPLETE_ORG_SETUP_SUCCESS'
export const API_COMPLETE_ORG_SETUP_ERROR = 'API_COMPLETE_ORG_SETUP_ERROR'

export const CHANGE_ORG_DETAILS = 'CHANGE_ORG_DETAILS'
export const ADD_ORGANISATION = 'ADD_ORGANISATION'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function changeOrgDetails({ seatsOccupied, orgUuid }) {
  return {
    type: CHANGE_ORG_DETAILS,
    data: {
      orgUuid,
      seatsOccupied,
    },
  }
}

export function getOrganisations() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORGANISATIONS_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/organisation`)
      const data = normalize(response.data.data, organisationListSchema)

      dispatch({
        type: API_GET_ORGANISATIONS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_ORGANISATIONS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the organisations. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function getNonDemoOrganisations() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORGANISATIONS_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/organisation`)
      const data = normalize(
        response.data.data.filter((org) => org.demo == null),
        organisationListSchema,
      )

      dispatch({
        type: API_GET_ORGANISATIONS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_ORGANISATIONS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the organisations. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOrganisation(uuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORGANISATION_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/organisation/${uuid}`)
      const data = normalize(response.data.data, organisationListSchema)

      dispatch({
        type: API_GET_ORGANISATION_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_ORGANISATION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createOrganisation({
  name,
  orgOwnerEmail,
  incidentsContactName,
  incidentsContactEmail,
  seats,
  industry,
  country,
  partnerUuid,
}) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_ORGANISATION_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/organisation/add`, {
        name,
        incidentsContactName,
        incidentsContactEmail,
        seats,
        industry,
        country,
        partnerUuid,
        ...(orgOwnerEmail ? { orgOwnerEmail } : undefined), // Working around empty string
      })

      const data = normalize(response.data.data, organisationListSchema)

      dispatch({
        type: API_CREATE_ORGANISATION_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'The organisation has been successfully created!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
      if (partnerUuid) {
        dispatch({
          type: LIST_EMPTY_PARTNER_ORGANISATION,
          data: Object.values(data.entities.organisations)[0],
        })
      }
    } catch (error) {
      dispatch({ type: API_CREATE_ORGANISATION_ERROR, error })
      dispatch(
        addToast({
          message: `There was an error creating your organisation. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createDemoOrganisation({ name, email }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_DEMO_ORGANISATION_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/demo`, {
        name,
        email,
      })

      const data = normalize(response.data.data, organisationListSchema)

      dispatch({
        type: API_CREATE_DEMO_ORGANISATION_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'The demo organisation has been successfully created!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_CREATE_DEMO_ORGANISATION_ERROR, error })
      dispatch(
        addToast({
          message: `There was an error creating your demo organisation. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resendOrganisationInvitation({ email, uuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESEND_ORGANISATION_INVITE_REQUEST })

    try {
      await axios.put(`${SAUCE_API_URL}/organisation/${uuid}/resend`, {
        orgOwnerEmail: email,
      })

      dispatch({ type: API_RESEND_ORGANISATION_INVITE_SUCCESS })
      dispatch(
        addToast({
          message: 'The organisation has been successfully reinvited!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_RESEND_ORGANISATION_INVITE_ERROR, error })
      dispatch(
        addToast({
          message: `There was an error resending the invitation. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteOrganisation(organisationUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_DELETE_ORGANISATION_REQUEST })
    const state = getState()
    const organisation = state.organisations.entities[organisationUuid]

    try {
      await axios.delete(`${SAUCE_API_URL}/organisation/${organisationUuid}`)

      dispatch({
        type: API_DELETE_ORGANISATION_SUCCESS,
        data: {
          uuid: organisationUuid,
        },
      })

      dispatch(
        addToast({
          message: `'${organisation.name}' organisation was successfully deleted.`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_ORGANISATION_ERROR, error })
      dispatch(
        addToast({
          message: `'${organisation.name}' organisation was unable to be deleted.`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateOrganisation({ uuid, name, incidentsContactUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_ORGANISATION_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${uuid}`,
        {
          name,
          incidentsContactUuid,
        },
      )

      const data = normalize(response.data.data, organisationListSchema)

      dispatch({ type: API_UPDATE_ORGANISATION_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The organisation has been successfully updated!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
      return true
    } catch (error) {
      dispatch({ type: API_UPDATE_ORGANISATION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was a problem updating the organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateAutoreply({ uuid, autoReply, autoReplyText }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_ORGANISATION_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${uuid}`,
        {
          autoReply,
          autoReplyText,
        },
      )

      const data = normalize(response.data.data, organisationListSchema)

      dispatch({ type: API_UPDATE_ORGANISATION_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The organisation has been successfully updated!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
      return true
    } catch (error) {
      dispatch({ type: API_UPDATE_ORGANISATION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was a problem updating the organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function uploadOrganisationLogo({ uuid, file, onUploadProgress }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPLOAD_ORGANISATION_LOGO_REQUEST })

    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${uuid}/logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        },
      )

      const data = normalize(response.data.data, organisationListSchema)

      dispatch({
        type: API_UPLOAD_ORGANISATION_LOGO_SUCCESS,
        data,
      })

      return true
    } catch (error) {
      dispatch({ type: API_UPLOAD_ORGANISATION_LOGO_ERROR, error })
      dispatch(
        addToast({
          message: 'There was a problem uploading the logo. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function completeOrganisationSetup(organisation) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_COMPLETE_ORG_SETUP_REQUEST })

    try {
      await axios.post(
        `${SAUCE_API_URL}/organisation/${organisation.uuid}/setup-complete`,
      )
      dispatch({ type: API_COMPLETE_ORG_SETUP_SUCCESS })
      return true
    } catch (error) {
      dispatch({ type: API_COMPLETE_ORG_SETUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was a problem uploading the logo. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addOrganisation(organisation) {
  return {
    type: ADD_ORGANISATION,
    data: {
      organisation,
    },
  }
}

export const organisationActions = {
  changeOrgDetails,
  getOrganisations,
  getNonDemoOrganisations,
  getOrganisation,
  createOrganisation,
  createDemoOrganisation,
  resendOrganisationInvitation,
  deleteOrganisation,
  updateOrganisation,
  updateAutoreply,
  uploadOrganisationLogo,
  completeOrganisationSetup,
  addOrganisation,
}
