import TextField from '@weareroam/cake-ui-v1/TextField'
import ChipInput from 'components/molecules/ChipInput'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef } from 'react'
import AutosuggestComponent from 'react-autosuggest'
import { toClass } from 'recompose'
import styled from 'styled-components'

// Convert the TextField to class since it is a SFC (no ref) but Autosuggest requires a ref
const TextFieldWithClass = toClass(TextField)

export const StyledAutosuggest = styled.div`
  position: relative;
`

export const StyledDropdown = styled.div`
  position: fixed;
  left: auto;
  top: auto;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: inherit;
  z-index: 5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  max-height: 250px;
  overflow-y: auto;

  ul {
    list-style-type: none;

    li:hover > div {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
`

export const StyledSuggestion = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.md}px;

  background-color: ${({ isActive }) =>
    isActive ? 'rgba(0, 0, 0, 0.1)' : 'white'};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Autosuggest(props) {
  const {
    className,
    filteredSuggestions,
    updateFilteredSuggestions,
    value,
    error,
    helperText,
    onChange,
    onBlur,
    placeholder,
    name,
    clearSuggestions,
    variant,
    chips,
    onDeleteChip,
    onAddChip,
    noneSelectedHelperText,
    hideinput,
    shouldAlwaysRenderSuggestions,
    onMounted,
    onInputLoseFocus,
  } = props

  const fieldError = useMemo(
    () =>
      error
        ? helperText
        : filteredSuggestions.length === 0 && value.length > 0
        ? noneSelectedHelperText
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, value, helperText],
  )
  const inputReference = useRef(null)

  useEffect(() => {
    if (!hideinput && onMounted && inputReference) {
      inputReference.current.input.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideinput])

  return (
    <StyledAutosuggest className={className}>
      <AutosuggestComponent
        suggestions={filteredSuggestions}
        onSuggestionsFetchRequested={({ value, reason }) =>
          updateFilteredSuggestions(value, reason)
        }
        onSuggestionsClearRequested={() => clearSuggestions()}
        getSuggestionValue={(suggestion) => suggestion.label}
        inputProps={{
          value,
          onChange,
          onBlur: onInputLoseFocus,
          placeholder,
        }}
        renderInputComponent={({ ref, onChange: innerOnChange, ...other }) => {
          switch (variant) {
            case 'chip':
              return (
                <div {...other}>
                  <ChipInput
                    {...other}
                    inputRef={ref}
                    helperText={helperText}
                    error={error}
                    onBlur={onBlur}
                    name={name}
                    value={chips}
                    inputValue={value}
                    onUpdateInput={(e) => {
                      innerOnChange(e)
                      onChange(e)
                    }}
                    onAdd={onAddChip}
                    onDelete={onDeleteChip}
                    hideinput={hideinput}
                  />
                </div>
              )
            case 'text':
            default:
              return (
                <TextFieldWithClass
                  {...other}
                  inputRef={ref}
                  fullWidth
                  type="text"
                  helperText={fieldError || null}
                  error={!!fieldError}
                  name={name}
                  onChange={innerOnChange}
                />
              )
          }
        }}
        // evaluating the result of the second func param (reason === '..') doesn't
        // actually effect the suggestion list rendering
        // if (reason === 'input-focused') return true
        // if (reason === 'input-blurred') return false
        shouldRenderSuggestions={() => true}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <StyledDropdown {...containerProps}>{children}</StyledDropdown>
        )}
        ref={inputReference}
        alwaysRenderSuggestions={!hideinput && shouldAlwaysRenderSuggestions}
        renderSuggestion={(suggestion) => {
          const isActive = suggestion.label === value
          return (
            <StyledSuggestion isActive={isActive}>
              {suggestion.label}
            </StyledSuggestion>
          )
        }}
      />
    </StyledAutosuggest>
  )
}

Autosuggest.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  chips: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  clearSuggestions: PropTypes.func,
  updateFilteredSuggestions: PropTypes.func,
  filteredSuggestions: PropTypes.array,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'chip']),
  onDeleteChip: PropTypes.func,
  onAddChip: PropTypes.func,
  noneSelectedHelperText: PropTypes.string,
  hideinput: PropTypes.bool,
  shouldAlwaysRenderSuggestions: PropTypes.bool,
  onMounted: PropTypes.bool,
  onInputLoseFocus: PropTypes.func,
}

Autosuggest.defaultProps = {
  variant: 'text',
  filteredSuggestions: [],
  noneSelectedHelperText: 'Please select a value',
}

export default Autosuggest
