import { JournalThemingOptions } from 'constants/JournalThemingOptions'

import { withFormik } from 'formik'
import { compose, withHandlers } from 'recompose'
import { toTitleCase, toScreamingSnakeCase } from 'utils/formatting'
import * as Yup from 'yup'

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialEntry, redFlagged, yellowFlagged, tags }) => ({
      entry: initialEntry || '',
      redFlagged,
      yellowFlagged,
      //TODO: update once backend is passing back multiple cats
      tags,
      textInput: '',
      categoryOptions: JournalThemingOptions,
    }),
    validationSchema: Yup.object({
      entry: Yup.string().required('Please enter your journal entry'),
      redFlagged: Yup.boolean().nullable(),
      yellowFlagged: Yup.boolean().nullable(),
      tags: Yup.array()
        .when('yellowFlagged', {
          is: true,
          then: () =>
            Yup.array()
              .min(
                1,
                'If an entry is yellow flagged, it must have at least one theme selected',
              )
              .max(
                3,
                'You can have at most 3 themes associated with a journal entry',
              ),
        })
        .max(3, 'You can have at most 3 themes associated with a journal entry')
        .nullable(),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      // pass values to our onSubmit prop
      if (onSubmit) {
        onSubmit(values)
      }
    },
  }),
  withHandlers({
    onAddChip:
      ({ setFieldValue, values }) =>
      (chip) => {
        // check if chip is already in the list
        const alreadyExists = values.tags.some(
          (category) =>
            category.tag.trim().toLowerCase() === chip.trim().toLowerCase(),
        )

        // Don't add to the list if it already exists
        if (alreadyExists) {
          return
        }

        const isAnOption = values.categoryOptions.some(
          (category) =>
            category.label.trim().toLowerCase() === chip.trim().toLowerCase(),
        )
        // chip needs to be an option
        if (!isAnOption) {
          return
        }

        const tags = [...values.tags, { tag: toScreamingSnakeCase(chip) }]

        setFieldValue('tags', tags)
        setFieldValue('textInput', '')
      },
    onDeleteChip:
      ({ values, setFieldValue }) =>
      (chip) => {
        setFieldValue(
          'tags',
          values.tags.filter(
            ({ tag }) =>
              toTitleCase(tag).trim().toLowerCase() !==
              toTitleCase(chip).trim().toLowerCase(),
          ),
        )
      },
  }),
)
