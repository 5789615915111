import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FilterLabel({ children, className }) {
  return (
    <StyledLabel variant="body2" className={className}>
      {children}
    </StyledLabel>
  )
}

FilterLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default FilterLabel
