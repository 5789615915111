import Typography from '@weareroam/cake-ui-v1/Typography'
import Box from 'components/molecules/Box'
import { distanceInWords } from 'date-fns'
import { DistressedUserLine } from 'models/DistressedUserLine'
import { Notification } from 'modules/notifications/schema'
import React from 'react'
import styled from 'styled-components'

const Data = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  border: 0;
`

const DataTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const DataBoxContent = styled.div`
  display: flex;
`
const DataBox = styled.div`
  flex-basis: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg}px;
`
const SectionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
const SectionValue = styled(Typography)``
const FingerPrintLabel = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export function DistressedUserInfo({
  data,
  messages,
}: {
  data: DistressedUserLine
  messages: Notification[]
}): React.ReactElement {
  const messagesOpened = messages.filter((m) => m.acknowledged).length
  return (
    <Data
      headerContent={
        <DataTitle>
          <Typography variant="h4">
            High-level stats for&nbsp;
            <FingerPrintLabel>
              {data.userFingerprint.slice(0, 3)}-
              {data.userFingerprint.slice(3, 7)}
            </FingerPrintLabel>
          </Typography>
          <Typography variant="h4">
            {data.group.organisation.name} / {data.group.name}
          </Typography>
        </DataTitle>
      }>
      <DataBoxContent>
        <DataBox>
          <SectionLabel>Avg. score</SectionLabel>
          <SectionValue variant="body1">
            {Math.round(data.averageScore)}
          </SectionValue>
        </DataBox>

        <DataBox>
          <SectionLabel>Distressed surveys</SectionLabel>
          <SectionValue variant="body1">
            {data.countOfDistressedSurveys}
          </SectionValue>
        </DataBox>

        <DataBox>
          <SectionLabel>Most recent distressed survey</SectionLabel>
          <SectionValue variant="body1">
            {distanceInWords(new Date(), data.mostRecentDistressedSurveyDate, {
              addSuffix: true,
            })}
          </SectionValue>
        </DataBox>

        <DataBox>
          <SectionLabel>Messages Opened&nbsp;/&nbsp;Received</SectionLabel>
          <SectionValue variant="body1">
            {messages.length ? `${messagesOpened} / ${messages.length}` : '-'}
          </SectionValue>
        </DataBox>
      </DataBoxContent>
    </Data>
  )
}
