/* eslint-disable @typescript-eslint/naming-convention */
export const API_YEAR_MONTH_FORMAT = 'YYYY-MM'
export const API_ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
export const MONTH_FILTER_FORMAT = 'MMMM YYYY'
export const JOURNAL_TASK_DATE = 'DD/MM/YY'
export const JOURNAL_TASK_TIME = 'h:mm:ss Z'
/**
 * @deprecated Use HumanReadableDatetime.
 */
export const JOURNAL_ENTRY_DATE_TIME = 'Do MMMM YYYY, h:mma'
export const JOURNAL_ENTRY_TIMEZONE = 'Z'
export const MONTH_FORMAT = 'MMMM'
/**
 * @deprecated Use DMYFull.
 */
export const MESSAGE_DATE = 'DD / MM / YY'
export const MESSAGE_TIME = 'h:mm:ss Z'
export const LAUNCH_DATE = new Date(2019, 0)
/* eslint-disable @typescript-eslint/naming-convention */

export const DMYFull = 'DD/MM/YYYY'
export const HumanReadableDatetime = `Do MMMM YYYY [at] h:mma`
export const HumanReadableDate = `Do MMMM YYYY`

export const DateInputValue = 'YYYY-MM-DD'
export const TimeInputValue = 'hh:mm'
