import { API_ISO_DATE_FORMAT } from 'constants/date'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { format, subDays, addDays } from 'date-fns'
import { DistressedUserLine } from 'models/DistressedUserLine'
import { useState, useEffect, useCallback, useReducer } from 'react'

export enum QueryFilters {
  TO_REVIEW = 'toReview',
}

type QueryFilterObject = {
  [key in QueryFilters]?: boolean
}

const getQueryParameters = ({
  orgUuids = [],
  filters = {},
}: {
  orgUuids?: string[]
  filters?: QueryFilterObject
}) => {
  const startDate = format(subDays(new Date(), 21), API_ISO_DATE_FORMAT)
  const endDate = format(addDays(new Date(), 1), API_ISO_DATE_FORMAT)
  return {
    startDate,
    endDate,
    ...filters,
    ...(orgUuids.length ? { orgUuids: JSON.stringify(orgUuids) } : {}),
  }
}

function filterReducer(
  state: QueryFilterObject,
  action: { type: string | null },
) {
  switch (action.type) {
    case 'toReview':
      return { ...state, toReview: true }
    default:
      return {}
  }
}

export const useDistressedUsers = (
  orgUuids?: string[],
): {
  data: DistressedUserLine[]
  isLoading: boolean
  hasError: boolean
  handleChangeFilter: (e: QueryFilters | null) => void
  refetch: () => Promise<void>
  filters: QueryFilterObject
} => {
  const [data, setData] = useState<DistressedUserLine[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setError] = useState<boolean>(false)
  const [filters, dispatch] = useReducer(filterReducer, {})

  const handleChangeFilter = useCallback(
    (e: QueryFilters | null) => {
      dispatch({ type: e })
    },
    [dispatch],
  )

  const fetchReflectionResponses = async () => {
    try {
      const response = await axios.get(`${SAUCE_API_URL}/distressed-users/`, {
        params: getQueryParameters({ orgUuids, filters }),
      })

      const data = response.data.data
      setData(data)
    } catch (error: unknown) {
      setError(true)
    }
  }

  const refetch = useCallback(fetchReflectionResponses, [orgUuids, filters])

  useEffect(() => {
    const fetchReflectionResponses = async () => {
      setIsLoading(true)
      setError(false)

      try {
        const response = await axios.get(`${SAUCE_API_URL}/distressed-users/`, {
          params: getQueryParameters({ orgUuids, filters }),
        })

        const data = response.data.data
        setData(data)
      } catch (error: unknown) {
        setError(true)
      }
      setIsLoading(false)
    }
    fetchReflectionResponses()
  }, [orgUuids, filters])

  return {
    data,
    isLoading,
    hasError,
    filters,
    handleChangeFilter,
    refetch,
  }
}
