import { PARTNER_DASHBOARD_SETTINGS_DETAILS } from 'constants/routes'

import Button from '@weareroam/cake-ui-v1/Button'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import EmptyState from 'components/molecules/EmptyState'
import EntityCard from 'components/molecules/EntityCard'
import { Modal } from 'components/molecules/Modal'
import DeletePartnerForm from 'components/organisms/DeletePartnerForm'
import NewPartnerForm from 'components/organisms/NewPartnerForm'
import useEntitiesSearch from 'hooks/useEntitiesSearch'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { replaceRouteParams } from 'utils/routes'

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledSearch = styled(SearchField)`
  width: 400px;
`

export const StyledDeleteModal = styled(Modal)`
  width: 450px;
`

export const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* Negative margin for items on the edges */
  margin: -${({ theme }) => theme.spacing.md}px;
  max-width: calc(100% + ${({ theme }) => theme.spacing.md * 2}px);
`

export const Partner = styled(EntityCard)`
  width: 230px;
  margin: ${({ theme }) => theme.spacing.md}px;
`

export const StyledHeaderButtons = styled.div`
  display: inline-block;
  float: right;
`

export const NewPartnerButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Partners({
  isNewPartnerModalOpen,
  setIsNewPartnerModalOpen,
  onCreateNewPartner,
  setIsDeletePartnerModalOpen,
  isDeletePartnerModalOpen,
  onDeletePartner,
  partners,
  getPartnersProgress,
  modalPartnerId,
  setModalPartnerId,
  partnerEntities,
}) {
  const { searchFilter, handleChangeSearchFilter, filteredEntities } =
    useEntitiesSearch(partners, 'title')

  return (
    <ChnnlDashboardLayout>
      {/* New partner form modal */}
      <Modal
        open={isNewPartnerModalOpen}
        onClose={() => setIsNewPartnerModalOpen(false)}
        hasCloseButton>
        <NewPartnerForm
          onSubmit={onCreateNewPartner}
          onClose={() => {
            setIsNewPartnerModalOpen(false)
          }}
        />
      </Modal>

      {/* Delete partner modal */}
      <StyledDeleteModal
        open={isDeletePartnerModalOpen}
        onClose={() => setIsDeletePartnerModalOpen(false)}
        hasCloseButton>
        <DeletePartnerForm
          partner={partnerEntities[modalPartnerId]}
          onSubmit={onDeletePartner}
          onCancel={() => setIsDeletePartnerModalOpen(false)}
        />
      </StyledDeleteModal>

      <div>
        <ContentContainer>
          <StyledHeader>
            <StyledSearch
              placeholder="Search"
              value={searchFilter}
              onChange={handleChangeSearchFilter}
            />
            <StyledHeaderButtons>
              <NewPartnerButton
                type="button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setIsNewPartnerModalOpen(true)}
                id="new-partner-button">
                New partner
              </NewPartnerButton>
            </StyledHeaderButtons>
          </StyledHeader>
        </ContentContainer>
        <ContentContainer>
          {getPartnersProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : filteredEntities.length ? (
            <StyledItems>
              {filteredEntities.map((partner) => {
                const deleteAction = {
                  key: 'delete',
                  label: 'Delete',
                  onClick: () => {
                    setIsDeletePartnerModalOpen(true)
                    setModalPartnerId(partner.uuid)
                  },
                }

                // actions for partners that are not pending
                const regularActions = [
                  {
                    ...deleteAction,
                  },
                ]

                return (
                  <Partner
                    dataQa={partner.title}
                    key={partner.uuid}
                    to={replaceRouteParams(PARTNER_DASHBOARD_SETTINGS_DETAILS, {
                      partnerUuid: partner.uuid,
                    })}
                    title={partner.title}
                    logo={partner.logo}
                    subtitle={`${partner.linkedOrgCount} active organisations`}
                    footerText={`${partner.partnerAdminCount} partner admins`}
                    actions={regularActions}
                  />
                )
              })}
            </StyledItems>
          ) : (
            <EmptyState
              title="Nothing to manage here yet."
              text="Get started by adding a new partner."
            />
          )}
        </ContentContainer>
      </div>
    </ChnnlDashboardLayout>
  )
}

Partners.propTypes = {
  isNewPartnerModalOpen: PropTypes.bool,
  setIsNewPartnerModalOpen: PropTypes.func,
  onCreateNewPartner: PropTypes.func,
  isDeletePartnerModalOpen: PropTypes.bool,
  setIsDeletePartnerModalOpen: PropTypes.func,
  onDeletePartner: PropTypes.func,
  partners: PropTypes.arrayOf(PropTypes.object),
  getPartnersProgress: PropTypes.object,
  modalPartnerId: PropTypes.string,
  setModalPartnerId: PropTypes.func,
  partnerEntities: PropTypes.object,
}

Partners.defaultProps = {
  getPartnersProgress: {},
  parnters: [],
  partnerEntities: {},
}

export default Partners
