import { withFormik } from 'formik'
import * as Yup from 'yup'

import { FormProps, FormValues, InnerFormProps } from './index'

export const enhance = withFormik<FormProps & InnerFormProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ initialReply }) => ({
    reply: initialReply || '',
  }),
  validationSchema: Yup.object({
    reply: Yup.string()
      .required('Please enter your reply')
      .max(700, 'Please keep your message within the character limit'),
  }),
  handleSubmit: async (values, { props: { onSubmit }, setSubmitting }) => {
    setSubmitting(true)
    await onSubmit?.(values)
    setSubmitting(false)
  },
})
