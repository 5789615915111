import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DragIndicator(props) {
  return (
    <svg width="12px" height="36px" viewBox="0 0 12 36" {...props}>
      <g
        id="Organisation-App"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="_Screens/App/Organisation/Organisation"
          transform="translate(-229.000000, -930.000000)"
          fill="#EBEBEB"
          fillRule="nonzero">
          <g id="Group-7" transform="translate(214.000000, 916.000000)">
            <g id="Group-Copy-6" transform="translate(15.000000, 14.000000)">
              <g id="Group-Copy">
                <g id="Group">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-5"
                    x="0"
                    y="7.846875"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-3"
                    x="7.38461538"
                    y="0"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-6"
                    x="7.38461538"
                    y="7.846875"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-8"
                    x="0"
                    y="15.69375"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-7"
                    x="7.38461538"
                    y="15.69375"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-10"
                    x="0"
                    y="23.540625"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-9"
                    x="7.38461538"
                    y="23.540625"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-10"
                    x="0"
                    y="31.640625"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                  <rect
                    id="Rectangle-Copy-9"
                    x="7.38461538"
                    y="31.640625"
                    width="4.61538462"
                    height="4.359375"
                    rx="0.8748"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DragIndicator
