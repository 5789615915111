import { TOAST_TYPE_ERROR } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import idx from 'idx'
import { addToast } from 'modules/toasts/actions'

export const API_GET_ORG_ACTIVITY_REQUEST = 'API_GET_ORG_ACTIVITY_REQUEST'
export const API_GET_ORG_ACTIVITY_SUCCESS = 'API_GET_ORG_ACTIVITY_SUCCESS'
export const API_GET_ORG_ACTIVITY_ERROR = 'API_GET_ORG_ACTIVITY_ERROR'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getActivityData({ orgs = [], month }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORG_ACTIVITY_REQUEST })
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/activity`,
        {
          params: {
            orgs,
            month,
          },
        },
      )

      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_GET_ORG_ACTIVITY_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({
        type: API_GET_ORG_ACTIVITY_ERROR,
      })
      dispatch(
        addToast({
          type: TOAST_TYPE_ERROR,
          message:
            'There was an error fetching activity data. Please try again',
        }),
      )
    }
  }
}
