import { FormikProps, withFormik } from 'formik'
import { compose } from 'recompose'
import * as Yup from 'yup'

import { FormProps, FormValues } from './index'

export default compose<FormProps & FormikProps<FormValues>, FormProps>(
  withFormik<FormProps & FormikProps<FormValues>, FormProps>({
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string()
        .min(2, 'Must be at least 2 characters long')
        .max(50, 'Must be less than 50 characters long')
        .required('Please enter the partner name'),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      onSubmit(values)
      setSubmitting(false)
    },
  }),
)
