import { createSelector } from 'reselect'

export const getRedFlagChatsList = createSelector(
  (state) => state.chatbot.redFlagChats,
  (chatbot) => Object.values(chatbot),
)

export const getChatHistoryList = createSelector(
  (state) => state.chatbot.chatHistory,
  (chatbot) => Object.values(chatbot),
)
