import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import { SAUCE_API_URL } from 'config'
import { subMinutes, format } from 'date-fns'
import { addToast } from 'modules/toasts/actions'
import { getSessionToken } from 'utils/authentication'

import { API_ISO_DATE_FORMAT } from '../../constants/date'
import { getCurrentDate } from '../../utils/date'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function exportJournalData({ orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    try {
      const date = format(subMinutes(getCurrentDate(), 1), API_ISO_DATE_FORMAT)
      window.location.assign(
        `${SAUCE_API_URL}/organisation/${orgUuid}/journal/export/chnnl-journal-export-${date}?token=${getSessionToken()}`,
      )

      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch(
        addToast({
          message:
            'There was an error exporting journal data. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function exportIncidentData({ orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    try {
      const date = format(subMinutes(getCurrentDate(), 1), API_ISO_DATE_FORMAT)
      window.location.assign(
        `${SAUCE_API_URL}/organisation/${orgUuid}/shared-incident/export/chnnl-incident-export-${date}?token=${getSessionToken()}`,
      )

      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch(
        addToast({
          message:
            'There was an error exporting incident data. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function exportAllOrgData() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    try {
      const date = format(subMinutes(getCurrentDate(), 1), API_ISO_DATE_FORMAT)

      window.location.assign(
        `${SAUCE_API_URL}/organisation/export/chnnl-reports-${date}?token=${getSessionToken()}`,
      )

      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch(
        addToast({
          message: 'There was an error exporting your data. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function exportOrgData({ orgUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    try {
      const date = format(subMinutes(getCurrentDate(), 1), API_ISO_DATE_FORMAT)
      window.location.assign(
        `${SAUCE_API_URL}/organisation/${orgUuid}/export/chnnl-reports-${date}?token=${getSessionToken()}`,
      )
      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch(
        addToast({
          message:
            'There was an error exporting your org data. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
