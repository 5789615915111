/* eslint-disable @typescript-eslint/naming-convention */
export const AUTH_CALLBACK = '/authenticated'
export const NO_ACCOUNT = '/no-account'

// Old auth that used our database
export const LOGIN = '/login'
export const NEW_PASSWORD = '/new-password'
export const SIGN_UP = '/sign-up'
export const SIGN_UP_ORGANISATION = '/sign-up/organisation/:orgUuid'
export const RESET_PASSWORD = '/reset-password'
export const RESET_PASSWORD_SUCCESS = '/reset-password/success'
export const SIGN_UP_LINK_EXPIRED = '/sign-up-link-expired'
export const SIGN_ME_UP = '/sign-me-up'
export const SIGN_UP_SUCCESS = '/sign-up-success'

export const MOBILE_FRIENDLY_ROUTES = [
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  NEW_PASSWORD,
  SIGN_UP_LINK_EXPIRED,
  SIGN_ME_UP,
  SIGN_UP_SUCCESS,
]

export const CHNNL_DASHBOARD = '/dashboard/chnnl'
export const CHNNL_DASHBOARD_ORGANISATIONS = '/dashboard/chnnl/organisations'
export const CHNNL_DASHBOARD_TIPS = '/dashboard/chnnl/tips'
export const CHNNL_DASHBOARD_JOURNALS = '/dashboard/chnnl/journals'
export const CHNNL_DASHBOARD_ADMIN_MESSAGES = '/dashboard/chnnl/messages'
export const CHNNL_DASHBOARD_DISTRESSED_USERS =
  '/dashboard/chnnl/distressed-users'
export const CHNNL_DASHBOARD_SPEAKUP = '/dashboard/chnnl/speakup'
export const CHNNL_DASHBOARD_PARTNERS = '/dashboard/chnnl/partners'
export const CHNNL_DASHBOARD_CHATBOT = '/dashboard/chnnl/chatbot'
export const CHNNL_DASHBOARD_ACTIVITY = '/dashboard/chnnl/activity'
export const CHNNL_DASHBOARD_SETTINGS = '/dashboard/chnnl/settings'
export const CHNNL_DASHBOARD_SETTINGS_PROFILE =
  '/dashboard/chnnl/settings/profile'
export const CHNNL_DASHBOARD_SETTINGS_PASSWORD =
  '/dashboard/chnnl/settings/password'
export const CHNNL_DASHBOARD_SETTINGS_NOTIFICATIONS =
  '/dashboard/chnnl/settings/notifications'

export const ORG_DASHBOARD = '/dashboard/organisation/:orgUuid'
export const ORG_DASHBOARD_OVERVIEW =
  '/dashboard/organisation/:orgUuid/overview'
export const ORG_DASHBOARD_GROUPS = '/dashboard/organisation/:orgUuid/groups'
export const ORG_DASHBOARD_JOURNALS =
  '/dashboard/organisation/:orgUuid/journals'
export const ORG_DASHBOARD_SPEAKUP = '/dashboard/organisation/:orgUuid/speakup'

export const ORG_DASHBOARD_APP = '/dashboard/organisation/:orgUuid/app'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ORG_DASHBOARD_APP_BOOSTERS =
  '/dashboard/organisation/:orgUuid/app/wellbeing-resources'
export const ORG_DASHBOARD_APP_PATHWAYS =
  '/dashboard/organisation/:orgUuid/app/pathways'
export const ORG_DASHBOARD_APP_ORGANISATION =
  '/dashboard/organisation/:orgUuid/app/organisation'
export const ORG_DASHBOARD_APP_CONTACTS =
  '/dashboard/organisation/:orgUuid/app/contacts'
export const ORG_DASHBOARD_APP_SURVEY =
  '/dashboard/organisation/:orgUuid/app/survey'
export const ORG_DASHBOARD_APP_SUPPORT =
  '/dashboard/organisation/:orgUuid/app/support'
export const ORG_DASHBOARD_APP_REFLECTIONS =
  '/dashboard/organisation/:orgUuid/app/reflections'

export const ORG_DASHBOARD_SETTINGS =
  '/dashboard/organisation/:orgUuid/settings'
export const ORG_DASHBOARD_SETTINGS_ADMINS =
  '/dashboard/organisation/:orgUuid/settings/admins'
export const ORG_DASHBOARD_SETTINGS_GENERAL =
  '/dashboard/organisation/:orgUuid/settings/general'
export const ORG_DASHBOARD_SETTINGS_PROFILE =
  '/dashboard/organisation/:orgUuid/settings/profile'
export const ORG_DASHBOARD_SETTINGS_NOTIFICATIONS =
  '/dashboard/organisation/:orgUuid/settings/notifications'
export const ORG_DASHBOARD_SETTINGS_ENTITLEMENTS =
  '/dashboard/organisation/:orgUuid/settings/entitlements'

export const ORG_DASHBOARD_MESSAGING =
  '/dashboard/organisation/:orgUuid/messaging'
export const ORG_DASHBOARD_MESSAGING_NETWORK =
  '/dashboard/organisation/:orgUuid/messaging/network'
export const ORG_DASHBOARD_MESSAGING_NOTICEBOARD =
  '/dashboard/organisation/:orgUuid/messaging/noticeboard'
export const ORG_DASHBOARD_MESSAGING_ORGANISATION =
  '/dashboard/organisation/:orgUuid/messaging/organisation'

export const GROUP_DASHBOARD =
  '/dashboard/organisation/:orgUuid/groups/:groupUuid'
export const GROUP_DASHBOARD_WELLBEING =
  '/dashboard/organisation/:orgUuid/groups/:groupUuid/wellbeing'
export const GROUP_DASHBOARD_BOOSTERS =
  '/dashboard/organisation/:orgUuid/groups/:groupUuid/wellbeing-resources'
export const GROUP_DASHBOARD_MESSAGING =
  '/dashboard/organisation/:orgUuid/groups/:groupUuid/messaging'
export const GROUP_DASHBOARD_MEMBERS =
  '/dashboard/organisation/:orgUuid/groups/:groupUuid/members'
export const GROUP_DASHBOARD_SETTINGS =
  '/dashboard/organisation/:orgUuid/groups/:groupUuid/settings'

export const APP_TOKEN_DEEPLINK = '/join/:token'

export const PARTNER_DASHBOARD_ADMINS = '/dashboard/partner/:partnerUuid/admins'
export const PARTNER_DASHBOARD_SETTINGS_DETAILS =
  '/dashboard/partner/:partnerUuid/details'
export const PARTNER_DASHBOARD_ORGANISATIONS =
  '/dashboard/partner/:partnerUuid/organisations'
export const PARTNER_ADMIN_DASHBOARD_ORGANISATIONS =
  '/dashboard/partner/:partnerUuid/organisations/admin'
