import Typography from '@weareroam/cake-ui-v1/Typography'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { MESSAGE_DATE, MESSAGE_TIME } from '../../../constants/date'

export const StyledMessage = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 8px;
  justify-content: ${(props) =>
    props.sender === 'user' ? 'flex-end' : 'flex-start'};
  background-color: ${(props) => (props.redFlagged ? '#FFE0E0' : '#ffffff')};

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

export const MessageEntry = styled.div`
  border-radius: 20px;
  padding: 8px 14px 8px 14px;
  color: ${(props) => (props.sender === 'user' ? '#ffffff' : '#000000')};
  background-color: ${(props) =>
    props.sender === 'user' ? '#2985fc' : '#f1f3f8'};
  text-align: ${(props) => (props.sender === 'user' ? 'right' : 'left')};
  margin-left: ${(props) => (props.sender === 'user' ? '30px' : '0')};
  margin-right: ${(props) => (props.sender === 'user' ? '0' : '30px')};
`

export const MessageDate = styled(Typography)`
  color: #000000;
  font-size: ${({ theme }) => theme.typography.pxToRem(8)};
`

export const EntryText = styled(Typography)`
  && {
    display: inline;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChatHistory({ messages }) {
  return messages.map((message, index) => {
    if (!message.message) {
      return null
    }
    return (
      <StyledMessage
        key={index}
        sender={message.sender}
        redFlagged={message.redFlagged}>
        <MessageEntry sender={message.sender}>
          <EntryText variant="body1" component="span">
            {message.message}
          </EntryText>
          <MessageDate variant="body2">
            <strong>{format(message.createdAt, MESSAGE_DATE)}</strong>{' '}
            {format(message.createdAt, MESSAGE_TIME)}
          </MessageDate>
        </MessageEntry>
      </StyledMessage>
    )
  })
}

ChatHistory.propTypes = {
  messages: PropTypes.array,
}

export default ChatHistory
