import {
  parsePhoneNumber,
  getCountryCallingCode,
  CountryCode,
} from 'libphonenumber-js/mobile'

export const truncateWithElipsis = (str: string, n: number): string =>
  str.length > n ? str.substring(0, n - 1) + '\u2026' : str

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFormattedMobileNumber = (
  phoneNumber: string,
  region: CountryCode,
) => {
  let number = phoneNumber
  const code = getCountryCallingCode(region).toString()
  if (phoneNumber.startsWith(code, 0)) {
    number = phoneNumber.substr(code.length, phoneNumber.length)
  }

  try {
    const parsedNumber = parsePhoneNumber(number, region)
    if (parsedNumber) {
      const formatedNumber = parsedNumber
        .formatInternational()
        .replace(/\s+/g, '')
      if (formatedNumber) {
        return formatedNumber
      }
    }
    return number
  } catch (error) {
    return phoneNumber
  }
}

export const toTitleCase = (str = ''): string =>
  str
    .toLowerCase()
    .split(' ')
    .filter((word) => word.length)
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ')
    .replaceAll('_', ' ')

export const toScreamingSnakeCase = (str = ''): string =>
  str.toUpperCase().split(' ').join('_')
