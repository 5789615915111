import initialState from 'reducers/initialState'

import {
  API_GET_ENTITLEMENTS_SUCCESS,
  API_UPDATE_ENTITLEMENT_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function entitlementsReducer(
  state = initialState.entitlements,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_GET_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        administration: {
          ...state.administration,
          [action.data.orgUuid]: {
            ...action.data.entitlements,
          },
        },
      }
    case API_UPDATE_ENTITLEMENT_SUCCESS:
      return {
        ...state,
        administration: {
          ...state.administration,
          [action.data.orgUuid]: {
            ...(state.administration[action.data.orgUuid]
              ? state.administration[action.data.orgUuid]
              : {}),
            ...action.data.entitlements,
          },
        },
      }
    default:
      return state
  }
}
