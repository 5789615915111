import {
  JOURNAL_TYPE_ALL,
  JOURNAL_TYPE_PRIVATE,
  JOURNAL_TYPE_TO_REVIEW,
  JOURNAL_TYPE_APPROVED,
  JOURNAL_TYPE_HIDDEN,
  JOURNAL_RED_FLAGGED,
  JOURNAL_YELLOW_FLAGGED,
  SORT_DIRECTION_ASC,
  TOGGLE_ALL,
} from 'constants/form'

import Badge from '@weareroam/cake-ui-v1/Badge'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import ContentContainer from 'components/atoms/ContentContainer'
import JournalSubmit from 'components/atoms/Icons/JournalSubmit'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import DropdownButton from 'components/molecules/DropdownButton'
import JournalTask from 'components/molecules/JournalTask'
import MenuItem from 'components/molecules/MenuItem'
import { Modal } from 'components/molecules/Modal'
import Pagination from 'components/molecules/Pagination'
import SortHeading from 'components/molecules/SortHeading'
import ApproveJournalsForm from 'components/organisms/ApproveJournalsForm'
import DeleteJournalForm from 'components/organisms/DeleteJournalForm'
import { JournalEntryModal } from 'components/organisms/JournalEntryModal'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import useJournalModals from 'hooks/useJournalModals'
import useJournals from 'hooks/useJournals'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderLeft = styled.div``
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`
export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

const AllOrganisationsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const JournalsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const JournalHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 5%;
  }
  &:nth-child(2) {
    width: 15%;
    margin-left: 0;
  }
  &:nth-child(3) {
    min-width: 11rem;
  }
  &:nth-child(4) {
    width: 15%;
    * {
      justify-content: center;
    }
  }
  &:nth-child(5) {
    width: 10.5%;
    * {
      justify-content: center;
    }
  }
  &:nth-child(6) {
    width: 12.5%;
  }
  &:nth-child(7) {
    width: 1.5%;
  }
`

export const StyledBadgeContainer = styled.div`
  opacity: ${({ faded }) => (faded ? 1 : 0.4)};
  cursor: ${({ faded }) => (faded ? 'pointer' : null)};
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export const JournalsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const DeleteModal = styled(Modal)`
  width: 500px;
`

export const ApproveModal = styled(Modal)`
  width: 500px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Journals({
  organisations,
  getJournalsProgress,
  getOrgsProgress,
  actions,
  journals,
  repliesByJournal,
}) {
  const {
    filteredEntities: filteredOrganisations,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(
    organisations,
    'organisation',
    !getOrgsProgress.inProgress,
  )

  const {
    isDeleteJournalModalOpen,
    isApproveJournalsModalOpen,
    isJournalEntryModalOpen,
    journalModalUuid,
    handleOpenDeleteJournalModal,
    handleCloseDeleteJournalModal,
    handleOpenApproveJournalsModal,
    handleCloseApproveJournalsModal,
    handleOpenEntryJournalsModal,
    handleCloseEntryJournalsModal,
  } = useJournalModals()

  const {
    handleEditSubmit,
    handleDeleteJournal,
    handleApproveJournals,
    journalType,
    searchFilter,
    pagination,
    handleChangePagination,
    handleRevertJournal,
    handleChangeJournalType,
    handleChangeSearchFilter,
    journalsMarkedToApprove,
    handleChangeJournalsMarkedToApprove,
  } = useJournals({
    journals,
    filters: {
      key: 'orgs',
      value:
        organisations.length === filteredOrganisations.length
          ? undefined
          : filteredOrganisations,
    },
    sortOptions: { createdAt: 'DESC' },
    journalModalUuid,
    onDeleteJournal: useCallback(async (values) => {
      await actions.deleteJournal(values)
      handleCloseDeleteJournalModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
    onApproveJournals: useCallback(async (values) => {
      await actions.approveJournals(values)
      handleCloseApproveJournalsModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
    onEditJournal: useCallback(async (values) => {
      await actions.editJournal(values)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
    onRevertJournal: useCallback(async (values) => {
      await actions.revertJournal(values)
      handleCloseEntryJournalsModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
    onFetchJournals: actions.getJournals,
    fetchJournalNotification: actions.getJournalReply,
    repliesByJournal,
  })

  const editingJournal = useMemo(() => {
    if (!journals) {
      return null
    }

    return journals.find((journal) => journal.uuid === journalModalUuid)
  }, [journals, journalModalUuid])

  const canApproveJournals = useMemo(
    () => journalsMarkedToApprove && journalsMarkedToApprove.length > 0,
    [journalsMarkedToApprove],
  )

  const openJournalApproveModal = useCallback(
    () => {
      if (canApproveJournals) {
        handleOpenApproveJournalsModal()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canApproveJournals],
  )

  const handleSendPushNotification = useCallback(
    async (values) => {
      await actions.createJournalPushNotification({
        journalUuid: editingJournal.originalUuid,
        ...values,
      })
    },
    [actions, editingJournal],
  )

  return (
    <ChnnlDashboardLayout>
      <DeleteModal
        open={isDeleteJournalModalOpen}
        onClose={handleCloseDeleteJournalModal}>
        <DeleteJournalForm
          onCancel={handleCloseDeleteJournalModal}
          onSubmit={handleDeleteJournal}
        />
      </DeleteModal>

      {isJournalEntryModalOpen && (
        <JournalEntryModal
          open={true}
          journal={editingJournal}
          actions={actions}
          onCancel={handleCloseEntryJournalsModal}
          onClose={handleCloseEntryJournalsModal}
          onRevert={handleRevertJournal}
          onSubmit={handleEditSubmit}
          onSendPushNotification={handleSendPushNotification}
        />
      )}

      <ApproveModal
        open={isApproveJournalsModalOpen}
        onClose={handleCloseApproveJournalsModal}>
        <ApproveJournalsForm
          onCancel={handleCloseApproveJournalsModal}
          onSubmit={handleApproveJournals}
        />
      </ApproveModal>

      <ContentContainer>
        <StyledHeader>
          <HeaderLeft>
            <DropdownButton
              name="organisations"
              value={filteredOrganisations}
              onChange={handleChangeFilteredEntities}
              renderValue={renderFilterLabel}>
              <MenuItem value={TOGGLE_ALL}>
                <Checkbox
                  color="primary"
                  checked={
                    filteredOrganisations.length === organisations.length
                  }
                />
                <AllOrganisationsLabel primary="All organisations" />
              </MenuItem>
              {organisations.map((organisation) => (
                <MenuItem key={organisation.uuid} value={organisation.uuid}>
                  <Checkbox
                    color="primary"
                    checked={filteredOrganisations.includes(organisation.uuid)}
                  />
                  <ListItemText primary={organisation.name} />
                </MenuItem>
              ))}
            </DropdownButton>
          </HeaderLeft>
          <HeaderRight>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              name="searchFilter"
              onChange={handleChangeSearchFilter}
            />
            <Filters>
              <ButtonSwitch
                value={JOURNAL_TYPE_ALL}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_ALL}
                onClick={handleChangeJournalType}>
                All entries
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_RED_FLAGGED}
                name="journalType"
                isActive={journalType === JOURNAL_RED_FLAGGED}
                onClick={handleChangeJournalType}>
                Red flagged
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_YELLOW_FLAGGED}
                name="journalType"
                isActive={journalType === JOURNAL_YELLOW_FLAGGED}
                onClick={handleChangeJournalType}>
                Yellow flagged
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_TYPE_TO_REVIEW}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_TO_REVIEW}
                onClick={handleChangeJournalType}>
                To review
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_TYPE_APPROVED}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_APPROVED}
                onClick={handleChangeJournalType}>
                Approved
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_TYPE_HIDDEN}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_HIDDEN}
                onClick={handleChangeJournalType}>
                Hidden
              </ButtonSwitch>
            </Filters>
          </HeaderRight>
        </StyledHeader>

        <JournalsHeader>
          <JournalHeaderItem>
            <StyledBadgeContainer faded={canApproveJournals}>
              <Badge
                badgeContent={journalsMarkedToApprove.length}
                onClick={openJournalApproveModal}>
                <JournalSubmit />
              </Badge>
            </StyledBadgeContainer>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>User Fingerprint</SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>Journal entry</SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>Notification</SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>
              <strong>Sentiment</strong>
            </SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>
              <strong>Org</strong> / Group
            </SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Date</strong> / Submitted
            </SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem />
        </JournalsHeader>

        {getJournalsProgress.inProgress &&
        (!journals || journals.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <React.Fragment>
            <JournalsList>
              {journals.map((journal, index) => {
                let journalStatus
                if (!journal.shared) {
                  journalStatus = JOURNAL_TYPE_PRIVATE
                } else if (journal.hidden) {
                  journalStatus = JOURNAL_TYPE_HIDDEN
                } else if (journal.approved) {
                  journalStatus = JOURNAL_TYPE_APPROVED
                } else {
                  journalStatus = JOURNAL_TYPE_TO_REVIEW
                }
                const actions = [
                  {
                    key: 'delete-journal',
                    label: 'Delete entry',
                    onClick: () => {
                      handleOpenDeleteJournalModal(journal.uuid)
                    },
                  },
                ]
                // Private journals are always hidden
                if (journal.shared) {
                  actions.push({
                    key: 'change-hidden',
                    onClick: () => {
                      actions.changeJournalHidden({
                        journalUuid: journal.uuid,
                        hidden: !journal.hidden,
                      })
                    },
                    label: `${
                      journal.hidden ? 'Un-hide' : 'Hide'
                    } from organisation`,
                  })
                }
                return (
                  <JournalTask
                    key={journal.uuid}
                    dataQa={`journal-entry-${index}`}
                    entry={journal.editedEntry || journal.originalEntry}
                    redFlagged={journal.redFlagged}
                    yellowFlagged={journal.yellowFlagged}
                    hasEntryBeenEdited={!!journal.editedEntry}
                    organisationName={
                      journal.organisation
                        ? journal.organisation.name
                        : 'No organisation'
                    }
                    groupName={journal.group ? journal.group.name : 'No group'}
                    date={journal.forDate}
                    createdAt={journal.createdAt}
                    status={journalStatus}
                    shared={journal.shared}
                    checked={journalsMarkedToApprove.includes(journal.uuid)}
                    value={journal.uuid}
                    onCheck={handleChangeJournalsMarkedToApprove}
                    onClick={handleOpenEntryJournalsModal}
                    name="markedToApprove"
                    sentiment={journal.sentiment}
                    isJournalModerationView
                    journalReplies={repliesByJournal(journal.originalUuid)}
                    userFingerprint={journal.userFingerprint}
                    actions={actions}
                  />
                )
              })}
            </JournalsList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </React.Fragment>
        )}
      </ContentContainer>
    </ChnnlDashboardLayout>
  )
}

Journals.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  getJournalsProgress: PropTypes.object,
  getOrgsProgress: PropTypes.object,
  actions: PropTypes.object,
  journals: PropTypes.arrayOf(PropTypes.object),
  repliesByJournal: PropTypes.func,
}

Journals.defaultProps = {
  organisations: [],
  getJournalsProgress: {},
  getOrgsProgress: {},
  actions: {},
  journals: [],
}

export default Journals
