import { NormalizedSchema, schema } from 'normalizr'

import { RequestProgressState } from '../progress'

export interface Partner {
  uuid: string
  title: string
  createdAt: string
  partnerAdminCount: number
  partnerOrgCount: number
}

export interface PartnersState {
  progress: {
    getPartners: RequestProgressState
  }
  partners: NormalizedSchema<{ [key: string]: Partner }, string>
}

// Schema
export const partnersSchema = new schema.Entity(
  'partners',
  {},
  { idAttribute: 'uuid' },
)
export const partnersListSchema = [partnersSchema]
