import { JOURNAL_TYPE_ALL } from 'constants/form'
import { PAGINATION_LIMIT } from 'constants/pagination'

import { useCallback, useState, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useJournals({
  filters,
  sortOptions,
  organisation,
  onDeleteJournal,
  onApproveJournals,
  onEditJournal,
  onRevertJournal,
  onFetchJournals,
  journalModalUuid,
  fetchJournalNotification,
  repliesByJournal,
}) {
  const [journalType, setJournalType] = useState(JOURNAL_TYPE_ALL)
  const [searchFilter, setSearchFilter] = useState('')
  const [pagination, setPagination] = useState({
    start: 0,
    count: 0,
    limit: PAGINATION_LIMIT,
  })
  const [journalsMarkedToApprove, setJournalsMarkedToApprove] = useState([])
  const prevSearch = useRef(null)

  const handleEditSubmit = useCallback(
    (form) => {
      onEditJournal({
        ...form,
        journalUuid: journalModalUuid,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journalModalUuid],
  )

  const handleDeleteJournal = useCallback(
    async () => {
      await onDeleteJournal(journalModalUuid)
      await fetchCallback()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journalModalUuid],
  )

  const handleRevertJournal = useCallback(
    () => {
      onRevertJournal(journalModalUuid)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journalModalUuid],
  )

  const handleApproveJournals = useCallback(
    async () => {
      await onApproveJournals(journalsMarkedToApprove)
      setJournalsMarkedToApprove([])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journalsMarkedToApprove],
  )

  const handleChangeJournalType = useCallback((e) => {
    setJournalType(e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
    prevSearch.current = e.target.value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeJournalsMarkedToApprove = useCallback(
    (e) => {
      const clickedUuid = e.target.value

      if (journalsMarkedToApprove.includes(clickedUuid)) {
        // remove the uuid from the array
        setJournalsMarkedToApprove(
          journalsMarkedToApprove.filter((uuid) => uuid !== clickedUuid),
        )
      } else {
        // append the uuid to the array
        setJournalsMarkedToApprove([...journalsMarkedToApprove, clickedUuid])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journalsMarkedToApprove],
  )

  const handleChangePagination = useCallback(
    (newPageIndex, pagination) => {
      setPagination({
        ...pagination,
        start: newPageIndex * PAGINATION_LIMIT,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.start],
  )

  // Search for journals and refetch when filters or pagination changes
  const fetchCallback = async () => {
    const response = await onFetchJournals(
      {
        start: pagination.start,
        limit: PAGINATION_LIMIT,
        searchFilter,
        sortOptions,
        journalType,
      },
      filters,
      organisation,
    )

    if (response) {
      setPagination(response)

      if (response.journalUuids && fetchJournalNotification) {
        response.journalUuids.forEach((element) => {
          const replies = repliesByJournal(element)
          if (replies.length === 0) {
            fetchJournalNotification({ journalUuid: element })
          }
        })
      }
    }
  }

  const debouncedFetch = useDebouncedCallback(fetchCallback, 350)

  // Use debounced fetch for search filter change.
  useEffect(
    () => {
      // Don't run this on initial mount.
      if (prevSearch.current !== null) {
        debouncedFetch()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],
  )

  // Don't debounce the search on button clicks
  useEffect(
    () => {
      fetchCallback()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.start, journalType, filters?.value],
  )

  return {
    handleEditSubmit,
    handleDeleteJournal,
    handleApproveJournals,
    journalType,
    searchFilter,
    sortOptions,
    handleChangeJournalType,
    handleChangeSearchFilter,
    handleRevertJournal,
    pagination,
    handleChangePagination,
    journalsMarkedToApprove,
    handleChangeJournalsMarkedToApprove,
  }
}
