import { FormikProps, withFormik } from 'formik'
import { compose } from 'recompose'
import * as Yup from 'yup'

import { FormProps, FormValues } from './index'

export default compose<FormProps & FormikProps<FormValues>, FormProps>(
  withFormik<FormProps & FormikProps<FormValues>, FormProps>({
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, 'Must be less than 50 characters long')
        .required('Please enter your organisation name'),
      email: Yup.string()
        .matches(
          /^((?!chnnl).)*$/, // ?! is a negative look ahead
          "Please don't use @chnnl.app emails.",
        )
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      onSubmit(values)
      setSubmitting(false)
    },
  }),
)
