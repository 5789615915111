import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { Notification } from 'modules/notifications/schema'
import { useState, useCallback, useEffect } from 'react'

export const useUserMessages = (): {
  listRequest: {
    data: Notification[] | null
    error: boolean
    isLoading: boolean
  }
  listRefetch: () => Promise<void>
  sendRequest: {
    data: Notification | null
    error: boolean
    isLoading: boolean
  }
  sendMessage: typeof sendMessage
} => {
  const [listRequest, setListRequest] = useState({
    data: null,
    error: false,
    isLoading: false,
  })
  const [sendRequest, setSendRequest] = useState({
    data: null,
    error: false,
    isLoading: false,
  })

  const fetchMessages = async () => {
    try {
      setListRequest((prevState) => ({ ...prevState, isLoading: true }))
      const response = await axios.get(`${SAUCE_API_URL}/notification/`)

      console.log('Got notifications')
      console.log(response.data.data)
      setListRequest({
        data: response.data.data,
        isLoading: false,
        error: false,
      })
    } catch (error: unknown) {
      setListRequest({
        data: null,
        isLoading: false,
        error: true,
      })
    }
  }

  const listRefetch = useCallback(fetchMessages, [])
  useEffect(() => {
    listRefetch()
  }, [listRefetch])

  const sendMessage = useCallback(
    async ({
      message,
      userFingerprint,
    }: {
      message: string
      userFingerprint: string
    }) => {
      try {
        setSendRequest((prevState) => ({ ...prevState, isLoading: true }))
        const res = await axios.post(
          `${SAUCE_API_URL}/user/${userFingerprint}/notification`,
          {
            type: 'chnnl',
            message,
          },
        )

        setSendRequest({
          data: res.data.data[0].entity,
          isLoading: false,
          error: false,
        })
      } catch (e: unknown) {
        setSendRequest({
          data: null,
          isLoading: false,
          error: true,
        })
      }
    },
    [],
  )
  return { listRequest, listRefetch, sendRequest, sendMessage }
}
