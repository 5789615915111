import { JOURNAL_TASK_DATE, JOURNAL_TASK_TIME } from 'constants/date'
import {
  INCIDENT_TYPE_TO_REVIEW,
  INCIDENT_TYPE_SHARED,
  INCIDENT_TYPE_HIDDEN,
} from 'constants/form'

import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import Typography from '@weareroam/cake-ui-v1/Typography'
import JournalChecked from 'components/atoms/Icons/JournalChecked'
import JournalHidden from 'components/atoms/Icons/JournalHidden'
import JournalShared from 'components/atoms/Icons/JournalShared'
import JournalUnchecked from 'components/atoms/Icons/JournalUnchecked'
import MoreActions from 'components/molecules/MoreActions'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledIncidentTask = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

export const IncidentState = styled.div`
  width: 5%;
  z-index: 2;
`

export const IncidentEntry = styled.div`
  width: ${({ readOnly }) => (readOnly ? '65%' : '50%')};
`

export const IncidentType = styled.div`
  width: 10%;
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }

  svg {
    width: 25px;
    height: 25px;
  }
`

export const IncidentFrom = styled.div`
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const IncidentDate = styled.div`
  text-align: right;
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const IncidentActions = styled.div`
  width: 5%;
  text-align: right;
  z-index: 2;
`

export const EntryText = styled(Typography)`
  && {
    display: inline;
  }
`

export const EditText = styled(Typography)`
  && {
    display: inline;
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IncidentTask({
  dataQa,
  className,
  status,
  entry,
  hasEntryBeenEdited,
  type,
  organisationName,
  groupName,
  date,
  actions,
  onClick,
  checked,
  onCheck,
  name,
  value,
  readOnly,
}) {
  const handleOnClick = useCallback(
    () => {
      if (onClick) {
        onClick(value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  return (
    <StyledIncidentTask className={className} data-qa={dataQa}>
      {!readOnly && <ClickArea onClick={handleOnClick} />}
      {!readOnly && (
        <IncidentState>
          {status === INCIDENT_TYPE_TO_REVIEW && (
            <StyledCheckbox
              icon={<JournalUnchecked />}
              checkedIcon={<JournalChecked />}
              onChange={onCheck}
              name={name}
              checked={checked}
              value={value}
            />
          )}
          {status === INCIDENT_TYPE_SHARED && <JournalShared />}
          {status === INCIDENT_TYPE_HIDDEN && <JournalHidden />}
        </IncidentState>
      )}
      <IncidentType>
        <EntryText variant="body1" component="span">
          {type}
        </EntryText>
      </IncidentType>
      <IncidentEntry readOnly={readOnly}>
        <EntryText variant="body1" component="span">
          {entry}
        </EntryText>
        {hasEntryBeenEdited && (
          <EditText variant="body2" component="span">
            Edited
          </EditText>
        )}
      </IncidentEntry>
      <IncidentFrom>
        <Typography variant="body2">
          <strong>{organisationName}</strong>
        </Typography>
        <Typography variant="body2">{groupName}</Typography>
      </IncidentFrom>
      <IncidentDate>
        <Typography variant="body2">
          <strong>{format(date, JOURNAL_TASK_DATE)}</strong>
        </Typography>
        <Typography variant="body2">
          {format(date, JOURNAL_TASK_TIME)}
        </Typography>
      </IncidentDate>
      {!readOnly && (
        <IncidentActions>
          {actions && (
            <MoreActions
              direction="vertical"
              color="primary"
              actions={actions}
            />
          )}
        </IncidentActions>
      )}
    </StyledIncidentTask>
  )
}

IncidentTask.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  status: PropTypes.oneOf([
    INCIDENT_TYPE_TO_REVIEW,
    INCIDENT_TYPE_SHARED,
    INCIDENT_TYPE_HIDDEN,
  ]),
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  entry: PropTypes.string,
  hasEntryBeenEdited: PropTypes.bool,
  type: PropTypes.string,
  organisationName: PropTypes.string,
  groupName: PropTypes.string,
  date: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
}

export default IncidentTask
