import { withFormik } from 'formik'
import { compose } from 'recompose'
import * as Yup from 'yup'

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
    }),
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Please enter your first name')
        .max(50, 'Must be less than 50 characters long'),
      lastName: Yup.string()
        .required('Please enter your last name')
        .max(50, 'Must be less than 50 characters long'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
)
