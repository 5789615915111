import axios from 'axios'
import {
  REFRESH_TOKEN_KEY,
  SESSION_KEY,
  ROLE_KEY,
  APP_SALT,
  ORGANISATION_KEY,
  PARTNER_KEY,
} from 'config'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import { differenceInMinutes } from 'date-fns'
import jwtDecode from 'jwt-decode'
import store from 'store2'

export const refreshBuffer = 30 // in minutes

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveUserRole = (role) => {
  store.set(ROLE_KEY, role)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserRole = () => store.get(ROLE_KEY)

export const saveUserOrganisation = (orgUuid) => {
  store.set(ORGANISATION_KEY, orgUuid)
}

export const getUserOrganisation = () => store.get(ORGANISATION_KEY)

export const saveUserPartner = (partnerUuid) => {
  store.set(PARTNER_KEY, partnerUuid)
}

export const getUserPartner = () => store.get(PARTNER_KEY)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveSessionToken = (sessionToken) => {
  store.set(SESSION_KEY, sessionToken)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSessionToken = () => store.get(SESSION_KEY)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveRefreshToken = (refreshToken) => {
  store.set(REFRESH_TOKEN_KEY, refreshToken)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRefreshToken = () => store.get(REFRESH_TOKEN_KEY)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setSessionHeaders = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hashPassword = (password) => {
  if (!password || !APP_SALT) {
    return ''
  }

  const encryptedPassword = HmacSHA256(password, APP_SALT)
  return String(encryptedPassword)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTokenExpirationTime = (token) => {
  try {
    const jwt = jwtDecode(token)
    const expirationTimestamp = jwt.exp * 1000 // Multiply by 1000 for fractional seconds

    return expirationTimestamp
  } catch (error) {
    return Date.now()
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isValidToken = (token) => {
  const expirationTimestamp = getTokenExpirationTime(token)
  const minutesUntilExpiration = differenceInMinutes(
    expirationTimestamp,
    Date.now(),
  )

  if (minutesUntilExpiration > 0) {
    return true
  }

  return false
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasSessionHeaders = () =>
  !!axios.defaults.headers.common.Authorization

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clearSession = () => {
  axios.defaults.headers.common.Authorization = ''
  store.clearAll()
}
