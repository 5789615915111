import * as toastActions from 'modules/toasts/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      toasts: state.toasts.messages,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...toastActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    onRemoveToast:
      ({ actions }) =>
      (toast) => {
        actions.removeToast(toast.id)
      },
    onRemoveAll:
      ({ actions }) =>
      () => {
        actions.removeAllToasts()
      },
  }),
  lifecycle({
    shouldComponentUpdate(nextProps) {
      const { toasts } = this.props
      return toasts.length !== nextProps.toasts.length
    },
  }),
)
