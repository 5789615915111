import React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
function Restful() {
  return (
    <svg width="25px" height="25px" viewBox="0 0 75.49 87.92">
      <g data-name="Layer 2">
        <g data-name="WHO-5 categories">
          <g>
            <path
              d="M27.89 9.67s-15 8-19 20M35.57 79.81s6.32 2.86 22.32-2.14c0 0-10 2-13-2"
              fill="none"
              stroke="#5c8368"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5px"
            />
            <path
              d="M44 0a44 44 0 1031 75 1.57 1.57 0 000-2.21 1.55 1.55 0 00-1.4-.43c-1.21.22-2.55.35-3.71.46q-1.25.09-2.49.09c-1.19 0-2.4-.06-3.59-.18a39.48 39.48 0 01-7-1.42 35 35 0 01-3.34-1.22 35.44 35.44 0 01-3.23-1.55c-1-.58-2.07-1.2-3.05-1.87a35.64 35.64 0 01-2.85-2.17 33.88 33.88 0 01-2.63-2.5q-1.24-1.29-2.37-2.7a33.22 33.22 0 01-2.08-2.9 34.35 34.35 0 01-1.78-3.12c-.54-1.06-1-2.16-1.47-3.27a34.65 34.65 0 01-1.12-3.4 35.35 35.35 0 01-.79-3.5c-.2-1.18-.35-2.36-.44-3.55-.06-.84-.08-1.69-.08-2.54 0-1.19.05-2.4.17-3.59s.3-2.37.53-3.54.54-2.33.89-3.47c.36-1.14.76-2.27 1.23-3.37a35.44 35.44 0 011.6-3.19c.58-1.05 1.21-2.07 1.88-3.05A35.64 35.64 0 0140 14a33.88 33.88 0 012.45-2.63A35.45 35.45 0 0145.14 9c.94-.74 1.92-1.43 2.92-2.07a33.86 33.86 0 013.09-1.78c.79-.4 1.67-.77 2.44-1.1a1.56 1.56 0 00-.27-2.95A44.21 44.21 0 0044 0zm0 3.13c1.23 0 2.44.29 3.67.4-.42.25-.85.47-1.26.73a35.5 35.5 0 00-3.16 2.26c-1 .81-2 1.67-2.93 2.57a38.71 38.71 0 00-7.06 9.28c-.62 1.14-1.19 2.31-1.7 3.51a38.89 38.89 0 00-1.32 3.66 37.9 37.9 0 00-1 3.77 38.47 38.47 0 00-.59 3.85c-.13 1.3-.19 2.6-.19 3.9 0 .92 0 1.84.1 2.76a38.72 38.72 0 00.44 3.86c.22 1.28.51 2.56.85 3.81s.76 2.48 1.23 3.69a37.65 37.65 0 001.59 3.55 36.47 36.47 0 001.94 3.38 35.75 35.75 0 002.27 3.17c.81 1 1.67 2 2.57 2.93a37.69 37.69 0 002.85 2.66c1 .83 2 1.62 3.1 2.35a39 39 0 003.33 2 37.64 37.64 0 003.5 1.7c1.2.5 2.43 1 3.67 1.33a42.12 42.12 0 007.59 1.53c1.3.13 2.59.2 3.89.2L68 76c-7 5.26-15.2 8.76-24.06 8.77A40.83 40.83 0 0144 3.13z"
              fill="#aad5b7"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default React.memo(Restful)
