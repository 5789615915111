import {
  INCIDENT_TYPE_ALL,
  INCIDENT_TYPE_TO_REVIEW,
  INCIDENT_TYPE_SHARED,
  INCIDENT_TYPE_HIDDEN,
} from 'constants/form'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import idx from 'idx'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'
import { getQueryParams, getArrayFilters, getPaginationResult } from 'utils/api'

import { incidentListSchema } from './schema'

// Actions
export const API_GET_INCIDENTS_REQUEST = 'API_GET_INCIDENTS_REQUEST'
export const API_GET_INCIDENTS_SUCCESS = 'API_GET_INCIDENTS_SUCCESS'
export const API_GET_INCIDENTS_ERROR = 'API_GET_INCIDENTS_ERROR'

export const API_DELETE_INCIDENT_REQUEST = 'API_DELETE_INCIDENT_REQUEST'
export const API_DELETE_INCIDENT_SUCCESS = 'API_DELETE_INCIDENT_SUCCESS'
export const API_DELETE_INCIDENT_ERROR = 'API_DELETE_INCIDENT_ERROR'

export const API_APPROVE_INCIDENTS_REQUEST = 'API_APPROVE_INCIDENTS_REQUEST'
export const API_APPROVE_INCIDENTS_SUCCESS = 'API_APPROVE_INCIDENTS_SUCCESS'
export const API_APPROVE_INCIDENTS_ERROR = 'API_APPROVE_INCIDENTS_ERROR'

export const API_HIDE_INCIDENT_REQUEST = 'API_HIDE_INCIDENT_REQUEST'
export const API_HIDE_INCIDENT_SUCCESS = 'API_HIDE_INCIDENT_SUCCESS'
export const API_HIDE_INCIDENT_ERROR = 'API_HIDE_INCIDENT_ERROR'

export const API_EDIT_INCIDENT_REQUEST = 'API_EDIT_INCIDENT_REQUEST'
export const API_EDIT_INCIDENT_SUCCESS = 'API_EDIT_INCIDENT_SUCCESS'
export const API_EDIT_INCIDENT_ERROR = 'API_EDIT_INCIDENT_ERROR'

export const API_REVERT_INCIDENT_REQUEST = 'API_REVERT_INCIDENT_REQUEST'
export const API_REVERT_INCIDENT_SUCCESS = 'API_REVERT_INCIDENT_SUCCESS'
export const API_REVERT_INCIDENT_ERROR = 'API_REVERT_INCIDENT_ERROR'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getIncidentFilters = (type) => {
  switch (type) {
    case INCIDENT_TYPE_HIDDEN:
      return 'filters:{hidden:$eq_true}'
    case INCIDENT_TYPE_SHARED:
      return 'filters:{approved:$eq_true,hidden:$eq_false}'
    case INCIDENT_TYPE_TO_REVIEW:
      return 'filters:{approved:$eq_false,hidden:$eq_false}'
    case INCIDENT_TYPE_ALL:
    default:
      return ''
  }
}

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOrgIncidents(params = {}, filters, organisation) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_INCIDENTS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisation}/incident?${getArrayFilters(
          filters,
        )}&${getQueryParams(params)}`,
      )
      const data = normalize(response.data.data, incidentListSchema)
      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GET_INCIDENTS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_INCIDENTS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the incidents. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getIncidentReports(params = {}, filters) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_INCIDENTS_REQUEST })
    try {
      const optionsQuery = encodeURIComponent(
        `{${getIncidentFilters(params.incidentType)}}`,
      )
      const response = await axios.get(
        `${SAUCE_API_URL}/shared-incident?${getArrayFilters(
          filters,
        )}&${getQueryParams(params)}&options=${optionsQuery}`,
      )
      const data = normalize(response.data.data, incidentListSchema)
      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GET_INCIDENTS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_INCIDENTS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the incidents. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteIncident(incidentUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_INCIDENT_REQUEST })

    try {
      await axios.delete(`${SAUCE_API_URL}/shared-incident/${incidentUuid}`)

      dispatch({
        type: API_DELETE_INCIDENT_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Incident entry has been deleted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_INCIDENT_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the incident entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function approveIncidents(entries) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_APPROVE_INCIDENTS_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/shared-incident/approve`,
        {
          uuids: entries,
        },
      )
      const data = normalize(response.data.data, incidentListSchema)

      dispatch({
        type: API_APPROVE_INCIDENTS_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Incidents have been approved',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_APPROVE_INCIDENTS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the incident entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function editIncident({ incidentUuid, entry }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_EDIT_INCIDENT_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/shared-incident/${incidentUuid}`,
        {
          editedEntry: entry,
        },
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_EDIT_INCIDENT_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: 'Incident entry has been edited',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_EDIT_INCIDENT_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the incident entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function revertIncident(incidentUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch, getState) {
    dispatch({
      type: API_REVERT_INCIDENT_REQUEST,
      data: {
        incidentUuid,
      },
    })

    const state = getState()
    const { editedEntry } = state.incidents.entities[incidentUuid]

    try {
      await axios.put(`${SAUCE_API_URL}/shared-incident/${incidentUuid}`, {
        editedEntry: null,
      })

      dispatch({
        type: API_REVERT_INCIDENT_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Incident entry has been reverted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({
        type: API_REVERT_INCIDENT_ERROR,
        error,
        data: {
          incidentUuid,
          editedEntry,
        },
      })
      dispatch(
        addToast({
          message:
            'There was an error reverting the incident entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function changeIncidentHidden({
  uuid,
  hidden,
  editedEntry,
  originalEntry,
}) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_HIDE_INCIDENT_REQUEST })

    // Populate edited entry if super admin decides to share straight away without editing.
    // We don't show original entry to org/group admins.
    const entry = editedEntry || originalEntry

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/shared-incident/${uuid}`,
        {
          hidden: !hidden,
          editedEntry: entry,
        },
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_HIDE_INCIDENT_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: `Incident entry has been ${hidden ? 'hidden' : 'un-hidden'}`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_HIDE_INCIDENT_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error hiding the incident entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
