import Portal from '@weareroam/cake-ui-v1/Portal'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Toast from 'components/atoms/Toast'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledClearButton = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 630px;
  z-index: 999;
  padding: ${({ theme }) => theme.spacing.sm}px;
  border-radius: ${({ theme }) => theme.spacing.sm}px;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.35);

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  & * {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  &.closed {
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Toasts({ toasts, onRemoveToast, onRemoveAll }) {
  return (
    <Portal>
      {toasts.map((item, index) => (
        <Toast
          data={item}
          key={item.id}
          onClose={onRemoveToast}
          toastCount={toasts.length}
          index={index}
        />
      ))}
      <StyledClearButton
        onClick={onRemoveAll}
        className={toasts.length > 1 ? 'open' : 'closed'}>
        <Typography>Clear all</Typography>
      </StyledClearButton>
    </Portal>
  )
}

Toasts.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.object),
  onRemoveToast: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func,
}

Toasts.defaultProps = {
  toasts: [],
}

export default Toasts
