import { LOGIN } from 'constants/routes'

import Typography from '@weareroam/cake-ui-v1/Typography'
import Link from 'components/atoms/Link'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import React from 'react'
import styled from 'styled-components'

const StyledContent = styled.div`
  text-align: center;
  width: 340px;
`

const StyledTitle = styled(Typography)`
  && {
    font-family: 'PP Telegraf', sans-serif;
    font-weight: 300;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const StyledParagraph = styled(Typography)`
  && {
    font-family: 'Graphik', sans-serif;
    font-weight: 300;
    letter-spacing: 0.8px;
    font-size: 16px;
    line-height: 24px;
    padding-top: ${({ theme }) => theme.spacing.xs}px;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const StyledLink = styled(Link)`
  && {
    font-family: 'Graphik', sans-serif;
    font-weight: 300;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function NoAccount(): React.ReactElement {
  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">Account not found</StyledTitle>
        <StyledParagraph>
          Your email is currently not registered as a chnnl dashboard admin. If
          this is a mistake please contact your organisation key contact or{' '}
          <a
            href="mailto:support@chnnl.app"
            target="_blank"
            rel="noopener noreferrer">
            support@chnnl.app
          </a>
          .
        </StyledParagraph>
        <StyledLink to={LOGIN}>Back to login</StyledLink>
      </StyledContent>
    </DefaultLayout>
  )
}
