import * as authActions from 'modules/authentication/actions'
import * as partnersActions from 'modules/partners/actions'
import { getPartnersList } from 'modules/partners/selectors'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      partners: getPartnersList(state),
      getPartnersProgress: state.progress.getPartners,
      partnerEntities: state.partners.entities,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...partnersActions,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState('isNewPartnerModalOpen', 'setIsNewPartnerModalOpen', false),
  withState('isDeletePartnerModalOpen', 'setIsDeletePartnerModalOpen', false),
  withState('modalPartnerId', 'setModalPartnerId', null),
  lifecycle({
    componentDidMount() {
      this.props.actions.getPartners()
    },
  }),
  withHandlers({
    onCreateNewPartner:
      ({ actions, setIsNewPartnerModalOpen }) =>
      ({ title }) => {
        actions.createPartner({ title })

        setIsNewPartnerModalOpen(false)
      },
    onDeletePartner:
      ({ actions, modalPartnerId, setIsDeletePartnerModalOpen }) =>
      (e) => {
        e.preventDefault()
        actions.deletePartner(modalPartnerId)
        setIsDeletePartnerModalOpen(false)
      },
  }),
)
