// eslint-disable-next-line @typescript-eslint/naming-convention
export const TOAST_TYPE_ERROR = 'TOAST_TYPE_ERROR'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TOAST_TYPE_SUCCESS = 'TOAST_TYPE_SUCCESS'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TOAST_TYPE_PRIMARY = 'TOAST_TYPE_PRIMARY'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PATHWAY_TYPE_POSITIVE = 'Positive pathway'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const PATHWAY_TYPE_LOW_RISK = 'Low risk pathway'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const PATHWAY_TYPE_HIGH_RISK = 'High risk pathway'
