import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import * as React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { FEATURE_KEY } from '../config'
import { getUser } from '../modules/user/selectors'

const optimizely = createInstance({ sdkKey: FEATURE_KEY || '' })

interface FeatureProviderPropTypes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any
}

const FeatureProvider: React.FC<FeatureProviderPropTypes> = ({
  children,
  user,
}: FeatureProviderPropTypes): React.ReactElement => {
  let orgUuid: string | undefined = undefined

  // Extract org uuid from path, as current org is not currently in Redux...
  const { pathname } = useLocation()
  const parts = pathname.split('/')
  if (parts.length > 2 && parts[2] === 'organisation') {
    orgUuid = parts[3]
  }

  const userParams = React.useMemo(
    () => ({
      id: user.uuid || 'UNDEFINED',
      attributes: {
        // Need to set attributes to null to remove them if previously were set
        // https://docs.developers.optimizely.com/web/docs/user
        userUuid: user.uuid || null,

        // Taking modulo of the first uuid character for 50/50 and 10% splits
        userUuidModulo2: user.uuid ? user.uuid.charCodeAt(0) % 2 : null,
        userUuidModulo10: user.uuid ? user.uuid.charCodeAt(0) % 10 : null,

        organisationUuid: orgUuid || null,
      },
    }),
    [user, orgUuid],
  )

  return (
    <OptimizelyProvider optimizely={optimizely} user={userParams} timeout={200}>
      {children}
    </OptimizelyProvider>
  )
}

export default connect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: any) => ({ user: getUser(state) }),
  null,
)(FeatureProvider)
