import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'

export const StyledNavItem = styled.span`
  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary.main : theme.palette.tertiary.dark};
`

export const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  height: 100%;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};
`

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md}px;
  padding-left: ${({ isVertical, theme }) =>
    isVertical ? theme.spacing.md : 0}px;
  width: ${({ isVertical }) => (isVertical ? '100%' : 'auto')};
`

export const StyledText = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  margin: ${({ theme }) => theme.spacing.sm}px;
`

export const StyledUnderline = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.palette.primary.main};
  left: 0;
  top: ${({ isVertical }) => (isVertical ? 0 : 'auto')};
  bottom: ${({ isVertical }) => (isVertical ? 'auto' : 0)};
  height: ${({ isVertical }) => (isVertical ? '100%' : '2px')};
  width: ${({ isVertical }) => (isVertical ? '2px' : '100%')};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NavItem({
  to,
  className,
  text,
  icon,
  active,
  match,
  direction,
}) {
  const routeActive = match && match.url && match.url === to
  const isActive = routeActive || active
  const isVertical = direction === 'vertical'

  return (
    <StyledNavItem isActive={isActive}>
      <StyledLink direction={direction} className={className} to={to}>
        {icon && <StyledIcon isVertical={isVertical}>{icon}</StyledIcon>}
        {text && <StyledText>{text}</StyledText>}
        {isActive && <StyledUnderline isVertical={isVertical} />}
      </StyledLink>
    </StyledNavItem>
  )
}

NavItem.propTypes = {
  match: PropTypes.object,
  className: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  active: PropTypes.bool,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
}

NavItem.defaultProps = {
  direction: 'horizontal',
  match: {},
}

export default withRouter(NavItem)
