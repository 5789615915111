import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const StyledInput = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledButton = styled(Button)`
  && {
    margin: ${({ theme }) => theme.spacing.sm}px;
    padding: ${({ theme }) => theme.spacing.sm}px;
    margin-top: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const HeaderButton = styled(Button)`
  && {
    width: 136px;
  }
`

export const FormContent = styled.div`
  width: 372px;
  display: flex;
  flex-direction: column;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function EditProfileForm({
  handleSubmit,
  values,
  errors,
  touched,
  showConfirmHeader,
  handleChange,
  handleBlur,
}) {
  return (
    <StyledForm onSubmit={handleSubmit}>
      {showConfirmHeader && (
        <StyledHeader>
          <Typography variant="h2">Profile</Typography>
          <HeaderButton
            size="small"
            variant="contained"
            type="submit"
            color="primary">
            Update
          </HeaderButton>
        </StyledHeader>
      )}
      <FormContent>
        <StyledInput
          id="firstName"
          name="firstName"
          label="First name"
          type="text"
          value={values.firstName || ''}
          InputLabelProps={{ shrink: true }}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.firstName && !!errors.firstName}
          helperText={touched.firstName ? errors.firstName : ''}
        />
        <StyledInput
          id="lastName"
          name="lastName"
          label="Last name"
          type="text"
          value={values.lastName || ''}
          InputLabelProps={{ shrink: true }}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.lastName && !!errors.lastName}
          helperText={touched.lastName ? errors.lastName : ''}
        />
        {!showConfirmHeader && (
          <StyledButton
            size="small"
            variant="contained"
            type="submit"
            color="primary">
            Update
          </StyledButton>
        )}
      </FormContent>
    </StyledForm>
  )
}

EditProfileForm.propTypes = {
  showConfirmHeader: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
}

EditProfileForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default EditProfileForm
