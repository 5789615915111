import {
  JOURNAL_TYPE_ALL,
  JOURNAL_RED_FLAGGED,
  JOURNAL_YELLOW_FLAGGED,
  JOURNAL_TYPE_TO_REVIEW,
  JOURNAL_TYPE_APPROVED,
  JOURNAL_TYPE_HIDDEN,
  JOURNAL_TYPE_PRIVATE,
} from 'constants/form'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import idx from 'idx'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'
import { getQueryParams, getArrayFilters, getPaginationResult } from 'utils/api'

import { notificationListSchema } from '../notifications/schema'
import { NotificationTypes } from '../notifications/types'

import { journalListSchema } from './schema'

// Actions
export const API_GET_JOURNALS_REQUEST = 'API_GET_JOURNALS_REQUEST'
export const API_GET_JOURNALS_SUCCESS = 'API_GET_JOURNALS_SUCCESS'
export const API_GET_JOURNALS_ERROR = 'API_GET_JOURNALS_ERROR'

export const API_DELETE_JOURNAL_REQUEST = 'API_DELETE_JOURNAL_REQUEST'
export const API_DELETE_JOURNAL_SUCCESS = 'API_DELETE_JOURNAL_SUCCESS'
export const API_DELETE_JOURNAL_ERROR = 'API_DELETE_JOURNAL_ERROR'

export const API_APPROVE_JOURNALS_REQUEST = 'API_APPROVE_JOURNALS_REQUEST'
export const API_APPROVE_JOURNALS_SUCCESS = 'API_APPROVE_JOURNALS_SUCCESS'
export const API_APPROVE_JOURNALS_ERROR = 'API_APPROVE_JOURNALS_ERROR'

export const API_HIDE_JOURNAL_REQUEST = 'API_HIDE_JOURNAL_REQUEST'
export const API_HIDE_JOURNAL_SUCCESS = 'API_HIDE_JOURNAL_SUCCESS'
export const API_HIDE_JOURNAL_ERROR = 'API_HIDE_JOURNAL_ERROR'

export const API_EDIT_JOURNAL_REQUEST = 'API_EDIT_JOURNAL_REQUEST'
export const API_EDIT_JOURNAL_SUCCESS = 'API_EDIT_JOURNAL_SUCCESS'
export const API_EDIT_JOURNAL_ERROR = 'API_EDIT_JOURNAL_ERROR'

export const API_REVERT_JOURNAL_REQUEST = 'API_REVERT_JOURNAL_REQUEST'
export const API_REVERT_JOURNAL_SUCCESS = 'API_REVERT_JOURNAL_SUCCESS'
export const API_REVERT_JOURNAL_ERROR = 'API_REVERT_JOURNAL_ERROR'

export const API_GET_JOURNAL_REPLY_REQUEST = 'API_GET_JOURNAL_REPLY_REQUEST'
export const API_GET_JOURNAL_REPLY_SUCCESS = 'API_GET_JOURNAL_REPLY_SUCCESS'
export const API_GET_JOURNAL_REPLY_ERROR = 'API_GET_JOURNAL_REPLY_ERROR'

export const API_SEND_JOURNAL_PN_REQUEST = 'API_SEND_JOURNAL_PN_REQUEST'
export const API_SEND_JOURNAL_PN_SUCCESS = 'API_SEND_JOURNAL_PN_SUCCESS'
export const API_SEND_JOURNAL_PN_ERROR = 'API_SEND_JOURNAL_PN_ERROR'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getJournalFilters = (type) => {
  switch (type) {
    case JOURNAL_TYPE_PRIVATE:
      return 'filters:{shared:$eq_false}'
    case JOURNAL_TYPE_HIDDEN:
      return 'filters:{hidden:$eq_true}'
    case JOURNAL_RED_FLAGGED:
      return 'filters:{redFlagged:$eq_true}'
    case JOURNAL_YELLOW_FLAGGED:
      return 'filters:{yellowFlagged:$eq_true}'
    case JOURNAL_TYPE_APPROVED:
      return 'filters:{approved:$eq_true,hidden:$eq_false}'
    case JOURNAL_TYPE_TO_REVIEW:
      return 'filters:{approved:$eq_false,hidden:$eq_false,shared:$eq_true}'
    case JOURNAL_TYPE_ALL:
    default:
      return ''
  }
}

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOrgJournals(params = {}, filters, organisation) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_JOURNALS_REQUEST })
    try {
      const options = []
      if (params.searchFilter) {
        params.searchFilter = params.searchFilter.trim()
      }
      if (params.sortOptions) {
        // produces "sort:{createdAt:DESC}"
        options.push(
          JSON.stringify({ sort: params.sortOptions })
            .replaceAll('"', '')
            .replace('{', '')
            .replace(/}$/, ''),
        )
      }
      const optionsString = `{${options.join(',')}}`

      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisation}/journal?${getArrayFilters(
          filters,
        )}&${getQueryParams(params)}&options=${encodeURIComponent(
          optionsString,
        )}`,
      )
      const data = normalize(response.data.data, journalListSchema)
      const pagination = {
        ...getPaginationResult(response.data),
        journalUuids: response.data.data.map((t) => t.originalUuid),
      }

      dispatch({
        type: API_GET_JOURNALS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_JOURNALS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the journals. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getJournals(params = {}, filters) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_JOURNALS_REQUEST })

    try {
      const options = []
      if (params.searchFilter) {
        params.searchFilter = params.searchFilter.trim()
      }
      const filterOptions = getJournalFilters(params.journalType)
      if (filterOptions) {
        options.push(filterOptions)
      }
      if (params.sortOptions) {
        // produces "sort:{createdAt:DESC}"
        options.push(
          JSON.stringify({ sort: params.sortOptions })
            .replaceAll('"', '')
            .replace('{', '')
            .replace(/}$/, ''),
        )
      }
      const optionsString = `{${options.join(',')}}`

      const response = await axios.get(
        `${SAUCE_API_URL}/journal?${getArrayFilters(filters)}&${getQueryParams(
          params,
        )}&options=${encodeURIComponent(optionsString)}`,
      )
      const data = normalize(response.data.data, journalListSchema)
      const pagination = {
        ...getPaginationResult(response.data),
        journalUuids: response.data.data.map((t) => t.originalUuid),
      }
      dispatch({
        type: API_GET_JOURNALS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_JOURNALS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the journals. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function getJournalReply({ journalUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_JOURNAL_REPLY_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/journal/${journalUuid}/notification`,
      )
      const data = normalize(response.data.data, notificationListSchema)
      dispatch({
        type: API_GET_JOURNAL_REPLY_SUCCESS,
        data,
      })

      return data
    } catch (error) {
      dispatch({ type: API_GET_JOURNAL_REPLY_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the journal reply. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteJournal(journalUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_JOURNAL_REQUEST })

    try {
      await axios.delete(`${SAUCE_API_URL}/journal/${journalUuid}`)

      dispatch({
        type: API_DELETE_JOURNAL_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Journal entry has been deleted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_JOURNAL_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function approveJournals(entries) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_APPROVE_JOURNALS_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/journal/approve`, {
        entries,
      })
      const data = normalize(response.data.data, journalListSchema)

      dispatch({
        type: API_APPROVE_JOURNALS_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Journals have been approved',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_APPROVE_JOURNALS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error approving the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function editJournal({
  journalUuid,
  entry,
  yellowFlagged,
  redFlagged,
  tags,
}) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_EDIT_JOURNAL_REQUEST })

    /*
     We only update flag properties that have been touched
    */
    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/journal/${journalUuid}/edit`,
        {
          editedEntry: entry,
          ...(redFlagged !== null ? { redFlagged } : {}),
          ...(yellowFlagged !== null ? { yellowFlagged } : {}),
          journalTags: tags,
        },
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_EDIT_JOURNAL_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: 'Journal entry has been edited',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_EDIT_JOURNAL_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error editing the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function revertJournal(journalUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch, getState) {
    dispatch({
      type: API_REVERT_JOURNAL_REQUEST,
      data: {
        journalUuid,
      },
    })

    const state = getState()
    const { editedEntry } = state.journals.entities[journalUuid]

    try {
      await axios.put(`${SAUCE_API_URL}/journal/${journalUuid}/edit`, {
        editedEntry: null,
      })

      dispatch({
        type: API_REVERT_JOURNAL_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Journal entry has been reverted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({
        type: API_REVERT_JOURNAL_ERROR,
        error,
        data: {
          journalUuid,
          editedEntry,
        },
      })
      dispatch(
        addToast({
          message:
            'There was an error reverting the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function changeJournalHidden({ journalUuid, hidden }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_HIDE_JOURNAL_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/journal/${journalUuid}/hide`,
        {
          hidden,
        },
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_HIDE_JOURNAL_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: `Journal entry has been ${hidden ? 'hidden' : 'un-hidden'}`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_HIDE_JOURNAL_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error hiding the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function createJournalPushNotification({ journalUuid, reply }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_SEND_JOURNAL_PN_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/journal/${journalUuid}/notification`,
        {
          type: NotificationTypes.CHNNL,
          message: reply,
        },
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_SEND_JOURNAL_PN_SUCCESS,
        data,
      })

      dispatch({
        type: API_GET_JOURNAL_REPLY_SUCCESS,
        data: normalize([data.entity], notificationListSchema),
      })

      dispatch(
        addToast({
          message: `Push notification has been sent`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )

      return true
    } catch (error) {
      dispatch({ type: API_SEND_JOURNAL_PN_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error sending the notification.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export const actions = {
  getOrgJournals,
  getJournals,
  getJournalReply,
  deleteJournal,
  approveJournals,
  editJournal,
  revertJournal,
  changeJournalHidden,
  createJournalPushNotification,
}
