import { FeatureKeys } from 'constants/features'
import {
  CHNNL_DASHBOARD_TIPS,
  CHNNL_DASHBOARD_JOURNALS,
  CHNNL_DASHBOARD_DISTRESSED_USERS,
  CHNNL_DASHBOARD_CHATBOT,
  CHNNL_DASHBOARD_SETTINGS,
  CHNNL_DASHBOARD_SPEAKUP,
  CHNNL_DASHBOARD_PARTNERS,
  CHNNL_DASHBOARD_ADMIN_MESSAGES,
} from 'constants/routes'

import { useFeature } from '@optimizely/react-sdk'
import AllInboxIcon from '@weareroam/cake-ui-v1-icons/AllInbox'
import ContentIcon from '@weareroam/cake-ui-v1-icons/LibraryBooksSharp'
import ListIcon from '@weareroam/cake-ui-v1-icons/List'
import DeltaIcon from 'components/atoms/Icons/Delta'
import JournalsIcon from 'components/atoms/Icons/Journals'
import PartnersIcon from 'components/atoms/Icons/Partners'
import SettingsIcon from 'components/atoms/Icons/Settings'
import SpeakUpIcon from 'components/atoms/Icons/SpeakUp'
import NavItem from 'components/molecules/NavItem'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledSidebar = styled.aside`
  width: 60px;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const StyledNav = styled.nav``

export const StyledNavItem = styled(NavItem)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChnnlAdminSidebar({ className }) {
  const speakUpEnabled = useFeature(FeatureKeys.SpeakUp)
  const partnersEnabled = useFeature(FeatureKeys.Partners)
  const [tipsEnabled] = useFeature(FeatureKeys.TipsNavigation)
  const [distressedUsersEnabled] = useFeature(FeatureKeys.DistressedUsers)

  return (
    <StyledSidebar className={className}>
      <StyledNav>
        <StyledNavItem
          to={CHNNL_DASHBOARD_JOURNALS}
          icon={<JournalsIcon />}
          direction="vertical"
        />
        <StyledNavItem
          to={CHNNL_DASHBOARD_ADMIN_MESSAGES}
          icon={<AllInboxIcon />}
          direction="vertical"
        />
        {distressedUsersEnabled && (
          <StyledNavItem
            to={CHNNL_DASHBOARD_DISTRESSED_USERS}
            icon={<ListIcon />}
            direction="vertical"
          />
        )}
        {speakUpEnabled && (
          <StyledNavItem
            to={CHNNL_DASHBOARD_SPEAKUP}
            icon={<SpeakUpIcon />}
            direction="vertical"
          />
        )}
        {tipsEnabled && (
          <StyledNavItem
            to={CHNNL_DASHBOARD_TIPS}
            icon={<ContentIcon />}
            direction="vertical"
          />
        )}
        <StyledNavItem
          to={CHNNL_DASHBOARD_CHATBOT}
          icon={<DeltaIcon height={30} />}
          direction="vertical"
        />
        {partnersEnabled && (
          <StyledNavItem
            to={CHNNL_DASHBOARD_PARTNERS}
            icon={<PartnersIcon />}
            direction="vertical"
          />
        )}
        <StyledNavItem
          to={CHNNL_DASHBOARD_SETTINGS}
          icon={<SettingsIcon />}
          direction="vertical"
        />
      </StyledNav>
    </StyledSidebar>
  )
}

ChnnlAdminSidebar.propTypes = {
  className: PropTypes.string,
}

export default ChnnlAdminSidebar
