import initialState from 'reducers/initialState'

import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOASTS } from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function toastReducer(state = initialState.toasts, action) {
  switch (action.type) {
    case ADD_TOAST: {
      const { message, type, id } = action.data

      return {
        ...state,
        messages: [
          ...state.messages,
          {
            message,
            type,
            id,
          },
        ],
      }
    }
    case REMOVE_TOAST:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message.id !== action.data.id,
        ),
      }
    case REMOVE_ALL_TOASTS:
      return {
        ...state,
        messages: [],
      }
    default:
      return state
  }
}
