import Button from '@weareroam/cake-ui-v1/Button'
import Portal from '@weareroam/cake-ui-v1/Portal'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const entryAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

const exitAnimation = keyframes`
  from {
    //opacity: 1;
    transform: translateX(0px);
  }

  to {
    //opacity: 0;
    transform: translateX(-10px);
  }
`

export const StyledUndo = styled.div`
  transition: top 0.3s ease;
  opacity: 0;
  width: 300px;
  display: flex;
  z-index: 999;
  position: fixed;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing.sm}px;
  top: 85px;
  padding: ${({ theme }) => theme.spacing.md}px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  right: ${({ theme }) => theme.spacing.md}px;

  &.open {
    animation: ${entryAnimation} 0.3s linear forwards;
  }
  &.closed {
    animation: ${exitAnimation} 0.3s linear forwards;
  }

  @media (max-width: 1024px) {
    width: 95%;
  }
`

export const StyledMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.text.main};
    margin-bottom: 10px;
  }
`

export const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Undo({ undoItems, onUndo, onUndoCancel }) {
  const [counter, setCounter] = useState(5)

  const counterRef = useRef(null)
  counterRef.current = counter
  useEffect(
    () => {
      let timer = null
      if (undoItems.length) {
        setCounter(5)
        timer = setInterval(() => setCounter(counterRef.current - 1), 1000)
      }
      return () => {
        clearInterval(timer)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [undoItems],
  )

  useEffect(
    () => {
      if (counter <= 0) {
        onUndoCancel()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [counter],
  )
  const handleOnClick = useCallback(
    () => {
      if (onUndo && undoItems.length) {
        onUndo(undoItems[undoItems.length - 1])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUndo, undoItems],
  )

  return (
    <Portal>
      {undoItems.length > 0 && (
        <StyledUndo className={undoItems.length ? 'open' : 'closed'}>
          <StyledMessage>
            {undoItems.length} transaction updated. You can undo this change for
            the next {counter} seconds.
          </StyledMessage>
          <StyledButtonRow>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="primary"
              onClick={handleOnClick}>
              Undo
            </Button>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="primary"
              onClick={onUndoCancel}>
              Cancel
            </Button>
          </StyledButtonRow>
        </StyledUndo>
      )}
    </Portal>
  )
}

Undo.propTypes = {
  undoItems: PropTypes.arrayOf(PropTypes.object),
  onUndo: PropTypes.func.isRequired,
  onUndoCancel: PropTypes.func,
}

Undo.defaultProps = {
  undoItems: [],
}

export default Undo
