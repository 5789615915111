import { HumanReadableDatetime } from 'constants/date'

import MaterialTableCell, { TableCellProps } from '@material-ui/core/TableCell'
import { TableRow } from '@weareroam/cake-ui-v1'
import { HeadingLabel } from 'components/molecules/SortHeading/SortHeading'
import { format } from 'date-fns'
import { Notification } from 'modules/notifications/schema'
import React from 'react'
import styled from 'styled-components'

const StyledTableRow = styled(TableRow)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  cursor: pointer;

  td:first-child {
    text-align: left;
    padding-left: 1rem;
  }

  td {
    margin-bottom: 10rem;
  }
`

const TableData = ({
  children,
  align = 'right',
  ...rest
}: { children: React.ReactNode } & TableCellProps) => (
  <MaterialTableCell component="td" align={align} {...rest}>
    <HeadingLabel variant="body2">{children}</HeadingLabel>
  </MaterialTableCell>
)

export const AdminMessageRow = (
  props: Notification & { key: number; onClick?: () => void },
): React.ReactElement => (
  <StyledTableRow key={props.key} onClick={props.onClick}>
    <TableData align="left" scope="row">
      {props.userFingerprint.slice(0, 3)}-{props.userFingerprint.slice(3, 7)}
    </TableData>
    <TableData>{format(props.createdAt, HumanReadableDatetime)}</TableData>
    <TableData>{props.message}</TableData>
  </StyledTableRow>
)
