import SearchIcon from '@weareroam/cake-ui-v1-icons/Search'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledSearchField = styled.div`
  position: relative;
`
export const StyledInput = styled.input`
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  background: transparent;
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.md}px ${({ theme }) => theme.spacing.sm}px
    36px;
  outline: 0;
  width: 100%;
  color: ${({ theme }) => theme.palette.tertiary.main};

  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiary.main};
    opacity: 1;
  }
`
export const StyledIcon = styled(SearchIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  pointer-events: none;
  color: ${({ theme }) => theme.palette.tertiary.main};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SearchField({
  value,
  onChange,
  name,
  placeholder,
  className,
  id,
}) {
  return (
    <StyledSearchField className={className}>
      <StyledInput
        id={id}
        name={name}
        placeholder={placeholder}
        type="search"
        value={value}
        onChange={onChange}
      />
      <StyledIcon />
    </StyledSearchField>
  )
}

SearchField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  id: PropTypes.string,
}

export default SearchField
