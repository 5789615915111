import { SORT_DIRECTION_ASC, TOGGLE_ALL } from 'constants/form'

import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import ChatHistory from 'components/molecules/ChatHistory'
import ChatSessionTask from 'components/molecules/ChatSessionTask'
import DropdownButton from 'components/molecules/DropdownButton'
import MenuItem from 'components/molecules/MenuItem'
import { Modal } from 'components/molecules/Modal'
import Pagination from 'components/molecules/Pagination'
import SortHeading from 'components/molecules/SortHeading'
import useChatbot from 'hooks/useChatbot'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderLeft = styled.div``
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`
export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

const AllOrganisationsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const ChatbotFlowHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const ChatbotFlowHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 60%;
  }
  &:nth-child(2) {
    width: 12.5%;
  }
  &:nth-child(3) {
    width: 12.5%;
  }
  &:nth-child(4) {
    width: 1.5%;
  }
`

export const StyledBadgeContainer = styled.div`
  opacity: ${({ faded }) => (faded ? 1 : 0.4)};
  cursor: ${({ faded }) => (faded ? 'pointer' : null)};
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export const ChatSessionList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const EntryModal = styled(Modal)`
  width: 700px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Chatbot({
  organisations,
  getRedFlagChatsProgress,
  getOrgsProgress,
  actions,
  redFlagChats,
  chatHistory,
}) {
  const {
    filteredEntities: filteredOrganisations,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(
    organisations,
    'organisation',
    !getOrgsProgress.inProgress,
  )

  const [isChatHistoryModalOpen, setIsChatHistoryModalOpen] = useState(false)
  const [chatbotModalUuid, setChatbotModalUuid] = useState(null)

  const handleOpenChatHistoryModal = useCallback((uuid) => {
    setChatbotModalUuid(uuid)
    setIsChatHistoryModalOpen(true)
  }, [])

  const handleCloseChatHistoryModal = useCallback(() => {
    setIsChatHistoryModalOpen(false)
    setChatbotModalUuid(null)
  }, [])

  const {
    searchFilter,
    // sortOptions,
    handleChangeSearchFilter,
    pagination,
    handleChangePagination,
    viewingChatHistory,
  } = useChatbot({
    filters: { key: 'orgs', value: filteredOrganisations },
    sortOptions: { createdAt: 'DESC' },
    onFetchChatbot: actions.getRedFlagChats,
    chatbotModalUuid,
    onViewChatHistory: actions.getSessionChatHistory,
    chatHistory,
  })

  return (
    <ChnnlDashboardLayout>
      <EntryModal
        open={isChatHistoryModalOpen}
        onClose={handleCloseChatHistoryModal}>
        {!viewingChatHistory ? (
          <ProgressLoader fullWidth />
        ) : (
          <ChatHistory messages={viewingChatHistory} />
        )}
      </EntryModal>

      <ContentContainer>
        <StyledHeader>
          <HeaderLeft>
            <DropdownButton
              name="organisations"
              value={filteredOrganisations}
              onChange={handleChangeFilteredEntities}
              renderValue={renderFilterLabel}>
              <MenuItem value={TOGGLE_ALL}>
                <Checkbox
                  color="primary"
                  checked={
                    filteredOrganisations.length === organisations.length
                  }
                />
                <AllOrganisationsLabel primary="All organisations" />
              </MenuItem>
              {organisations.map((organisation) => (
                <MenuItem key={organisation.uuid} value={organisation.uuid}>
                  <Checkbox
                    color="primary"
                    checked={filteredOrganisations.includes(organisation.uuid)}
                  />
                  <ListItemText primary={organisation.name} />
                </MenuItem>
              ))}
            </DropdownButton>
          </HeaderLeft>
          <HeaderRight>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              name="searchFilter"
              onChange={handleChangeSearchFilter}
            />
          </HeaderRight>
        </StyledHeader>

        <ChatbotFlowHeader>
          <ChatbotFlowHeaderItem>
            <SortHeading>Chatbot entry</SortHeading>
          </ChatbotFlowHeaderItem>
          <ChatbotFlowHeaderItem>
            <SortHeading>
              <strong>Org</strong> / Group
            </SortHeading>
          </ChatbotFlowHeaderItem>
          <ChatbotFlowHeaderItem>
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Started</strong> / Finished
            </SortHeading>
          </ChatbotFlowHeaderItem>
          <ChatbotFlowHeaderItem />
        </ChatbotFlowHeader>

        {getRedFlagChatsProgress.inProgress &&
        (!redFlagChats || redFlagChats.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <React.Fragment>
            <ChatSessionList>
              {redFlagChats.map((chatSession, index) => (
                <ChatSessionTask
                  key={chatSession.uuid}
                  dataQa={`chatsession-entry-${index}`}
                  entry={`lastStep: ${chatSession.lastStep},  nextStep: ${chatSession.nextStep}`}
                  organisationName={
                    chatSession.organisation
                      ? chatSession.organisation.name
                      : 'No organisation'
                  }
                  groupName={
                    chatSession.group ? chatSession.group.name : 'No group'
                  }
                  createdAt={chatSession.createdAt}
                  updatedAt={chatSession.updatedAt}
                  value={chatSession.uuid}
                  onClick={handleOpenChatHistoryModal}
                  name="markedToApprove"
                />
              ))}
            </ChatSessionList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </React.Fragment>
        )}
      </ContentContainer>
    </ChnnlDashboardLayout>
  )
}

Chatbot.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  getRedFlagChatsProgress: PropTypes.object,
  getOrgsProgress: PropTypes.object,
  actions: PropTypes.object,
  redFlagChats: PropTypes.arrayOf(PropTypes.object),
  chatHistory: PropTypes.arrayOf(PropTypes.object),
}

Chatbot.defaultProps = {
  organisations: [],
  getRedFlagChatsProgress: {},
  getOrgsProgress: {},
  actions: {},
  redFlagChats: [],
  chatHistory: [],
}

export default Chatbot
