import React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
function Calm() {
  return (
    <svg width="25px" height="25px" viewBox="0 0 90.37 56.05">
      <g data-name="Layer 2">
        <g data-name="WHO-5 categories">
          <g>
            <path
              d="M43.92 0a24.58 24.58 0 00-24.13 20.72 16.14 16.14 0 00-2.67-.35A17.14 17.14 0 000 37.48c0 9.44 7.5 18.57 17.12 18.57h56.12c9.62 0 17.13-9.13 17.13-18.57a17.15 17.15 0 00-17.13-17.11 16.84 16.84 0 00-5.08 1.08A24.59 24.59 0 0043.92 0zm0 3.13a21.56 21.56 0 0121.55 20.22 1.56 1.56 0 001.65 1.46 1.43 1.43 0 00.54-.13 14 14 0 0119.59 12.8c0 7.75-6.44 15.46-14 15.46H17.12c-7.57 0-14-7.71-14-15.46A13.94 13.94 0 0120.5 23.93a1.55 1.55 0 001.9-1.12 2 2 0 00.05-.24A21.56 21.56 0 0143.92 3.13z"
              fill="#aad5b7"
              fillRule="evenodd"
            />
            <path
              d="M44 6.35a19.26 19.26 0 00-19.15 17.26 1.56 1.56 0 003.07.55 1 1 0 000-.24A16.09 16.09 0 0144 9.48a1.57 1.57 0 000-3.13zM8.56 29.48a1.58 1.58 0 00-1.26.75 13.54 13.54 0 00-2.08 7.23 13.27 13.27 0 0013.37 13.36 1.57 1.57 0 10.07-3.13h-.07A10 10 0 018.34 37.46a10.45 10.45 0 011.6-5.57 1.56 1.56 0 00-1.38-2.41z"
              fillRule="evenodd"
              fill="#5c8368"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default React.memo(Calm)
