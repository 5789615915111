import { HumanReadableDatetime, HumanReadableDate } from 'constants/date'

import MaterialTableCell, { TableCellProps } from '@material-ui/core/TableCell'
import { TableRow } from '@weareroam/cake-ui-v1'
import { HeadingLabel } from 'components/molecules/SortHeading/SortHeading'
import { format } from 'date-fns'
import { DistressedUserLine } from 'models/DistressedUserLine'
import React from 'react'
import styled from 'styled-components'

const StyledTableRow = styled(TableRow)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  cursor: pointer;

  td:first-child {
    text-align: left;
    padding-left: 1rem;
  }

  td {
    margin-bottom: 10rem;
  }
`

const TableData = ({
  children,
  align = 'right',
  ...rest
}: { children: React.ReactNode } & TableCellProps) => (
  <MaterialTableCell component="td" align={align} {...rest}>
    <HeadingLabel variant="body2">{children}</HeadingLabel>
  </MaterialTableCell>
)

export const DistressedUserRow = (
  props: DistressedUserLine & { key: number; onClick?: () => void },
): React.ReactElement => (
  <StyledTableRow key={props.key} onClick={props.onClick}>
    <TableData align="left" scope="row">
      {props.userFingerprint.slice(0, 3)}-{props.userFingerprint.slice(3, 7)}
    </TableData>
    <TableData>{Math.round(props.averageScore)}</TableData>
    <TableData>{props.countOfDistressedSurveys}</TableData>
    <MaterialTableCell component="td" align="right">
      <HeadingLabel variant="body2">
        <strong>{props.group.organisation.name}</strong>
      </HeadingLabel>
      <HeadingLabel variant="body2">{props.group.name}</HeadingLabel>
    </MaterialTableCell>
    <TableData>
      {format(props.mostRecentDistressedSurveyDate, HumanReadableDate)}
    </TableData>
    <TableData>
      {props.messages.length
        ? format(
            props.messages.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            )[0].createdAt,
            HumanReadableDatetime,
          )
        : 'No messages sent'}
    </TableData>
    <TableData>{props.messages.length}</TableData>
  </StyledTableRow>
)
