import initialState from 'reducers/initialState'

import { API_GET_COUNTRIES_SUCCESS } from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function countriesReducer(
  state = initialState.countries,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.countries,
        },
      }
    default:
      return state
  }
}
