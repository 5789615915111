import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { boosterListSchema } from './schema'

export const API_GET_ORGANISATION_BOOSTERS_REQUEST =
  'API_GET_ORGANISATION_BOOSTERS_REQUEST'
export const API_GET_ORGANISATION_BOOSTERS_SUCCESS =
  'API_GET_ORGANISATION_BOOSTERS_SUCCESS'
export const API_GET_ORGANISATION_BOOSTERS_ERROR =
  'API_GET_ORGANISATION_BOOSTERS_ERROR'

export const API_GET_GROUP_BOOSTERS_REQUEST = 'API_GET_GROUP_BOOSTERS_REQUEST'
export const API_GET_GROUP_BOOSTERS_SUCCESS = 'API_GET_GROUP_BOOSTERS_SUCCESS'
export const API_GET_GROUP_BOOSTERS_ERROR = 'API_GET_GROUP_BOOSTERS_ERROR'

export const API_UPDATE_ORGANISATION_BOOSTERS_REQUEST =
  'API_UPDATE_ORGANISATION_BOOSTERS_REQUEST'
export const API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS =
  'API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS'
export const API_UPDATE_ORGANISATION_BOOSTERS_ERROR =
  'API_UPDATE_ORGANISATION_BOOSTERS_ERROR'

export const API_UPDATE_GROUP_BOOSTERS_REQUEST =
  'API_UPDATE_GROUP_BOOSTERS_REQUEST'
export const API_UPDATE_GROUP_BOOSTERS_SUCCESS =
  'API_UPDATE_GROUP_BOOSTERS_SUCCESS'
export const API_UPDATE_GROUP_BOOSTERS_ERROR = 'API_UPDATE_GROUP_BOOSTERS_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOrganisationBoosters(orgUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORGANISATION_BOOSTERS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/boosters`,
      )
      const data = normalize(
        // append the orgUuid to the booster
        response.data.data.map((booster) => ({ ...booster, orgUuid })),
        boosterListSchema,
      )

      dispatch({
        type: API_GET_ORGANISATION_BOOSTERS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_ORGANISATION_BOOSTERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the resources for this organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateOrganisationBoosters({ orgUuid, boosters }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_ORGANISATION_BOOSTERS_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/boosters`,
        {
          boosters,
        },
      )
      const data = normalize(
        // append the orgUuid to the booster
        response.data.data.map((booster) => ({ ...booster, orgUuid })),
        boosterListSchema,
      )
      dispatch({
        type: API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Resources were updated.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_ORGANISATION_BOOSTERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error updating the resources for this organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateGroupBoosters({ orgUuid, groupUuid, boosters }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_GROUP_BOOSTERS_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/boosters`,
        {
          boosters,
        },
      )
      const data = normalize(response.data.data, boosterListSchema)

      dispatch({
        type: API_UPDATE_GROUP_BOOSTERS_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Resources were updated.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_GROUP_BOOSTERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error updating the resources for this group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getGroupBoosters({ orgUuid, groupUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUP_BOOSTERS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/boosters`,
      )
      const data = normalize(
        // append the orgUuid and groupUuid to the booster
        response.data.data.map((booster) => ({
          ...booster,
          orgUuid,
          groupUuid,
        })),
        boosterListSchema,
      )

      dispatch({
        type: API_GET_GROUP_BOOSTERS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_GROUP_BOOSTERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the resources for this group. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
