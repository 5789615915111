import {
  endOfDay,
  endOfMonth,
  endOfToday,
  format,
  startOfDay,
  startOfMonth,
  startOfToday,
  subDays,
  subMonths,
} from 'date-fns'

import { API_ISO_DATE_FORMAT, HumanReadableDate } from '../constants/date'

export type Period = 'last-thirty' | 'last-month' | 'this-month' | 'custom'

export interface PeriodBoundaries {
  start: string
  startDateTime: Date
  end: string
  endDateTime: Date
}

export const getCurrentDate = (): string => new Date().toISOString()

export const formatPeriod = (from: Date | string, to: Date | string): string =>
  `${format(from, HumanReadableDate)} - ${format(to, HumanReadableDate)}`

export const getPeriodFromDates = (
  start: string,
  end: string,
): PeriodBoundaries => ({
  start: start,
  startDateTime: startOfDay(start),
  end: end,
  endDateTime: endOfDay(end),
})

export const getPeriodFromName = (period: Period): PeriodBoundaries => {
  if (period === 'last-thirty') {
    return {
      startDateTime: subDays(startOfToday(), 29),
      endDateTime: endOfToday(),
      start: format(subDays(startOfToday(), 29), API_ISO_DATE_FORMAT),
      end: format(endOfToday(), API_ISO_DATE_FORMAT),
    }
  } else if (period === 'this-month') {
    return {
      startDateTime: startOfMonth(new Date()),
      endDateTime: endOfMonth(new Date()),
      start: format(startOfMonth(new Date()), API_ISO_DATE_FORMAT),
      end: format(endOfMonth(new Date()), API_ISO_DATE_FORMAT),
    }
  } else if (period === 'last-month') {
    return {
      startDateTime: subMonths(startOfMonth(new Date()), 1),
      endDateTime: subMonths(endOfMonth(new Date()), 1),
      start: format(
        subMonths(startOfMonth(new Date()), 1),
        API_ISO_DATE_FORMAT,
      ),
      end: format(subMonths(endOfMonth(new Date()), 1), API_ISO_DATE_FORMAT),
    }
  } else {
    // Shouldn't ever get here, but just in case.
    return {
      start: format(startOfToday(), API_ISO_DATE_FORMAT),
      startDateTime: startOfToday(),
      end: format(endOfToday(), API_ISO_DATE_FORMAT),
      endDateTime: endOfToday(),
    }
  }
}

export default getCurrentDate
