import {
  API_GET_ORG_ADMINS_REQUEST,
  API_GET_ORG_ADMINS_SUCCESS,
  API_GET_ORG_ADMINS_ERROR,
} from 'modules/admins/actions'
import {
  API_REFRESH_SESSION_REQUEST,
  API_REFRESH_SESSION_SUCCESS,
  API_REFRESH_SESSION_ERROR,
} from 'modules/authentication/actions'
import {
  API_GET_ORGANISATION_BOOSTERS_REQUEST,
  API_GET_ORGANISATION_BOOSTERS_SUCCESS,
  API_GET_ORGANISATION_BOOSTERS_ERROR,
  API_UPDATE_ORGANISATION_BOOSTERS_REQUEST,
  API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS,
  API_UPDATE_ORGANISATION_BOOSTERS_ERROR,
  API_GET_GROUP_BOOSTERS_REQUEST,
  API_GET_GROUP_BOOSTERS_SUCCESS,
  API_GET_GROUP_BOOSTERS_ERROR,
  API_UPDATE_GROUP_BOOSTERS_REQUEST,
  API_UPDATE_GROUP_BOOSTERS_SUCCESS,
  API_UPDATE_GROUP_BOOSTERS_ERROR,
} from 'modules/boosters/actions'
import {
  API_GET_REDFLAGCHAT_REQUEST,
  API_GET_REDFLAGCHAT_SUCCESS,
  API_GET_REDFLAGCHAT_ERROR,
} from 'modules/chatbot/actions'
import {
  API_GET_DAY_PARAMETERS_REQUEST,
  API_GET_DAY_PARAMETERS_SUCCESS,
  API_GET_DAY_PARAMETERS_ERROR,
} from 'modules/dayParameters/actions'
import {
  API_GET_ENTITLEMENTS_REQUEST,
  API_GET_ENTITLEMENTS_SUCCESS,
  API_GET_ENTITLEMENTS_ERROR,
} from 'modules/entitlements/actions'
import {
  API_GET_GROUPS_REQUEST,
  API_GET_GROUPS_SUCCESS,
  API_GET_GROUPS_ERROR,
  API_DELETE_GROUP_REQUEST,
  API_DELETE_GROUP_SUCCESS,
  API_DELETE_GROUP_ERROR,
  API_GET_GROUP_MEMBERS_REQUEST,
  API_GET_GROUP_MEMBERS_SUCCESS,
  API_GET_GROUP_MEMBERS_ERROR,
  API_INVITE_MEMBERS_TO_GROUP_RESET,
  API_INVITE_MEMBERS_TO_GROUP_REQUEST,
  API_INVITE_MEMBERS_TO_GROUP_SUCCESS,
  API_INVITE_MEMBERS_TO_GROUP_ERROR,
  API_RESEND_MEMBER_INVITATION_REQUEST,
  API_RESEND_MEMBER_INVITATION_SUCCESS,
  API_RESEND_MEMBER_INVITATION_ERROR,
  API_RESEND_MULTI_MEMBER_INVITATION_REQUEST,
  API_RESEND_MULTI_MEMBER_INVITATION_SUCCESS,
  API_RESEND_MULTI_MEMBER_INVITATION_ERROR,
  API_MOVE_MEMBERS_REQUEST,
  API_MOVE_MEMBERS_SUCCESS,
  API_MOVE_MEMBERS_ERROR,
} from 'modules/groups/actions'
import {
  API_GET_GROUP_SIGNUP_LINK,
  API_GET_GROUP_SIGNUP_LINK_SUCCESS,
  API_GET_GROUP_SIGNUP_LINK_ERROR,
  API_CREATE_GROUP_SIGNUP_LINK,
  API_CREATE_GROUP_SIGNUP_LINK_SUCCESS,
  API_CREATE_GROUP_SIGNUP_LINK_ERROR,
  API_ARCHIVE_GROUP_SIGNUP_LINK,
  API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS,
  API_ARCHIVE_GROUP_SIGNUP_LINK_ERROR,
} from 'modules/groupSignUpLink/actions'
import {
  API_GET_JOURNALS_REQUEST,
  API_GET_JOURNALS_SUCCESS,
  API_GET_JOURNALS_ERROR,
  API_GET_JOURNAL_REPLY_REQUEST,
  API_GET_JOURNAL_REPLY_SUCCESS,
  API_GET_JOURNAL_REPLY_ERROR,
} from 'modules/journals/actions'
import {
  API_GET_KEY_CONTACTS_REQUEST,
  API_GET_KEY_CONTACTS_SUCCESS,
  API_GET_KEY_CONTACTS_ERROR,
} from 'modules/keyContacts/actions'
import {
  API_GET_ORGANISATIONS_REQUEST,
  API_GET_ORGANISATIONS_SUCCESS,
  API_GET_ORGANISATIONS_ERROR,
  API_GET_ORGANISATION_REQUEST,
  API_GET_ORGANISATION_SUCCESS,
  API_GET_ORGANISATION_ERROR,
  API_UPLOAD_ORGANISATION_LOGO_REQUEST,
  API_UPLOAD_ORGANISATION_LOGO_SUCCESS,
  API_UPLOAD_ORGANISATION_LOGO_ERROR,
  API_UPDATE_ORGANISATION_REQUEST,
  API_UPDATE_ORGANISATION_SUCCESS,
  API_UPDATE_ORGANISATION_ERROR,
} from 'modules/organisations/actions'
import {
  API_GET_ORG_COUNTRIES_REQUEST,
  API_GET_ORG_COUNTRIES_SUCCESS,
  API_GET_ORG_COUNTRIES_ERROR,
} from 'modules/orgCountries/actions'
import {
  API_GET_PARTNER_ORGS_REQUEST,
  API_GET_PARTNER_ORGS_SUCCESS,
  API_GET_PARTNER_ORGS_ERROR,
} from 'modules/partnerOrganisations/actions'
import {
  API_GET_PARTNERS_REQUEST,
  API_GET_PARTNERS_SUCCESS,
  API_GET_PARTNERS_ERROR,
  API_GET_PARTNER_REQUEST,
  API_GET_PARTNER_SUCCESS,
  API_GET_PARTNER_ERROR,
} from 'modules/partners/actions'
import {
  API_GET_PATHWAYS_REQUEST,
  API_GET_PATHWAYS_SUCCESS,
  API_GET_PATHWAYS_ERROR,
} from 'modules/pathways/actions'
import {
  API_GET_REFLECTIONS_REQUEST,
  API_GET_REFLECTIONS_SUCCESS,
  API_GET_REFLECTIONS_ERROR,
  API_ADD_ORGANISATION_REFLECTION_REQUEST,
  API_ADD_ORGANISATION_REFLECTION_SUCCESS,
  API_ADD_ORGANISATION_REFLECTION_ERROR,
  API_CREATE_REFLECTION_REQUEST,
  API_CREATE_REFLECTION_SUCCESS,
  API_CREATE_REFLECTION_ERROR,
  API_UPDATE_REFLECTION_REQUEST,
  API_UPDATE_REFLECTION_SUCCESS,
  API_UPDATE_REFLECTION_ERROR,
  API_DELETE_REFLECTION_REQUEST,
  API_DELETE_REFLECTION_SUCCESS,
  API_DELETE_REFLECTION_ERROR,
} from 'modules/reflections/actions'
import {
  API_GET_BRANCH_DATA,
  API_GET_BRANCH_DATA_SUCCESS,
  API_GET_BRANCH_DATA_ERROR,
  API_SEND_SMS,
  API_SEND_SMS_SUCCESS,
  API_SEND_SMS_ERROR,
} from 'modules/selfSignUp/actions'
import {
  API_GET_INCIDENTS_REQUEST,
  API_GET_INCIDENTS_SUCCESS,
  API_GET_INCIDENTS_ERROR,
} from 'modules/speakUp/actions'
import {
  API_GET_TIPS_REQUEST,
  API_GET_TIPS_SUCCESS,
  API_GET_TIPS_ERROR,
} from 'modules/tips/actions'
import initialState from 'reducers/initialState'

import {
  API_UPDATE_PATHWAY_REQUEST,
  API_UPDATE_PATHWAY_SUCCESS,
  API_UPDATE_PATHWAY_ERROR,
} from './pathways/actions'

export interface RequestProgressState {
  inProgress: boolean
  isComplete: boolean
  hasError: boolean
  success: boolean
  initialLoad?: boolean
  error?: Error
}

export const requestProgressState: RequestProgressState = {
  inProgress: true,
  isComplete: false,
  hasError: false,
  success: false,
}

export const successProgressState: RequestProgressState = {
  inProgress: false,
  isComplete: true,
  hasError: false,
  success: true,
  initialLoad: true,
}

export const errorProgressState: RequestProgressState = {
  inProgress: false,
  isComplete: true,
  hasError: true,
  success: false,
  error: new Error('Unhandled error'),
  initialLoad: true,
}

export const initialProgressState: RequestProgressState = {
  inProgress: false,
  isComplete: false,
  hasError: false,
  success: false,
  initialLoad: false,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function progressReducer(
  state = initialState.progress,
  action: { type: string },
) {
  switch (action.type) {
    case API_DELETE_GROUP_REQUEST:
      return {
        ...state,
        deleteGroup: {
          ...state.deleteGroup,
          ...requestProgressState,
        },
      }
    case API_DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteGroup: {
          ...successProgressState,
        },
      }
    case API_DELETE_GROUP_ERROR:
      return {
        ...state,
        deleteGroup: {
          ...errorProgressState,
        },
      }
    case API_REFRESH_SESSION_REQUEST:
      return {
        ...state,
        refreshSession: {
          ...state.refreshSession,
          ...requestProgressState,
        },
      }
    case API_REFRESH_SESSION_SUCCESS:
      return {
        ...state,
        refreshSession: {
          ...successProgressState,
        },
      }
    case API_REFRESH_SESSION_ERROR:
      return {
        ...state,
        refreshSession: {
          ...errorProgressState,
        },
      }
    case API_GET_JOURNALS_REQUEST:
      return {
        ...state,
        getJournals: {
          ...state.getJournals,
          ...requestProgressState,
        },
      }
    case API_GET_JOURNALS_SUCCESS:
      return {
        ...state,
        getJournals: {
          ...successProgressState,
        },
      }
    case API_GET_JOURNALS_ERROR:
      return {
        ...state,
        getJournals: {
          ...errorProgressState,
        },
      }
    case API_GET_JOURNAL_REPLY_REQUEST:
      return {
        ...state,
        getJournalReply: {
          ...state.getJournalReply,
          ...requestProgressState,
        },
      }
    case API_GET_JOURNAL_REPLY_SUCCESS:
      return {
        ...state,
        getJournalReply: {
          ...successProgressState,
        },
      }
    case API_GET_JOURNAL_REPLY_ERROR:
      return {
        ...state,
        getJournalReply: {
          ...errorProgressState,
        },
      }
    case API_GET_INCIDENTS_REQUEST:
      return {
        ...state,
        getIncidents: {
          ...state.getIncidents,
          ...requestProgressState,
        },
      }
    case API_GET_INCIDENTS_SUCCESS:
      return {
        ...state,
        getIncidents: {
          ...successProgressState,
        },
      }
    case API_GET_INCIDENTS_ERROR:
      return {
        ...state,
        getIncidents: {
          ...errorProgressState,
        },
      }
    case API_GET_KEY_CONTACTS_REQUEST:
      return {
        ...state,
        getKeyContacts: {
          ...state.getKeyContacts,
          ...requestProgressState,
        },
      }
    case API_GET_KEY_CONTACTS_SUCCESS:
      return {
        ...state,
        getKeyContacts: {
          ...successProgressState,
        },
      }
    case API_GET_KEY_CONTACTS_ERROR:
      return {
        ...state,
        getKeyContacts: {
          ...errorProgressState,
        },
      }
    case API_GET_DAY_PARAMETERS_REQUEST:
      return {
        ...state,
        getDayParameters: {
          ...state.getDayParameters,
          ...requestProgressState,
        },
      }
    case API_GET_DAY_PARAMETERS_SUCCESS:
      return {
        ...state,
        getDayParameters: {
          ...successProgressState,
        },
      }
    case API_GET_DAY_PARAMETERS_ERROR:
      return {
        ...state,
        getDayParameters: {
          ...errorProgressState,
        },
      }
    case API_GET_REFLECTIONS_REQUEST:
      return {
        ...state,
        getReflections: {
          ...state.getReflections,
          ...requestProgressState,
        },
      }
    case API_GET_REFLECTIONS_SUCCESS:
      return {
        ...state,
        getReflections: {
          ...successProgressState,
        },
      }
    case API_GET_REFLECTIONS_ERROR:
      return {
        ...state,
        getReflections: {
          ...errorProgressState,
        },
      }
    case API_ADD_ORGANISATION_REFLECTION_REQUEST:
      return {
        ...state,
        updateReflections: {
          ...state.updateReflections,
          ...requestProgressState,
        },
      }
    case API_ADD_ORGANISATION_REFLECTION_SUCCESS:
      return {
        ...state,
        updateReflections: {
          ...successProgressState,
        },
      }
    case API_ADD_ORGANISATION_REFLECTION_ERROR:
      return {
        ...state,
        updateReflections: {
          ...errorProgressState,
        },
      }
    case API_CREATE_REFLECTION_REQUEST:
      return {
        ...state,
        createReflection: {
          ...state.updateReflections,
          ...requestProgressState,
        },
      }
    case API_CREATE_REFLECTION_SUCCESS:
      return {
        ...state,
        createReflection: {
          ...successProgressState,
        },
      }
    case API_CREATE_REFLECTION_ERROR:
      return {
        ...state,
        createReflection: {
          ...errorProgressState,
        },
      }
    case API_UPDATE_REFLECTION_REQUEST:
      return {
        ...state,
        updateReflection: {
          ...state.updateReflections,
          ...requestProgressState,
        },
      }
    case API_UPDATE_REFLECTION_SUCCESS:
      return {
        ...state,
        updateReflection: {
          ...successProgressState,
        },
      }
    case API_UPDATE_REFLECTION_ERROR:
      return {
        ...state,
        updateReflection: {
          ...errorProgressState,
        },
      }
    case API_DELETE_REFLECTION_REQUEST:
      return {
        ...state,
        deleteReflection: {
          ...state.updateReflections,
          ...requestProgressState,
        },
      }
    case API_DELETE_REFLECTION_SUCCESS:
      return {
        ...state,
        deleteReflection: {
          ...successProgressState,
        },
      }
    case API_DELETE_REFLECTION_ERROR:
      return {
        ...state,
        deleteReflection: {
          ...errorProgressState,
        },
      }
    case API_GET_PATHWAYS_REQUEST:
      return {
        ...state,
        getPathways: {
          ...state.getPathways,
          ...requestProgressState,
        },
      }
    case API_UPDATE_PATHWAY_REQUEST:
      return {
        ...state,
        updatePathways: {
          ...state.updatePathways,
          ...requestProgressState,
        },
      }
    case API_GET_PATHWAYS_SUCCESS:
      return {
        ...state,
        getPathways: {
          ...successProgressState,
        },
      }
    case API_UPDATE_PATHWAY_SUCCESS:
      return {
        ...state,
        updatePathways: {
          ...successProgressState,
        },
      }
    case API_GET_PATHWAYS_ERROR:
      return {
        ...state,
        getPathways: {
          ...errorProgressState,
        },
      }
    case API_UPDATE_PATHWAY_ERROR:
      return {
        ...state,
        updatePathways: {
          ...errorProgressState,
        },
      }
    case API_GET_GROUP_BOOSTERS_REQUEST:
      return {
        ...state,
        getGroupBoosters: {
          // will come back for these... not introduced or related
          // eslint-disable-next-line
          // @ts-ignore
          ...state.getGroupBoosters,
          ...requestProgressState,
        },
      }
    case API_GET_GROUP_BOOSTERS_SUCCESS:
      return {
        ...state,
        getGroupBoosters: {
          ...successProgressState,
        },
      }
    case API_GET_GROUP_BOOSTERS_ERROR:
      return {
        ...state,
        getGroupBoosters: {
          ...errorProgressState,
        },
      }
    case API_GET_ORGANISATION_BOOSTERS_REQUEST:
      return {
        ...state,
        getOrganisationBoosters: {
          ...state.getOrganisationBoosters,
          ...requestProgressState,
        },
      }
    case API_GET_ORGANISATION_BOOSTERS_SUCCESS:
      return {
        ...state,
        getOrganisationBoosters: {
          ...successProgressState,
        },
      }
    case API_GET_ORGANISATION_BOOSTERS_ERROR:
      return {
        ...state,
        getOrganisationBoosters: {
          ...errorProgressState,
        },
      }
    case API_UPDATE_ORGANISATION_BOOSTERS_REQUEST:
      return {
        ...state,
        updateOrganisationBoosters: {
          ...state.updateOrganisationBoosters,
          ...requestProgressState,
        },
      }
    case API_UPDATE_ORGANISATION_BOOSTERS_SUCCESS:
      return {
        ...state,
        updateOrganisationBoosters: {
          ...state.updateOrganisationBoosters,
          ...successProgressState,
        },
      }
    case API_UPDATE_ORGANISATION_BOOSTERS_ERROR:
      return {
        ...state,
        updateOrganisationBoosters: {
          ...state.updateOrganisationBoosters,
          ...errorProgressState,
        },
      }
    case API_UPDATE_GROUP_BOOSTERS_REQUEST:
      return {
        ...state,
        updateGroupBoosters: {
          ...state.updateOrganisationBoosters,
          ...requestProgressState,
        },
      }
    case API_UPDATE_GROUP_BOOSTERS_SUCCESS:
      return {
        ...state,
        updateGroupBoosters: {
          ...state.updateOrganisationBoosters,
          ...successProgressState,
        },
      }
    case API_UPDATE_GROUP_BOOSTERS_ERROR:
      return {
        ...state,
        updateGroupBoosters: {
          ...state.updateOrganisationBoosters,
          ...errorProgressState,
        },
      }
    case API_GET_ORG_ADMINS_REQUEST:
      return {
        ...state,
        getOrganisationAdmins: {
          ...state.getOrganisationAdmins,
          ...requestProgressState,
        },
      }
    case API_GET_ORG_ADMINS_SUCCESS:
      return {
        ...state,
        getOrganisationAdmins: {
          ...successProgressState,
        },
      }
    case API_GET_ORG_ADMINS_ERROR:
      return {
        ...state,
        getOrganisationAdmins: {
          ...errorProgressState,
        },
      }
    case API_GET_ORG_COUNTRIES_REQUEST:
      return {
        ...state,
        getOrgCountries: {
          ...state.getOrgCountries,
          ...requestProgressState,
        },
      }
    case API_GET_ORG_COUNTRIES_SUCCESS:
      return {
        ...state,
        getOrgCountries: {
          ...successProgressState,
        },
      }
    case API_GET_ORG_COUNTRIES_ERROR:
      return {
        ...state,
        getOrgCountries: {
          ...errorProgressState,
        },
      }
    case API_UPLOAD_ORGANISATION_LOGO_REQUEST:
      return {
        ...state,
        uploadOrganisationLogo: {
          ...state.uploadOrganisationLogo,
          ...requestProgressState,
        },
      }
    case API_UPLOAD_ORGANISATION_LOGO_SUCCESS:
      return {
        ...state,
        uploadOrganisationLogo: {
          ...successProgressState,
        },
      }
    case API_UPLOAD_ORGANISATION_LOGO_ERROR:
      return {
        ...state,
        uploadOrganisationLogo: {
          ...errorProgressState,
        },
      }
    case API_UPDATE_ORGANISATION_REQUEST:
      return {
        ...state,
        updateOrganisation: {
          ...state.updateOrganisation,
          ...requestProgressState,
        },
      }
    case API_UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        updateOrganisation: {
          ...successProgressState,
        },
      }
    case API_UPDATE_ORGANISATION_ERROR:
      return {
        ...state,
        updateOrganisation: {
          ...errorProgressState,
        },
      }
    case API_GET_GROUPS_REQUEST:
      return {
        ...state,
        getGroups: {
          ...state.getGroups,
          ...requestProgressState,
        },
        inviteGroupMembers: {
          ...state.inviteGroupMembers,
          ...initialProgressState,
        },
      }
    case API_GET_GROUPS_SUCCESS:
      return {
        ...state,
        getGroups: {
          ...successProgressState,
        },
      }
    case API_GET_GROUPS_ERROR:
      return {
        ...state,
        getGroups: {
          ...errorProgressState,
        },
      }
    case API_GET_GROUP_MEMBERS_REQUEST:
      return {
        ...state,
        getGroupMembers: {
          ...state.getGroupMembers,
          ...requestProgressState,
        },
      }
    case API_GET_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        getGroupMembers: {
          ...state.getGroupMembers,
          ...successProgressState,
        },
      }
    case API_GET_GROUP_MEMBERS_ERROR:
      return {
        ...state,
        getGroupMembers: {
          ...state.getGroupMembers,
          ...errorProgressState,
        },
      }
    case API_INVITE_MEMBERS_TO_GROUP_RESET:
      return {
        ...state,
        inviteGroupMembers: {
          ...state.inviteGroupMembers,
          ...initialProgressState,
        },
      }
    case API_INVITE_MEMBERS_TO_GROUP_REQUEST:
      return {
        ...state,
        inviteGroupMembers: {
          ...state.inviteGroupMembers,
          ...requestProgressState,
        },
      }
    case API_INVITE_MEMBERS_TO_GROUP_SUCCESS:
      return {
        ...state,
        inviteGroupMembers: {
          ...state.inviteGroupMembers,
          ...successProgressState,
        },
      }
    case API_INVITE_MEMBERS_TO_GROUP_ERROR:
      return {
        ...state,
        inviteGroupMembers: {
          ...state.inviteGroupMembers,
          ...errorProgressState,
          // eslint-disable-next-line
          // @ts-ignore
          error: action.error,
        },
      }
    case API_RESEND_MEMBER_INVITATION_REQUEST:
      return {
        ...state,
        reinviteGroupMember: {
          ...state.reinviteGroupMember,
          ...requestProgressState,
        },
      }
    case API_RESEND_MEMBER_INVITATION_SUCCESS:
      return {
        ...state,
        reinviteGroupMember: {
          ...state.reinviteGroupMember,
          ...successProgressState,
        },
      }
    case API_RESEND_MEMBER_INVITATION_ERROR:
      return {
        ...state,
        reinviteGroupMember: {
          ...state.reinviteGroupMember,
          ...errorProgressState,
        },
      }
    case API_RESEND_MULTI_MEMBER_INVITATION_REQUEST:
      return {
        ...state,
        resendMultipleMemberInvites: {
          ...state.resendMultipleMemberInvites,
          ...requestProgressState,
        },
      }
    case API_RESEND_MULTI_MEMBER_INVITATION_SUCCESS:
      return {
        ...state,
        resendMultipleMemberInvites: {
          ...state.resendMultipleMemberInvites,
          ...successProgressState,
        },
      }
    case API_RESEND_MULTI_MEMBER_INVITATION_ERROR:
      return {
        ...state,
        resendMultipleMemberInvites: {
          ...state.resendMultipleMemberInvites,
          ...errorProgressState,
        },
      }
    case API_MOVE_MEMBERS_REQUEST:
      return {
        ...state,
        moveMembers: {
          ...state.moveMembers,
          ...requestProgressState,
        },
      }
    case API_MOVE_MEMBERS_SUCCESS:
      return {
        ...state,
        moveMembers: {
          ...state.moveMembers,
          ...successProgressState,
        },
      }
    case API_MOVE_MEMBERS_ERROR:
      return {
        ...state,
        moveMembers: {
          ...state.moveMembers,
          ...errorProgressState,
        },
      }
    case API_GET_GROUP_SIGNUP_LINK:
      return {
        ...state,
        getSignUpLink: {
          ...state.getSignUpLink,
          ...requestProgressState,
        },
      }
    case API_GET_GROUP_SIGNUP_LINK_SUCCESS:
      return {
        ...state,
        getSignUpLink: {
          ...state.getSignUpLink,
          ...successProgressState,
        },
      }
    case API_GET_GROUP_SIGNUP_LINK_ERROR:
      return {
        ...state,
        getSignUpLink: {
          ...state.getSignUpLink,
          ...errorProgressState,
        },
      }
    case API_CREATE_GROUP_SIGNUP_LINK:
      return {
        ...state,
        createSignUpLink: {
          ...state.createSignUpLink,
          ...requestProgressState,
        },
      }
    case API_CREATE_GROUP_SIGNUP_LINK_SUCCESS:
      return {
        ...state,
        createSignUpLink: {
          ...state.createSignUpLink,
          ...successProgressState,
        },
      }
    case API_CREATE_GROUP_SIGNUP_LINK_ERROR:
      return {
        ...state,
        createSignUpLink: {
          ...state.createSignUpLink,
          ...errorProgressState,
        },
      }
    case API_ARCHIVE_GROUP_SIGNUP_LINK:
      return {
        ...state,
        archiveSignUpLink: {
          // eslint-disable-next-line
          // @ts-ignore
          ...state.archiveSignUpLink.requestProgressState,
          ...requestProgressState,
        },
      }
    case API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS:
      return {
        ...state,
        archiveSignUpLink: {
          // eslint-disable-next-line
          // @ts-ignore
          ...state.archiveSignUpLink.requestProgressState,
          ...successProgressState,
        },
      }
    case API_ARCHIVE_GROUP_SIGNUP_LINK_ERROR:
      return {
        ...state,
        archiveSignUpLink: {
          // eslint-disable-next-line
          // @ts-ignore
          ...state.archiveSignUpLink.requestProgressState,
          ...errorProgressState,
        },
      }
    case API_GET_ORGANISATIONS_REQUEST:
      return {
        ...state,
        getOrganisations: {
          ...state.getOrganisations,
          ...requestProgressState,
        },
      }
    case API_GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        getOrganisations: {
          ...successProgressState,
        },
      }
    case API_GET_ORGANISATIONS_ERROR:
      return {
        ...state,
        getOrganisations: {
          ...errorProgressState,
        },
      }
    case API_GET_ORGANISATION_REQUEST:
      return {
        ...state,
        getOrganisation: {
          // eslint-disable-next-line
          // @ts-ignore
          ...state.getOrganisation,
          ...requestProgressState,
        },
      }
    case API_GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        getOrganisation: {
          ...successProgressState,
        },
      }
    case API_GET_ORGANISATION_ERROR:
      return {
        ...state,
        getOrganisation: {
          ...errorProgressState,
        },
      }
    case API_GET_PARTNERS_REQUEST:
      return {
        ...state,
        getPartners: {
          ...state.getPartners,
          ...requestProgressState,
        },
      }
    case API_GET_PARTNERS_SUCCESS:
      return {
        ...state,
        getPartners: {
          ...successProgressState,
        },
      }
    case API_GET_PARTNERS_ERROR:
      return {
        ...state,
        getPartners: {
          ...errorProgressState,
        },
      }
    case API_GET_PARTNER_REQUEST:
      return {
        ...state,
        getPartner: {
          ...state.getPartner,
          ...requestProgressState,
        },
      }
    case API_GET_PARTNER_SUCCESS:
      return {
        ...state,
        getPartner: {
          ...successProgressState,
        },
      }
    case API_GET_PARTNER_ERROR:
      return {
        ...state,
        getPartner: {
          ...errorProgressState,
        },
      }
    case API_GET_PARTNER_ORGS_REQUEST:
      return {
        ...state,
        getPartnerOrganisations: {
          ...state.getPartnerOrganisations,
          ...requestProgressState,
        },
      }
    case API_GET_PARTNER_ORGS_SUCCESS:
      return {
        ...state,
        getPartnerOrganisations: {
          ...successProgressState,
        },
      }
    case API_GET_PARTNER_ORGS_ERROR:
      return {
        ...state,
        getPartnerOrganisations: {
          ...errorProgressState,
        },
      }
    case API_GET_BRANCH_DATA:
      return {
        ...state,
        getBranchData: {
          ...state.getBranchData,
          ...requestProgressState,
        },
      }
    case API_GET_BRANCH_DATA_SUCCESS:
      return {
        ...state,
        getBranchData: {
          ...state.getBranchData,
          ...successProgressState,
        },
      }
    case API_GET_BRANCH_DATA_ERROR:
      return {
        ...state,
        getBranchData: {
          ...state.getBranchData,
          ...errorProgressState,
        },
      }
    case API_SEND_SMS:
      return {
        ...state,
        sendSms: {
          ...state.sendSms,
          ...requestProgressState,
        },
      }
    case API_SEND_SMS_SUCCESS:
      return {
        ...state,
        sendSms: {
          ...state.sendSms,
          ...successProgressState,
        },
      }
    case API_SEND_SMS_ERROR:
      return {
        ...state,
        sendSms: {
          ...state.sendSms,
          ...errorProgressState,
        },
      }
    case API_GET_REDFLAGCHAT_REQUEST:
      return {
        ...state,
        getRedFlagChats: {
          ...state.getRedFlagChats,
          ...requestProgressState,
        },
      }
    case API_GET_REDFLAGCHAT_SUCCESS:
      return {
        ...state,
        getRedFlagChats: {
          ...successProgressState,
        },
      }
    case API_GET_REDFLAGCHAT_ERROR:
      return {
        ...state,
        getRedFlagChats: {
          ...errorProgressState,
        },
      }
    case API_GET_ENTITLEMENTS_REQUEST:
      return {
        ...state,
        getEntitlements: {
          ...state.getEntitlements,
          ...requestProgressState,
        },
      }
    case API_GET_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        getEntitlements: {
          ...successProgressState,
        },
      }
    case API_GET_ENTITLEMENTS_ERROR:
      return {
        ...state,
        getEntitlements: {
          ...errorProgressState,
        },
      }
    case API_GET_TIPS_REQUEST:
      return {
        ...state,
        getTips: {
          ...state.getEntitlements,
          ...requestProgressState,
        },
      }
    case API_GET_TIPS_SUCCESS:
      return {
        ...state,
        getTips: {
          ...successProgressState,
        },
      }
    case API_GET_TIPS_ERROR:
      return {
        ...state,
        getTips: {
          ...errorProgressState,
        },
      }
    default:
      return state
  }
}
