import initialState from 'reducers/initialState'

import {
  API_ADD_ORG_COUNTRY_SUCCESS,
  API_GET_ORG_COUNTRIES_SUCCESS,
  API_DELETE_ORG_COUNTRY_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function orgCountriesReducer(
  state = initialState.orgCountries,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_GET_ORG_COUNTRIES_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.orgCountries,
        },
      }
    case API_ADD_ORG_COUNTRY_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.entities.orgCountry.id]: action.data.entities.orgCountry,
        },
      }
    case API_DELETE_ORG_COUNTRY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newEntities = { ...state.entities }
      delete newEntities[action.data.id]

      return {
        ...state,
        entities: newEntities,
      }
    default:
      return state
  }
}
