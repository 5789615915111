import React, { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AppDownloadRedirect = () => {
  useEffect(() => {
    window.location.assign('https://chnnl.app/app-download')
  })

  return <div />
}

export default AppDownloadRedirect
