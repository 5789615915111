import ChevronLink from 'components/molecules/ChevronLink'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import React from 'react'
import styled from 'styled-components'

export const Menu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin-top: ${({ theme }) => theme.spacing.xl}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Settings() {
  return (
    <ChnnlDashboardLayout>
      <Menu>
        <ChevronLink to="settings/profile" label="Edit profile" />
        <ChevronLink to="settings/password" label="Change password" />
      </Menu>
    </ChnnlDashboardLayout>
  )
}

Settings.propTypes = {}

export default Settings
