import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function JournalShared(props) {
  return (
    <svg width="19px" height="15px" viewBox="0 0 19 15" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="atom-/-journal-checkbox-/-shared"
          transform="translate(-4.000000, -5.000000)"
          fill="#C4C5E3"
          fillRule="nonzero">
          <path
            d="M15.4,19.00625 L15.4,14.9 C10.118,14.9 6.641,16.49375 4,20 C5.064,14.99375 8.009,10.00625 15.4,8.99375 L15.4,5 L23,11.99375 L15.4,19.00625 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  )
}

export default JournalShared
