import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { partnersListSchema } from './schema'

// Actions
export const API_GET_PARTNERS_REQUEST = 'API_GET_PARTNERS_REQUEST'
export const API_GET_PARTNERS_SUCCESS = 'API_GET_PARTNERS_SUCCESS'
export const API_GET_PARTNERS_ERROR = 'API_GET_PARTNERS_ERROR'
export const API_GET_PARTNER_REQUEST = 'API_GET_PARTNER_REQUEST'
export const API_GET_PARTNER_SUCCESS = 'API_GET_PARTNER_SUCCESS'
export const API_GET_PARTNER_ERROR = 'API_GET_PARTNER_ERROR'
export const API_DELETE_PARTNER_REQUEST = 'API_DELETE_PARTNER_REQUEST'
export const API_DELETE_PARTNER_SUCCESS = 'API_DELETE_PARTNER_SUCCESS'
export const API_DELETE_PARTNER_ERROR = 'API_DELETE_PARTNER_ERROR'
export const API_CREATE_PARTNER_REQUEST = 'API_CREATE_PARTNER_REQUEST'
export const API_CREATE_PARTNER_SUCCESS = 'API_CREATE_PARTNER_SUCCESS'
export const API_CREATE_PARTNER_ERROR = 'API_CREATE_PARTNER_ERROR'
export const API_UPDATE_PARTNER_REQUEST = 'API_UPDATE_PARTNER_REQUEST'
export const API_UPDATE_PARTNER_SUCCESS = 'API_UPDATE_PARTNER_SUCCESS'
export const API_UPDATE_PARTNER_ERROR = 'API_UPDATE_PARTNER_ERROR'
export const API_UPLOAD_PARTNER_LOGO_REQUEST = 'API_UPLOAD_PARTNER_LOGO_REQUEST'
export const API_UPLOAD_PARTNER_LOGO_SUCCESS = 'API_UPLOAD_PARTNER_LOGO_SUCCESS'
export const API_UPLOAD_PARTNER_LOGO_ERROR = 'API_UPLOAD_PARTNER_LOGO_ERROR'

export const CHANGE_PARTNER_DETAILS = 'CHANGE_PARTNER_DETAILS'
export const ADD_PARTNER = 'ADD_PARTNER'

// Action creators

export function getPartners() {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_PARTNERS_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/partner/`)
      const data = normalize(response.data.data[0].output, partnersListSchema)

      dispatch({
        type: API_GET_PARTNERS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_PARTNERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error while fetching partners. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPartner(partnerUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_PARTNER_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/partner/${partnerUuid}`,
      )
      const data = normalize(response.data.data[0], partnersListSchema)

      dispatch({
        type: API_GET_PARTNER_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_PARTNER_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error while fetching the partner. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deletePartner(partnerUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_DELETE_PARTNER_REQUEST })
    const state = getState()
    const partner = state.partners.entities[partnerUuid]

    try {
      await axios.delete(`${SAUCE_API_URL}/partner/${partnerUuid}`)

      dispatch({
        type: API_DELETE_PARTNER_SUCCESS,
        data: {
          uuid: partnerUuid,
        },
      })

      dispatch(
        addToast({
          message: `'${partner.name}' partner was successfully deleted.`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_PARTNER_ERROR, error })
      dispatch(
        addToast({
          message: `'${partner.name}' partner was unable to be deleted.`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addPartner(partner) {
  return {
    type: ADD_PARTNER,
    data: {
      partner,
    },
  }
}

export function createPartner({ title }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_PARTNER_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/partner/`, { title })

      const data = normalize([response.data.data[0].output], partnersListSchema)

      dispatch({
        type: API_CREATE_PARTNER_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Partner has been successfully created!',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
      dispatch(addPartner(data))
    } catch (error) {
      dispatch({ type: API_CREATE_PARTNER_ERROR, error })
      dispatch(
        addToast({
          message: 'There was a problem creating a partner. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

export function updatePartner(partnerUuid, title) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_PARTNER_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/partner/${partnerUuid}`,
        { title: title },
      )
      const data = normalize(response.data.data[0].output, partnersListSchema)

      dispatch({
        message: 'Partner successfully updated!',
        type: API_UPDATE_PARTNER_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_UPDATE_PARTNER_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error while updating partner. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function uploadPartnerLogo({ uuid, file, onUploadProgress }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPLOAD_PARTNER_LOGO_REQUEST })

    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.put(
        `${SAUCE_API_URL}/partner/${uuid}/logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        },
      )

      const data = normalize(response.data.data[0].output, partnersListSchema)

      dispatch({
        message: 'Partner logo successfully updated!',
        type: API_UPLOAD_PARTNER_LOGO_SUCCESS,
        data,
      })

      return true
    } catch (error) {
      dispatch({ type: API_UPLOAD_PARTNER_LOGO_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was a problem uploading the partner logo. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )

      return false
    }
  }
}

export const partnerActions = {
  getPartners,
  getPartner,
  deletePartner,
  addPartner,
  createPartner,
  updatePartner,
  uploadPartnerLogo,
}
