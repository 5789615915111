import initialState from 'reducers/initialState'

import {
  API_GET_PARTNER_ORGS_SUCCESS,
  API_CREATE_PARTNER_ORG_SUCCESS,
  API_DELETE_PARTNER_ORG_SUCCESS,
  LIST_EMPTY_PARTNER_ORGANISATION,
  CLEAN_OUT_EMPTY_PARTNER_ORGANISATIONS,
} from './actions'

// Reducer
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function partnerOrganisationsReducer(
  state = initialState.partnerOrganisations,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_CREATE_PARTNER_ORG_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.partnerOrganisations,
        },
      }
    case API_GET_PARTNER_ORGS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.partnerOrganisations,
        },
      }
    case API_DELETE_PARTNER_ORG_SUCCESS: {
      const newEntities = { ...state.entities }
      delete newEntities[action.data.uuid]

      return {
        ...state,
        entities: newEntities,
      }
    }
    case LIST_EMPTY_PARTNER_ORGANISATION: {
      const partnerOrganisation = action.data
      return {
        ...state,
        entities: {
          ...state.entities,
          [partnerOrganisation.uuid]: {
            organisation: partnerOrganisation,
            adminCount: 0,
            uuid: partnerOrganisation.uuid,
            isPending: true,
          },
        },
      }
    }
    case CLEAN_OUT_EMPTY_PARTNER_ORGANISATIONS: {
      const newEntities = { ...state.entities }

      const entitiesToRemove = Object.values(newEntities).filter(
        (partnerOrg) => partnerOrg.uuid === partnerOrg.organisation.uuid,
      )
      if (!entitiesToRemove.length) {
        return {
          ...state,
          entities: newEntities,
        }
      }
      for (let emptyStateOrg of entitiesToRemove) {
        delete newEntities[emptyStateOrg.uuid]
      }

      return {
        ...state,
        entities: newEntities,
      }
    }

    default:
      return state
  }
}
