import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ContentContainer({ children, className }) {
  return <StyledContainer className={className}>{children}</StyledContainer>
}

ContentContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default ContentContainer
