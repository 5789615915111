import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import {
  typesOfDayListSchema,
  departmentListSchema,
  roleListSchema,
} from './schema'

// Actions
export const API_GET_DAY_PARAMETERS_REQUEST = 'API_GET_DAY_PARAMETERS_REQUEST'
export const API_GET_DAY_PARAMETERS_SUCCESS = 'API_GET_DAY_PARAMETERS_SUCCESS'
export const API_GET_DAY_PARAMETERS_ERROR = 'API_GET_DAY_PARAMETERS_ERROR'

export const API_UPDATE_DAY_PARAMETERS_REQUEST =
  'API_UPDATE_DAY_PARAMETERS_REQUEST'
export const API_UPDATE_DAY_PARAMETERS_SUCCESS =
  'API_UPDATE_DAY_PARAMETERS_SUCCESS'
export const API_UPDATE_DAY_PARAMETERS_ERROR = 'API_UPDATE_DAY_PARAMETERS_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getDayParameters(orgUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_DAY_PARAMETERS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/day`,
      )

      const dayTypeData = normalize(
        response.data.data[0].dayTypes,
        typesOfDayListSchema,
      )
      const departmentData = normalize(
        response.data.data[0].departments,
        departmentListSchema,
      )
      const roleData = normalize(response.data.data[0].roles, roleListSchema)

      dispatch({
        type: API_GET_DAY_PARAMETERS_SUCCESS,
        data: {
          typesOfDays: dayTypeData,
          departments: departmentData,
          roles: roleData,
        },
      })
    } catch (error) {
      dispatch({ type: API_GET_DAY_PARAMETERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the survey questions. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateDayParameters({ orgUuid, dayTypes, departments, roles }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_DAY_PARAMETERS_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/day`,
        {
          dayTypes,
          departments,
          roles,
        },
      )
      const dayTypeData = normalize(
        response.data.data[0].dayTypes,
        typesOfDayListSchema,
      )
      const departmentData = normalize(
        response.data.data[0].departments,
        departmentListSchema,
      )
      const roleData = normalize(response.data.data[0].roles, roleListSchema)

      dispatch({
        type: API_UPDATE_DAY_PARAMETERS_SUCCESS,
        data: {
          typesOfDays: dayTypeData,
          departments: departmentData,
          roles: roleData,
        },
      })

      dispatch(
        addToast({
          message: 'Survey questions have been updated.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_DAY_PARAMETERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error updating survey questions. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
