import initialState from 'reducers/initialState'

import {
  API_GET_INCIDENTS_SUCCESS,
  API_REVERT_INCIDENT_ERROR,
  API_EDIT_INCIDENT_SUCCESS,
  API_REVERT_INCIDENT_REQUEST,
  API_HIDE_INCIDENT_SUCCESS,
  API_APPROVE_INCIDENTS_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function incidentsReducer(
  state = initialState.incidents,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_GET_INCIDENTS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.incidents,
        },
      }

    case API_REVERT_INCIDENT_REQUEST: {
      const { incidentUuid } = action.data
      const incidentEntry = state.entities[incidentUuid]

      // Optimistically revert incident on request
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.incidentUuid]: {
            ...incidentEntry,
            editedEntry: null,
          },
        },
      }
    }

    case API_HIDE_INCIDENT_SUCCESS:
    case API_EDIT_INCIDENT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.uuid]: {
            ...action.data,
          },
        },
      }
    case API_APPROVE_INCIDENTS_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.incidents,
        },
      }
    }
    case API_REVERT_INCIDENT_ERROR: {
      const { incidentUuid, editedEntry } = action.data
      const incidentEntry = state.entities[incidentUuid]

      // Revert incident back if the api request fails
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.incidentUuid]: {
            ...incidentEntry,
            editedEntry,
          },
        },
      }
    }

    default:
      return state
  }
}
