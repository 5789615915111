import { TOGGLE_ALL } from 'constants/form'

import useFilterLabel from 'hooks/useFilterLabel'
import React, { useMemo, useState, useCallback, useEffect } from 'react'

/**
 * Mainly for usage with the organisations or groups filters that are used in the dashboard
 * Provides functionality for rendering the filter label and managing the state of the filter
 * @param {Array}    entities       The unfiltered list of entities.
 * @param {string}   entityName     The name of the entity to render in the label.
 * @param {boolean}  entitiesLoaded Loading state
 * @returns {Object}                The object with the functions and values used for the filter
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useEntitiesFilter<T = unknown>(
  entities: T & { uuid: string }[],
  entityName: string,
  entitiesLoaded: boolean,
): {
  renderFilterLabel: () => string
  /** @deprecated use handleChangeSelectedUuids, naming for this one is wrong */
  handleChangeFilteredEntities: (
    e: typeof TOGGLE_ALL | React.BaseSyntheticEvent,
  ) => void
  handleChangeSelectedUuids: (
    e: typeof TOGGLE_ALL | React.BaseSyntheticEvent,
  ) => void
  /** @deprecated use selectedUuids, naming for this one is wrong */
  filteredEntities: string[]
  selectedUuids: string[]
} {
  const allUuids = useMemo(
    () => entities.map((entity) => entity.uuid),
    [entities],
  )
  const [selectedUuids, setSelectedUuids] = useState(allUuids)

  // This handles the 'Toggle all' button at the top of the filter
  const handleToggleAll = useCallback(() => {
    if (selectedUuids.length !== allUuids.length) {
      setSelectedUuids(allUuids)
    } else {
      setSelectedUuids([])
    }
  }, [selectedUuids, allUuids])

  const renderFilterLabel = useFilterLabel(entities, entityName, selectedUuids)

  // This updates the array of organisations when a menu item is clicked
  const handleChangeSelectedUuids = useCallback(
    (e: typeof TOGGLE_ALL | React.BaseSyntheticEvent) => {
      if (e === TOGGLE_ALL || e.target.value.includes(TOGGLE_ALL)) {
        handleToggleAll()
      } else {
        setSelectedUuids(e.target.value)
      }
    },
    [handleToggleAll],
  )

  useEffect(
    () => {
      if (entitiesLoaded) {
        if (allUuids.length === selectedUuids.length) {
          setSelectedUuids(allUuids)
        } else {
          handleChangeSelectedUuids(TOGGLE_ALL)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entitiesLoaded],
  )

  return {
    renderFilterLabel,
    handleChangeSelectedUuids,
    selectedUuids,
    // deprecated naming
    handleChangeFilteredEntities: handleChangeSelectedUuids,
    filteredEntities: selectedUuids,
  }
}
