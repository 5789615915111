import * as authActions from 'modules/authentication/actions'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    handleSubmit:
      ({ actions }) =>
      (values) => {
        actions.changePassword(values.oldPassword, values.newPassword)
      },
  }),
)
