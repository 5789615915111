import { useState, useCallback } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useIncidentModals() {
  const [isDeleteIncidentModalOpen, setIsDeleteIncidentModalOpen] =
    useState(false)
  const [isApproveIncidentsModalOpen, setIsApproveIncidentsModalOpen] =
    useState(false)
  const [isIncidentEntryModalOpen, setIsIncidentEntryModalOpen] =
    useState(false)
  const [incidentModalUuid, setIncidentModalUuid] = useState(null)

  const handleOpenDeleteIncidentModal = useCallback((uuid) => {
    setIncidentModalUuid(uuid)
    setIsDeleteIncidentModalOpen(true)
  }, [])

  const handleCloseDeleteIncidentModal = useCallback(() => {
    setIsDeleteIncidentModalOpen(false)
    setIncidentModalUuid(null)
  }, [])

  const handleOpenApproveIncidentsModal = useCallback(() => {
    setIsApproveIncidentsModalOpen(true)
  }, [])

  const handleCloseApproveIncidentsModal = useCallback(() => {
    setIsApproveIncidentsModalOpen(false)
  }, [])

  const handleOpenEntryIncidentsModal = useCallback((uuid) => {
    setIncidentModalUuid(uuid)
    setIsIncidentEntryModalOpen(true)
  }, [])

  const handleCloseEntryIncidentsModal = useCallback(() => {
    setIsIncidentEntryModalOpen(false)
    setIncidentModalUuid(null)
  }, [])

  return {
    isDeleteIncidentModalOpen,
    isApproveIncidentsModalOpen,
    isIncidentEntryModalOpen,
    incidentModalUuid,
    handleOpenDeleteIncidentModal,
    handleCloseDeleteIncidentModal,
    handleOpenApproveIncidentsModal,
    handleCloseApproveIncidentsModal,
    handleOpenEntryIncidentsModal,
    handleCloseEntryIncidentsModal,
  }
}
