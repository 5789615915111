// eslint-disable-next-line import/no-named-as-default
import NewPartnerForm from './NewPartnerForm'
import enhance from './NewPartnerForm.enhancer'

export default enhance(NewPartnerForm)

export interface FormValues {
  title: string
}

export interface InitialValues {
  title: ''
}

export interface FormProps {
  className: string
  onSubmit: (values: FormValues) => void
  onClose: () => void
  title: string
  initialValues: InitialValues
}
