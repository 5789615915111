import { compose, withStateHandlers, lifecycle, withState } from 'recompose'

export default compose(
  withState('onMounted', 'setOnMounted', false),
  withStateHandlers(
    ({ suggestions }) => ({
      filteredSuggestions: [...suggestions],
    }),
    {
      updateFilteredSuggestions:
        (state, { suggestions, hideinput }) =>
        (query, reason) => {
          if (hideinput) {
            return {
              filteredSuggestions: [],
              shouldAlwaysRenderSuggestions: false,
            }
          }

          return {
            shouldAlwaysRenderSuggestions: true,
            // Filter the visible suggestions by the query
            filteredSuggestions:
              //if user select one suggestion, the keep showing remaining options,
              //otherwise filter out options by free text
              'suggestion-selected' === reason
                ? suggestions
                : suggestions.filter((suggestion) => {
                    // convert to lowercase before comparing values
                    const label = suggestion.label.toLowerCase()
                    const lcQuery = query.toLowerCase()

                    return label.includes(lcQuery)
                  }),
          }
        },
      clearSuggestions:
        (state, { suggestions }) =>
        () => ({
          filteredSuggestions: [...suggestions],
        }),
      onInputLoseFocus: () => () => ({
        filteredSuggestions: [],
        shouldAlwaysRenderSuggestions: false,
      }),
    },
  ),
  lifecycle({
    componentDidMount() {
      const { setOnMounted } = this.props
      setOnMounted(true)
    },
  }),
)
