import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import {
  authenticatedRedirect,
  saveAuthInfo,
} from 'modules/authentication/actions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { CHNNL_API_URL } from '../../config'
import { NO_ACCOUNT } from '../../constants/routes'
import { TOAST_TYPE_ERROR } from '../../constants/ui'
// eslint-disable-next-line import/no-named-as-default
import redirect from '../../modules/route'
import { addToast } from '../../modules/toasts/actions'

export const StyledContent = styled.div`
  text-align: center;
  width: 340px;
`

const StyledTitle = styled(Typography)`
  && {
    font-family: 'PP Telegraf', sans-serif;
    font-weight: 300;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const StyledParagraph = styled(Typography)`
  && {
    font-family: 'Graphik', sans-serif;
    font-weight: 300;
    letter-spacing: 0.8px;
    font-size: 16px;
    line-height: 24px;
    padding-top: ${({ theme }) => theme.spacing.xs}px;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function Authenticated(): React.ReactElement {
  const dispatch = useDispatch()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: CHNNL_API_URL,
          },
        })

        const apiResponse = await fetch(`${CHNNL_API_URL}profile`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${accessToken}`,
          },
        })

        if (apiResponse.status === 401) {
          // TODO: Redirect to need a role page
          dispatch(redirect(NO_ACCOUNT))
          return
        } else if (apiResponse.status !== 200) {
          dispatch(
            addToast({
              message: 'Privileges check failed. Please contact support.',
              type: TOAST_TYPE_ERROR,
            }),
          )
          return
        }

        const profile = (await apiResponse.json()).data[0]

        dispatch(
          saveAuthInfo({
            token: accessToken,
            role: profile.role,
            organisationUuid: profile.organisation?.uuid,
            partnerUuid: profile.partner?.uuid,
          }),
        )
        dispatch(authenticatedRedirect())
      } catch (error) {
        Sentry.captureException(error)
        dispatch(
          addToast({
            message: 'Privileges check failed. Please contact support.',
            type: TOAST_TYPE_ERROR,
          }),
        )
      }
    }

    getUserProfile()
  }, [dispatch, getAccessTokenSilently, isAuthenticated])

  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">Checking privileges</StyledTitle>
        <StyledParagraph>You should be redirected soon</StyledParagraph>
      </StyledContent>
    </DefaultLayout>
  )
}
