import { normalize, NormalizedSchema, schema } from 'normalizr'

import { RequestProgressState } from '../progress'

export interface Tip {
  uuid: string
  body: string
  tags: string
  createdAt: string
}

export interface TipsState {
  progress: {
    getTips: RequestProgressState
  }
  tips: NormalizedSchema<{ [key: string]: Tip }, string>
}

export const tipSchema = new schema.Entity<Tip>(
  'tips',
  {},
  { idAttribute: 'uuid' },
)

export const tipListSchema = [tipSchema]

// TODO: move this as a util eventually and use generics for the type of entity

// eslint-disable-next-line @typescript-eslint/naming-convention
type entityKeys = 'tips'

export type NormalizedSchemaResponse = NormalizedSchema<
  {
    [k in entityKeys]: {
      [key: string]: Tip
    }
  },
  string[]
>

export const normalizeResponse = <T>(
  responseData: T[],
  schema: typeof tipListSchema,
): NormalizedSchemaResponse => normalize(responseData, schema)
