import initialState from 'reducers/initialState'

import {
  API_GET_PARTNER_ADMINS_SUCCESS,
  API_CREATE_PARTNER_ADMIN_SUCCESS,
  API_DELETE_PARTNER_ADMIN_SUCCESS,
} from './actions'

// Reducer
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function partnerAdminsReducer(
  state = initialState.partnerAdmins,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_CREATE_PARTNER_ADMIN_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.partnerAdmins,
        },
      }
    case API_GET_PARTNER_ADMINS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.partnerAdmins,
        },
      }
    case API_DELETE_PARTNER_ADMIN_SUCCESS: {
      const newEntities = { ...state.entities }
      delete newEntities[action.data.uuid]

      return {
        ...state,
        entities: newEntities,
      }
    }

    default:
      return state
  }
}
