import { push } from 'connected-react-router'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const redirect = (pathname) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  function dispatcher(dispatch) {
    dispatch(push(pathname))
  }

export default redirect
