import { initialProgressState } from 'modules/progress'
import {
  getUserRole,
  getRefreshToken,
  getSessionToken,
} from 'utils/authentication'

import { messagingInitialState } from '../modules/messaging/initialState'

const initialState = {
  progress: {
    login: {
      ...initialProgressState,
    },
    register: {
      ...initialProgressState,
    },
    resetPassword: {
      ...initialProgressState,
    },
    newPassword: {
      ...initialProgressState,
    },
    getOrganisations: {
      ...initialProgressState,
    },
    getOrganisationAdmins: {
      ...initialProgressState,
    },
    getOrgCountries: {
      ...initialProgressState,
    },
    updateOrganisation: {
      ...initialProgressState,
    },
    uploadOrganisationLogo: {
      ...initialProgressState,
    },
    getPartners: {
      ...initialProgressState,
    },
    getPartner: {
      ...initialProgressState,
    },
    getPartnerOrganisations: {
      ...initialProgressState,
    },
    partnerlessOrganisations: {
      ...initialProgressState,
    },
    getPartnerAdmins: {
      ...initialProgressState,
    },
    updatePartner: {
      ...initialProgressState,
    },
    uploadPartnerLogo: {
      ...initialProgressState,
    },
    getGroups: {
      ...initialProgressState,
    },
    getOrganisationBoosters: {
      ...initialProgressState,
    },
    getJournals: {
      ...initialProgressState,
    },
    getJournalReply: {
      ...initialProgressState,
    },
    getTips: {
      ...initialProgressState,
    },
    getIncidents: {
      ...initialProgressState,
    },
    getReflections: {
      ...initialProgressState,
    },
    updateReflections: {
      ...initialProgressState,
    },
    createReflection: {
      ...initialProgressState,
    },
    updateReflection: {
      ...initialProgressState,
    },
    deleteReflection: {
      ...initialProgressState,
    },
    getActivity: {
      ...initialProgressState,
    },
    getGroupMembers: {
      ...initialProgressState,
    },
    getKeyContacts: {
      ...initialProgressState,
    },
    getSettings: {
      ...initialProgressState,
    },
    getPathways: {
      ...initialProgressState,
    },
    updatePathways: {
      ...initialProgressState,
    },
    getDayParameters: {
      ...initialProgressState,
    },
    inviteUser: {
      ...initialProgressState,
    },
    reinviteGroupMember: {
      ...initialProgressState,
    },
    resendMultipleMemberInvites: {
      ...initialProgressState,
    },
    moveMembers: {
      ...initialProgressState,
    },
    inviteGroupMembers: {
      ...initialProgressState,
    },
    createGroup: {
      ...initialProgressState,
    },
    addBooster: {
      ...initialProgressState,
    },
    getSignUpLink: {
      ...initialProgressState,
    },
    createSignUpLink: {
      ...initialProgressState,
    },
    archiveSignUpLink: {
      ...initialProgressState,
    },
    deleteBooster: {
      ...initialProgressState,
    },
    updateBooster: {
      ...initialProgressState,
    },
    updateOrganisationBoosters: {
      ...initialProgressState,
    },
    updateGroupBoosters: {
      ...initialProgressState,
    },
    updateProfile: {
      ...initialProgressState,
    },
    updatePathway: {
      ...initialProgressState,
    },
    refreshSession: {
      ...initialProgressState,
    },
    deleteGroup: {
      ...initialProgressState,
    },
    getBranchData: {
      ...initialProgressState,
    },
    sendSms: {
      ...initialProgressState,
    },
    getRedFlagChats: {
      ...initialProgressState,
    },
    getEntitlements: {
      ...initialProgressState,
    },
  },
  authentication: {
    role: getUserRole(),
    token: getSessionToken(),
    refreshToken: getRefreshToken(),
  },
  user: {
    email: '',
    firstName: '',
    lastName: '',
    role: null,
    assignedGroups: [],
    organisation: {
      uuid: '',
      name: '',
      logo: '',
    },
    notifications: {
      monthlyReport: false,
      emailUpdate: false,
    },
    partner: {
      uuid: '',
      title: '',
      logo: '',
    },
  },
  activity: {
    seats: {},
    highLevel: {},
    devices: {
      rows: [],
    },
    responses: {
      dailyChart: {
        rows: [],
      },
      time: {
        rows: [],
      },
    },
  },
  admins: {
    entities: {},
  },
  boosters: {
    entities: {},
  },
  branchData: {
    entities: {},
  },
  countries: {
    entities: {},
  },
  groups: {
    entities: {},
    selected: {
      active: 0,
      pending: [],
    },
  },
  groupSignUpLinks: {},
  journals: {
    entities: {},
  },
  tips: {
    entities: {},
  },
  incidents: {
    entities: {},
  },
  keyContacts: {
    entities: {},
    orderedIds: [],
  },
  notifications: {
    entities: {},
  },
  organisations: {
    entities: {},
  },
  partners: {
    entities: {},
  },
  partnerOrganisations: {
    entities: {},
  },
  partnerAdmins: {
    entities: {},
  },
  orgCountries: {
    entities: {},
  },
  pathways: {
    entities: {},
  },
  reflections: {
    entities: {},
    orderedIds: [],
  },
  dayParameters: {
    typesOfDays: {
      entities: {},
      orderedIds: [],
    },
    departments: {
      entities: {},
      orderedIds: [],
    },
    roles: {
      entities: {},
      orderedIds: [],
    },
  },
  toasts: {
    messages: [],
  },
  chatbot: {
    redFlagChats: {},
    chatHistory: {},
  },
  entitlements: {
    administration: {},
  },
  undo: {
    past: [],
  },
  messaging: { ...messagingInitialState },
}

export default initialState
