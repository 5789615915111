import { schema } from 'normalizr'

import { UserJournal } from '../journals/schema'

//TODO update this to "Message", Notification is a reserved keyword in browsers
export interface Notification {
  uuid: string
  createdAt: Date
  updatedAt: Date | null
  authorUuid: string

  title: string
  message: string
  acknowledged: boolean
  userUuid: string
  userFingerprint: string
  originalJournalUuid?: string
  journal?: UserJournal
}

export const notificationSchema = new schema.Entity(
  'notifications',
  {},
  { idAttribute: 'uuid' },
)
export const notificationListSchema = [notificationSchema]
