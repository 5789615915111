import initialState from 'reducers/initialState'

import {
  API_GET_ORGANISATIONS_SUCCESS,
  API_GET_ORGANISATION_SUCCESS,
  API_CREATE_ORGANISATION_SUCCESS,
  API_CREATE_DEMO_ORGANISATION_SUCCESS,
  API_DELETE_ORGANISATION_SUCCESS,
  API_UPDATE_ORGANISATION_SUCCESS,
  API_UPLOAD_ORGANISATION_LOGO_SUCCESS,
  ADD_ORGANISATION,
  CHANGE_ORG_DETAILS,
} from './actions'

// Reducer
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function organisationsReducer(
  state = initialState.organisations,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case CHANGE_ORG_DETAILS: {
      const { orgUuid, seatsOccupied } = action.data

      return {
        ...state,
        entities: {
          ...state.entities,
          [orgUuid]: {
            ...state.entities[orgUuid],
            seatsOccupied,
          },
        },
      }
    }

    case ADD_ORGANISATION: {
      const { organisation } = action.data

      return {
        ...state,
        entities: {
          [organisation.uuid]: {
            ...organisation,
          },
        },
      }
    }
    case API_CREATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.organisations,
        },
      }
    case API_CREATE_DEMO_ORGANISATION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.organisations,
        },
      }
    case API_GET_ORGANISATION_SUCCESS:
    case API_UPLOAD_ORGANISATION_LOGO_SUCCESS:
    case API_UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.organisations,
        },
      }
    case API_GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.organisations,
        },
      }

    case API_DELETE_ORGANISATION_SUCCESS: {
      const newEntities = { ...state.entities }
      delete newEntities[action.data.uuid]

      return {
        ...state,
        entities: newEntities,
      }
    }

    default:
      return state
  }
}
