import PropTypes from 'prop-types'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ArrowUp({ className }) {
  return (
    <svg width="10" height="10" className={className}>
      <g id="Chnnl-admin-home" fill="none" fillRule="evenodd">
        <g
          id="_Screens/Super-admin/journal-approval"
          transform="translate(-942 -183)"
          fill="#3574FA">
          <g id="Group-3" transform="translate(942 183)">
            <path
              d="M1.114.213a.606.606 0 0 0-.923 0 .788.788 0 0 0 0 1.029L4.27 5.787a.606.606 0 0 0 .922 0L9.27 1.242a.788.788 0 0 0 0-1.029.606.606 0 0 0-.923 0L4.73 4.244 1.114.213z"
              id="Path-3"
              transform="matrix(1 0 0 -1 0 6)"
            />
            <rect
              id="Rectangle-19"
              transform="matrix(1 0 0 -1 0 11)"
              x="4"
              y="1"
              width="1.443"
              height="9"
              rx=".721"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

ArrowUp.propTypes = {
  className: PropTypes.string,
}

export default ArrowUp
