import initialState from 'reducers/initialState'

import {
  API_GET_DAY_PARAMETERS_SUCCESS,
  API_UPDATE_DAY_PARAMETERS_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function surveyReducer(
  state = initialState.dayParameters,
  action,
) {
  switch (action.type) {
    case API_GET_DAY_PARAMETERS_SUCCESS:
    case API_UPDATE_DAY_PARAMETERS_SUCCESS:
      return {
        ...state,
        typesOfDays: {
          ...state.typesOfDays,
          entities: {
            ...action.data.typesOfDays.entities.typesOfDays,
          },
          orderedIds: action.data.typesOfDays.result,
        },
        departments: {
          ...state.departments,
          entities: {
            ...action.data.departments.entities.departments,
          },
          orderedIds: action.data.departments.result,
        },
        roles: {
          ...state.roles,
          entities: {
            ...action.data.roles.entities.roles,
          },
          orderedIds: action.data.roles.result,
        },
      }

    default:
      return state
  }
}
