import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledFields = styled.fieldset`
  width: 300px;
  margin: 0 auto ${({ theme }) => theme.spacing.xl}px auto;
`

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledModalTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const ModalText = styled(Typography)`
  && {
    width: 300px;
    margin: 0 auto ${({ theme }) => theme.spacing.lg}px auto;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ResendNewOrganisationForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  organisation,
  onCancel,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledModalTitle variant="h2">{organisation.name}</StyledModalTitle>
      <ModalText variant="body1">
        We&apos;re still waiting for {organisation.name}&apos;s response to
        their chnnl invitation.
      </ModalText>
      <StyledFields>
        <TextField
          id="email"
          name="email"
          label="Organisation owner email address"
          type="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.email && !!errors.email}
          helperText={touched.email ? errors.email : ''}
          fullWidth
        />
      </StyledFields>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          type="submit"
          size="small"
          color="primary">
          Resend invitation
        </ActionButton>
      </StyledActions>
    </form>
  )
}

ResendNewOrganisationForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  organisation: PropTypes.object,
}

ResendNewOrganisationForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  organisation: {},
}

export default ResendNewOrganisationForm
