import { PAGINATION_LIMIT } from 'constants/pagination'

import idx from 'idx'

// TODO: Need a set of get functions that return a query params POJO
//  to be passed to Axios. Instead of formatting strings here.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getQueryParams = (params) => {
  if (!params) {
    return
  }
  let qString = ''
  let paramFields = { ...params }

  if ((paramFields.start || paramFields.start === 0) && paramFields.limit) {
    qString += `start=${params.start}&limit=${params.limit}&count=true`
    delete paramFields.start
    delete paramFields.limit
  }

  if (paramFields.searchFilter) {
    qString += `&search=%25${paramFields.searchFilter}%25`
    delete paramFields.searchFilter
  }

  return qString
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getArrayFilters = (filters) =>
  filters && filters.value && filters.value.length
    ? `${filters.key}=%5B${filters.value.map((f) => `"${f}"`)}%5D`
    : ''

export const getValueFilter = (filter) =>
  filter && filter.value ? `${filter.key}=%25${filter.value}%25` : ''

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPaginationResult = (result) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  start: idx(result, (_) => _.pagination.start) || 0,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  limit: idx(result, (_) => _.pagination.limit) || PAGINATION_LIMIT,
  count: result.count || 0,
})

export const getApiErrorMessage = (axiosError, defaultErrorMessage) => {
  if (axiosError?.response?.data?.message) {
    return axiosError.response.data.message
  }
  return defaultErrorMessage
}
