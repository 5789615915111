import { JournalApproval } from '../../../modules/journals/schema'
import { Notification } from '../../../modules/notifications/schema'
import { RequestProgressState } from '../../../modules/progress'
import { User } from '../../../modules/user/schema'

import { enhance } from './JournalReplyForm.enhancer'
import { JournalReplyInnerForm } from './JournalReplyInnerForm'

export interface InnerFormProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit?: Function
  journal: JournalApproval
  replies: Notification[]
  currentUser: User
  progress: RequestProgressState
  // eslint-disable-next-line @typescript-eslint/ban-types
  storeReply?: Function
}

export interface FormProps {
  initialReply: string
}

export interface FormValues {
  reply: string
}

export const JournalReplyForm = enhance(JournalReplyInnerForm)
