import { schema } from 'normalizr'

export const redFlagChatsSchema = new schema.Entity(
  'redFlagChat',
  {},
  { idAttribute: 'uuid' },
)
export const redFlagChatsListSchema = [redFlagChatsSchema]

export const chatHistorySchema = new schema.Entity(
  'chatHistory',
  {},
  { idAttribute: 'id' },
)
export const chatHistorySchemaListSchema = [chatHistorySchema]
