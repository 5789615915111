import {
  TIP_TYPE_ALL,
  TIP_THEME_CALM,
  TIP_THEME_ACTIVE,
  TIP_THEME_RESTFUL,
  TIP_THEME_INTERESTED,
  TIP_THEME_CHEERFUL,
} from 'constants/form'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { TipFormValues } from 'components/organisms/TipForm/TipForm'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import {
  getPaginationResult,
  getQueryParams,
  getApiErrorMessage,
} from 'utils/api'

import { DispatchProps } from '../../reducers/types'
import { RootState } from '../RootState'

import {
  tipListSchema,
  Tip,
  normalizeResponse,
  NormalizedSchemaResponse,
} from './schema'

// Actions

// GET
export const API_GET_TIPS_REQUEST = 'API_GET_TIPS_REQUEST'
export const API_GET_TIPS_SUCCESS = 'API_GET_TIPS_SUCCESS'
export const API_GET_TIPS_ERROR = 'API_GET_TIPS_ERROR'
// DELETE
export const API_DELETE_TIP_REQUEST = 'API_DELETE_TIP_REQUEST'
export const API_DELETE_TIP_SUCCESS = 'API_DELETE_TIP_SUCCESS'
export const API_DELETE_TIP_ERROR = 'API_DELETE_TIP_ERROR'
// PUT
export const API_UPDATE_TIP_REQUEST = 'API_UPDATE_TIP_REQUEST'
export const API_UPDATE_TIP_SUCCESS = 'API_UPDATE_TIP_SUCCESS'
export const API_UPDATE_TIP_ERROR = 'API_UPDATE_TIP_ERROR'
// POST
export const API_ADD_TIP_REQUEST = 'API_ADD_TIP_REQUEST'
export const API_ADD_TIP_SUCCESS = 'API_ADD_TIP_SUCCESS'
export const API_ADD_TIP_ERROR = 'API_ADD_TIP_ERROR'

export interface GetTipsSuccess {
  type: typeof API_GET_TIPS_SUCCESS
  data: NormalizedSchemaResponse
}

export interface GetTipsRequest {
  type: typeof API_GET_TIPS_REQUEST
}

export interface UpdateTipRequest {
  type: typeof API_UPDATE_TIP_REQUEST
}

export interface GetTipsError {
  type: typeof API_GET_TIPS_ERROR
  error: unknown
}

export interface UpdateTipSuccess {
  type: typeof API_UPDATE_TIP_REQUEST
  data: {
    updatedTip: TipFormValues
    tipUuid: string
  }
}
export interface AddTipSuccess {
  type: typeof API_ADD_TIP_SUCCESS
  data: {
    tip: Tip
  }
}

export interface UpdateTipError {
  type: typeof API_UPDATE_TIP_ERROR
  data: {
    tip: Tip
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTipsFilters = (type: string) => {
  switch (type) {
    case TIP_THEME_CALM:
      return 'filters:{tags:$like_CALM}'
    case TIP_THEME_ACTIVE:
      return 'filters:{tags:$like_ACTIVE}'
    case TIP_THEME_RESTFUL:
      return 'filters:{tags:$like_RESTFUL}'
    case TIP_THEME_INTERESTED:
      return 'filters:{tags:$like_INTERESTED}'
    case TIP_THEME_CHEERFUL:
      return 'filters:{tags:$like_CHEERFUL}'
    case TIP_TYPE_ALL:
    default:
      return ''
  }
}

export interface FetchTipsParams {
  sortOptions?: Record<string, string>
  tipType: string
  start?: number
  limit?: number
  searchFilter: string
}

// TODO: add this to getPaginationResult helper
export interface PaginationResult {
  start: number
  limit: number
  count: number
}

const defaultParams: FetchTipsParams = {
  tipType: '',
  searchFilter: '',
}

// Action creators
const getTips = (params: FetchTipsParams = defaultParams) =>
  async function dispatcher(
    dispatch: (props: DispatchProps) => void,
  ): Promise<PaginationResult | undefined> {
    dispatch({ type: API_GET_TIPS_REQUEST })

    try {
      const options = []
      const filterOptions = getTipsFilters(params.tipType)
      if (filterOptions) {
        options.push(filterOptions)
      }
      if (params.sortOptions) {
        // produces "sort:{createdAt:DESC}"
        options.push(
          JSON.stringify({ sort: params.sortOptions })
            .replaceAll('"', '')
            .replace('{', '')
            .replace(/}$/, ''),
        )
      }
      const optionsString = `{${options.join(',')}}`

      const response = await axios.get(
        `${SAUCE_API_URL}/tip?&${getQueryParams(
          params,
        )}&options=${encodeURIComponent(optionsString)}`,
      )
      const data = normalizeResponse<Tip>(response.data.data, tipListSchema)

      const pagination: PaginationResult = getPaginationResult(response.data)

      dispatch({
        type: API_GET_TIPS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_TIPS_ERROR, error })
      dispatch(
        addToast({
          message: `There was an error fetching the tips. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,

          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }

export function deleteTip(tipUuid: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch: (props: DispatchProps) => void) {
    dispatch({ type: API_DELETE_TIP_REQUEST })

    try {
      await axios.delete(`${SAUCE_API_URL}/tip/${tipUuid}`)

      dispatch({
        type: API_DELETE_TIP_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Tip has been deleted',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_TIP_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the tip entry. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function updateTip(updatedTip: TipFormValues, tipUuid: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(
    dispatch: (props: DispatchProps) => void,
    getState: () => RootState,
  ) {
    const state = getState()
    const tip = state.tips.entities[tipUuid]

    // optimistically updated our state
    dispatch({
      type: API_UPDATE_TIP_REQUEST,
      data: {
        updatedTip,
        tipUuid,
      },
    })

    try {
      await axios.patch(`${SAUCE_API_URL}/tip/${tipUuid}`, {
        body: updatedTip.body,
        tags: updatedTip.tags,
      })

      dispatch({
        type: API_UPDATE_TIP_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Tip has been successfully updated',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      // rollback our state update
      dispatch({
        type: API_UPDATE_TIP_ERROR,
        error,
        data: {
          tip,
        },
      })
      dispatch(
        addToast({
          message: `There was an error updating the tip. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,

          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function addTip(newTip: TipFormValues) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch: (props: DispatchProps) => void) {
    dispatch({
      type: API_ADD_TIP_REQUEST,
    })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/tip`, {
        body: newTip.body,
        tags: newTip.tags,
      })

      const data = normalizeResponse<Tip>(response.data.data, tipListSchema)

      const {
        result: [tipUuid],
        entities: { tips },
      } = data

      const tip = tips[tipUuid]

      dispatch({
        type: API_ADD_TIP_SUCCESS,
        data: {
          tip,
        },
      })

      dispatch(
        addToast({
          message: 'Tip has been successfully created',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({
        type: API_ADD_TIP_ERROR,
        error,
      })
      dispatch(
        addToast({
          message: `There was an error creating the tip. ${getApiErrorMessage(
            error,
            'Please try again.',
          )}`,

          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export const actions = {
  getTips,
  addTip,
  updateTip,
  deleteTip,
}
