import initialState from 'reducers/initialState'

import {
  API_GET_TIPS_SUCCESS,
  API_ADD_TIP_SUCCESS,
  API_UPDATE_TIP_REQUEST,
  API_UPDATE_TIP_ERROR,
  GetTipsSuccess,
  AddTipSuccess,
  UpdateTipSuccess,
  UpdateTipError,
} from './actions'
import { Tip } from './schema'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function tipsReducer(
  state = initialState.tips,
  action: GetTipsSuccess | AddTipSuccess | UpdateTipSuccess | UpdateTipError,
) {
  switch (action.type) {
    case API_GET_TIPS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.tips,
        },
      }

    case API_ADD_TIP_SUCCESS: {
      const { tip } = action.data

      return {
        ...state,
        entities: {
          [tip.uuid]: {
            ...tip,
          },
          ...state.entities,
        },
      }
    }

    case API_UPDATE_TIP_REQUEST: {
      const { updatedTip, tipUuid } = action.data
      // the file TS is inferring from is a JS file so have to do this casting
      // TODO: add TS coverage to our slices of state in JS land - this will bite us in the long run
      const tip: Tip = (state.entities as { [key: string]: Tip })[tipUuid]
      // Optimistically update tip
      return {
        ...state,
        entities: {
          ...state.entities,
          [tipUuid]: {
            ...tip,
            ...updatedTip,
          },
        },
      }
    }

    case API_UPDATE_TIP_ERROR: {
      const { tip } = action.data
      // Revert tip back if the api request fails
      return {
        ...state,
        entities: {
          ...state.entities,
          [tip.uuid]: {
            ...tip,
          },
        },
      }
    }
    default:
      return state
  }
}
