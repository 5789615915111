import * as chatbotActions from 'modules/chatbot/actions'
import {
  getRedFlagChatsList,
  getChatHistoryList,
} from 'modules/chatbot/selectors'
import * as organisationsActions from 'modules/organisations/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      organisations: getOrganisationsList(state),
      redFlagChats: getRedFlagChatsList(state),
      chatHistory: getChatHistoryList(state),
      getRedFlagChatsProgress: state.progress.getRedFlagChats,
      getOrgsProgress: state.progress.getOrganisations,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...chatbotActions,
        ...organisationsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.actions.getOrganisations()
    },
  }),
)
