import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const DeleteText = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`
export const DeleteTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DeleteOrganisationForm({
  className,
  onSubmit,
  organisation,
  onCancel,
}) {
  return (
    <form
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <DeleteTitle variant="h4">
        Are you sure you would like to delete{' '}
        {organisation ? organisation.name : 'this organisation'}?
      </DeleteTitle>
      <DeleteText variant="body1">
        This organisation will be permanently removed. Any past data will still
        remain in the reports.
      </DeleteText>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          No, cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          type="submit"
          size="small"
          color="primary">
          Yes, delete
        </ActionButton>
      </StyledActions>
    </form>
  )
}

DeleteOrganisationForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  organisation: PropTypes.object,
}

export default DeleteOrganisationForm
