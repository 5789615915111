import initialState from 'reducers/initialState'

import { API_GET_BRANCH_DATA_SUCCESS } from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function selfSignUpReducer(
  state = initialState.branchData,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_GET_BRANCH_DATA_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data,
        },
      }
    default:
      return state
  }
}
