import LogoString from 'assets/2022-theme/images/logo-string.png'
import React from 'react'
import styled from 'styled-components'

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const LogoContainer = styled.div`
  width: 132px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

type Props = React.HTMLProps<HTMLDivElement>

export function DefaultLayout({ children }: Props): React.ReactElement {
  return (
    <StyledLayout>
      <Inner>
        <LogoContainer>
          <img src={LogoString} alt="Main Logo" />
        </LogoContainer>
        <div>{children}</div>
      </Inner>
    </StyledLayout>
  )
}
