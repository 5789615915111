import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledButton = styled.button`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.palette.primary.main : theme.palette.secondary.main};
  color: ${({ isActive, theme }) =>
    !isActive ? theme.palette.text.primary : theme.palette.secondary.main};
  font-size: ${({ theme, size }) => {
    switch (size) {
      case 'medium':
        return theme.typography.pxToRem(14)
      case 'small':
      default:
        return theme.typography.pxToRem(12)
    }
  }};
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme, size }) => {
      switch (size) {
        case 'medium':
          return theme.spacing.md
        case 'small':
        default:
          return theme.spacing.sm
      }
    }}px;
  outline: 0;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:nth-of-type(n + 2) {
    margin-left: -1px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ButtonSwitch({
  children,
  isActive,
  onClick,
  size,
  value,
  name,
  ...rest
}) {
  return (
    <StyledButton
      isActive={isActive}
      type="button"
      onClick={onClick}
      size={size}
      value={value}
      name={name}
      {...rest}>
      {children}
    </StyledButton>
  )
}

ButtonSwitch.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.string,
  name: PropTypes.string,
}

ButtonSwitch.defaultProps = {
  size: 'medium',
}

export default ButtonSwitch
