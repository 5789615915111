import initialState from 'reducers/initialState'
import {
  saveSessionToken,
  setSessionHeaders,
  saveUserRole,
  clearSession,
  saveUserOrganisation,
  saveUserPartner,
} from 'utils/authentication'

import {
  SAVE_AUTH_INFO,
  API_LOGOUT_SUCCESS,
  API_RESET_PASSWORD_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function authenticationReducer(
  state = initialState.authentication,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_LOGOUT_SUCCESS:
      clearSession()

      return {
        ...state,
        role: '',
        token: '',
        refreshToken: '',
      }
    case SAVE_AUTH_INFO: {
      const { role, token, organisationUuid, partnerUuid } = action.data

      // Save tokens and role to local storage
      saveSessionToken(token)
      setSessionHeaders(token)

      if (role) {
        saveUserRole(role)
      }

      if (organisationUuid) {
        saveUserOrganisation(organisationUuid)
      }
      if (partnerUuid) {
        saveUserPartner(partnerUuid)
      }

      return {
        ...state,
        token,
        role: role || state.role,
      }
    }
    case API_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        API_RESET_PASSWORD_SUCCESS: true,
      }
    }
    default:
      return state
  }
}
