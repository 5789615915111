import { compose, lifecycle, withHandlers, withState } from 'recompose'

export default compose(
  withState('closed', 'toggleClosedState', false),
  withHandlers({
    handleCloseClick:
      ({ data, onClose, toggleClosedState }) =>
      () => {
        if (onClose) {
          toggleClosedState(true)
          setTimeout(() => {
            onClose(data)
          }, 300)
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      this.exit = setTimeout(() => {
        this.props.handleCloseClick()
      }, 6000)
    },
    shouldComponentUpdate(nextProps) {
      const { index } = this.props
      return index !== nextProps.index || nextProps.closed
    },
  }),
)
