import React from 'react'

interface CloseCrossProps {
  color?: string
  height?: number
  width?: number
}

export const CloseCross = ({
  color,
  height,
  width,
}: CloseCrossProps): React.ReactElement => (
  <svg
    height={height || 12}
    width={width || 12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.69654 5.99886L11.6472 2.04752C11.8639 1.8208 11.9832 1.51828 11.9796 1.20469C11.9761 0.891098 11.8499 0.591353 11.6282 0.369597C11.4065 0.147842 11.1068 0.0216923 10.7933 0.0181434C10.4797 0.0145944 10.1773 0.133928 9.95059 0.350608L5.99992 4.30195L2.04722 0.350608C1.82054 0.133928 1.51807 0.0145944 1.20453 0.0181434C0.890994 0.0216923 0.591301 0.147842 0.369583 0.369597C0.147865 0.591353 0.0217362 0.891098 0.0181878 1.20469C0.0146395 1.51828 0.133953 1.8208 0.350597 2.04752L4.3033 5.99886L0.350597 9.9502C0.125611 10.1755 -0.000678138 10.481 -0.000488067 10.7994C-0.000297996 11.1178 0.126355 11.4231 0.35161 11.6481C0.576865 11.8731 0.882269 11.9995 1.20064 11.9993C1.51901 11.9991 1.82426 11.8724 2.04925 11.6471L5.99992 7.69577L9.95059 11.6471C10.062 11.7587 10.1943 11.8472 10.3399 11.9076C10.4855 11.968 10.6416 11.9992 10.7992 11.9993C10.9568 11.9994 11.113 11.9684 11.2586 11.9082C11.4043 11.8479 11.5367 11.7595 11.6482 11.6481C11.7598 11.5367 11.8483 11.4044 11.9087 11.2588C11.9691 11.1131 12.0002 10.957 12.0003 10.7994C12.0004 10.6417 11.9695 10.4856 11.9092 10.3399C11.849 10.1942 11.7606 10.0618 11.6492 9.9502L7.69654 5.99886Z"
      fill={color || 'white'}
    />
  </svg>
)
