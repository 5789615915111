import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import MaterialTableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Button } from '@weareroam/cake-ui-v1'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import EmptyState from 'components/molecules/EmptyState'
import { Modal } from 'components/molecules/Modal'
import { AdminMessageForm } from 'components/organisms/AdminMessageForm/AdminMessageForm'
import { useUserMessages } from 'hooks/useUserMessages'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import { Notification } from 'modules/notifications/schema'
import React from 'react'
import styled from 'styled-components'

import { AdminMessageRow } from './AdminMessage.row'

const StyledTable = styled(Table)`
  min-width: 900px;
  && {
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
`

const MessageModal = styled(Modal)`
  min-width: 900px;
`

const HeadingLabel = styled(Typography)`
  && {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`

const TableHeader = ({
  children,
  align = 'right',
}: { children: React.ReactNode } & TableCellProps) => (
  <MaterialTableCell scope="col" component="th" align={align}>
    <HeadingLabel variant="body1">{children}</HeadingLabel>
  </MaterialTableCell>
)

const MessagesTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableHeader align="left">User Fingerprint</TableHeader>
      <TableHeader>Most Recent Message</TableHeader>
      <TableHeader>Text</TableHeader>
    </TableRow>
  </TableHead>
)

type TabIds = 'message'

const tabs: {
  id: TabIds
  title: string
}[] = [{ id: 'message', title: 'Message User' }]

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AdminMessages = (): React.ReactElement => {
  const [sendNew, setSendNew] = React.useState<boolean>(false)
  const [selectedLine, setSelectedLine] = React.useState<Notification | null>(
    null,
  )

  const { listRequest, listRefetch } = useUserMessages()

  return (
    <ChnnlDashboardLayout>
      <MessageModal
        maxWidth="md"
        tabs={tabs}
        open={!!selectedLine || sendNew}
        hasCloseButton
        onClose={() => {
          setSelectedLine(null)
          setSendNew(false)
        }}>
        <AdminMessageForm
          onSuccess={listRefetch}
          data={selectedLine}
          onCancel={() => {
            setSelectedLine(null)
            setSendNew(false)
          }}
        />
      </MessageModal>
      <ContentContainer>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setSendNew(true)}>
          Send new +
        </Button>
        <StyledTable>
          {listRequest.isLoading && (
            <>
              <StyledTable>
                <MessagesTableHeader />
              </StyledTable>
              <ProgressLoader fullWidth />
            </>
          )}
          {!listRequest.isLoading && listRequest.error && (
            <EmptyState
              title="Oh no! We’re not able to show this page right now."
              childComponent={
                <span>
                  We’ve been notified and will take a look but if the problem
                  persists then let us know.
                </span>
              }
            />
          )}
          {!listRequest.isLoading && !listRequest.error && (
            <StyledTable>
              <MessagesTableHeader />
              <TableBody>
                {listRequest.data?.map((row, i) => (
                  <AdminMessageRow
                    key={i}
                    {...row}
                    onClick={() => setSelectedLine(row)}
                  />
                ))}
              </TableBody>
            </StyledTable>
          )}
        </StyledTable>
      </ContentContainer>
    </ChnnlDashboardLayout>
  )
}
