export const ADD_UNDO = 'ADD_UNDO'
export const APPLY_UNDO = 'APPLY_UNDO'
export const CANCEL_UNDO = 'CANCEL_UNDO'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addUndo({ callback }) {
  return {
    type: ADD_UNDO,
    data: {
      callback,
    },
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function applyUndo({ callback }) {
  if (callback) {
    callback()
  }
  return {
    type: APPLY_UNDO,
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function cancelUndo() {
  return {
    type: CANCEL_UNDO,
  }
}
