import idx from 'idx'
import * as authActions from 'modules/authentication/actions'
import * as userActions from 'modules/user/actions'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user: idx(state, (_) => _.user),
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...userActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    handleSubmit:
      ({ actions }) =>
      (values) => {
        actions.updateProfile(values)
      },
  }),
)
