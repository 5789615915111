import initialState from 'reducers/initialState'

import {
  API_GET_ADMIN_PROFILE_SUCCESS,
  API_UPDATE_PROFILE_NOTIFICATIONS_SUCCESS,
  API_GET_PROFILE_NOTIFICATIONS_SUCCESS,
  API_UPDATE_ADMIN_PROFILE_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case API_GET_ADMIN_PROFILE_SUCCESS: {
      const {
        email,
        firstName,
        lastName,
        role,
        canEdit,
        canEditGroups,
        assignedGroups,
        organisation,
        uuid,
        partner,
      } = action.data

      return {
        ...state,
        email,
        firstName,
        lastName,
        role,
        canEdit,
        canEditGroups,
        assignedGroups,
        organisation,
        partner,
        uuid,
      }
    }
    case API_UPDATE_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.data,
      }
    case API_UPDATE_PROFILE_NOTIFICATIONS_SUCCESS:
    case API_GET_PROFILE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          monthlyReport: action.data.monthlyReport,
          emailUpdate: action.data.emailUpdate,
        },
      }
    default:
      return state
  }
}
