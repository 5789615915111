import initialState from 'reducers/initialState'

import {
  API_GET_KEY_CONTACTS_SUCCESS,
  API_UPDATE_KEY_CONTACTS_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function keyContactsReducer(
  state = initialState.keyContacts,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_UPDATE_KEY_CONTACTS_SUCCESS:
    case API_GET_KEY_CONTACTS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.keyContacts,
        },
        orderedIds: action.data.result,
      }

    default:
      return state
  }
}
