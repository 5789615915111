import Select from '@weareroam/cake-ui-v1/Select'
import DropdownIcon from '@weareroam/cake-ui-v1-icons/ExpandMore'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledDropdownButton = styled.div`
  display: inline-flex;
  align-items: center;
`

const selectMenuClass = 'dropdown-filter-select-menu'
const paperClass = 'menu-no-shadow' // in theme file. workaround since this appears in a modal
export const StyledSelect = styled(Select).attrs(({ variant }) => ({
  classes: {
    selectMenu: selectMenuClass,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      horizontal: 'center',
      vertical: 'top',
    },
    getContentAnchorEl: null,
    classes: {
      paper: variant === 'outlined' ? paperClass : '',
    },
  },
}))`
  border-radius: 4px;

  && {
    &::after,
    &::before {
      border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
    }
  }

  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .${selectMenuClass} {
    color: ${({ theme }) => theme.palette.primary.main};
    padding-bottom: ${({ theme }) => theme.spacing.xs}px;
    font-weight: ${({ theme, size }) => {
      switch (size) {
        case 'small':
          return theme.weights.bold
        case 'medium':
        default:
          return theme.weights.regular
      }
    }};
    font-size: ${({ theme, size }) => {
      switch (size) {
        case 'small':
          return theme.typography.pxToRem(14)
        case 'medium':
        default:
          return theme.typography.pxToRem(18)
      }
    }};
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DropdownButton({
  children,
  name,
  value,
  onChange,
  className,
  renderValue,
  size,
  multiple,
  variant,
}) {
  return (
    <StyledDropdownButton>
      <StyledSelect
        size={size}
        IconComponent={DropdownIcon}
        value={value}
        onChange={onChange}
        displayEmpty
        name={name}
        variant={variant}
        className={className}
        multiple={multiple}
        renderValue={renderValue}>
        {children}
      </StyledSelect>
    </StyledDropdownButton>
  )
}

DropdownButton.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  renderValue: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  multiple: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined']),
}

DropdownButton.defaultProps = {
  size: 'medium',
  multiple: true,
  variant: 'standard',
}

export default DropdownButton
