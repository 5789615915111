import { connect } from 'react-redux'
import initialState from 'reducers/initialState'
import { bindActionCreators } from 'redux'

import { actions } from '../../../modules/journals/actions'
import { getJournalReplies } from '../../../modules/notifications/selectors'
import { getUser } from '../../../modules/user/selectors'

import {
  JournalEntryModal as inner,
  JournalEntryModalProps,
} from './JournalEntryModal'

// TODO: Could we please upgrade to react-redux@^7.1...
//  So we won't need this HoC contraption anymore and could use hooks inside the component
export const JournalEntryModal = connect(
  (state: typeof initialState, ownProps: JournalEntryModalProps) => ({
    replies: getJournalReplies(state, ownProps.journal.originalUuid),
    currentUser: getUser(state),
    getJournalReplyProgress: state.progress.getJournalReply,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(inner)
