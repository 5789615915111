import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.md}px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

interface DeleteTipFormProps {
  className?: string
  onSubmit: () => void
  onCancel: () => void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DeleteTipForm({
  className,
  onSubmit,
  onCancel,
}: DeleteTipFormProps) {
  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault()

      if (onSubmit) {
        onSubmit()
      }
    },
    [onSubmit],
  )

  return (
    <form
      onSubmit={handleOnSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledTitle variant="h4">
        Are you sure you would like to delete this tip?
      </StyledTitle>
      <StyledText variant="body1">
        This entry will be permanently removed.
      </StyledText>
      <StyledActions>
        <Button
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          No, cancel
        </Button>
        <Button variant="contained" type="submit" size="small" color="primary">
          Yes, delete
        </Button>
      </StyledActions>
    </form>
  )
}

export default DeleteTipForm
