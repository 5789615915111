import { JOURNAL_ENTRY_DATE_TIME, JOURNAL_ENTRY_TIMEZONE } from 'constants/date'

import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  justify-content: space-between;
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const Date = styled(Typography)`
  && {
    display: flex;
    align-items: center;
  }
`

export const Timezone = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const OriginalEntry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
  text-align: left;
`

export const OriginalEntryLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledTextArea = styled(TextField)`
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }

  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const ActionsLeft = styled.div``
export const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IncidentEntryForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onCancel,
  organisationName,
  groupName,
  date,
  originalEntry,
  onRevert,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledHeader>
        <Typography variant="body2">
          <strong>{organisationName}</strong> &mdash; {groupName}
        </Typography>
        <Date variant="body2" component="div">
          {format(date, JOURNAL_ENTRY_DATE_TIME)}
          <Timezone variant="body2">
            {format(date, JOURNAL_ENTRY_TIMEZONE)}
          </Timezone>
        </Date>
      </StyledHeader>
      <StyledTextArea
        id="entry"
        name="entry"
        type="text"
        value={values.entry}
        InputProps={{
          disableUnderline: true,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.entry && !!errors.entry}
        helperText={touched.entry ? errors.entry : ''}
        fullWidth
        multiline
        rows={5}
      />
      {originalEntry && (
        <OriginalEntry>
          <OriginalEntryLabel variant="body2">Original</OriginalEntryLabel>
          <Typography variant="body2">{originalEntry}</Typography>
        </OriginalEntry>
      )}
      <Actions>
        <ActionsLeft>
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={onRevert}>
            Revert to original
          </Button>
        </ActionsLeft>
        <ActionsRight>
          <Button
            variant="outlined"
            type="button"
            color="primary"
            fullWidth
            size="small"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            size="small">
            Save changes
          </Button>
        </ActionsRight>
      </Actions>
    </form>
  )
}

IncidentEntryForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  onCancel: PropTypes.func,
  organisationName: PropTypes.string,
  groupName: PropTypes.string,
  date: PropTypes.string,
  onRevert: PropTypes.func,
  originalEntry: PropTypes.string,
}

IncidentEntryForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default IncidentEntryForm
