import { NormalizedSchema, schema } from 'normalizr'

import { IPartnerOrganisation } from '../../models/PartnerOrganisation'
import { RequestProgressState } from '../progress'

export interface PartnerOrganisationsState {
  progress: {
    getPartnerOrganisations: RequestProgressState
  }
  partnerOrganisations: NormalizedSchema<
    { [key: string]: IPartnerOrganisation },
    string
  >
}

// Schema
export const partnerOrganisationSchema = new schema.Entity(
  'partnerOrganisations',
  {},
  { idAttribute: 'uuid' },
)
export const partnerOrganisationListSchema = [partnerOrganisationSchema]
