import Button from '@weareroam/cake-ui-v1/Button'
import DetailedSwitch from 'components/molecules/DetailedSwitch'
import SettingsPageContainer from 'components/molecules/SettingsPageContainer'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'

export const Content = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const StyledButton = styled(Button)`
  && {
    width: 100%;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Notifications({ actions }) {
  const [isSelected, setSelected] = useState(true)
  const handleUpdateClick = useCallback(
    () => {
      actions.updateProfileNotifications({ monthlyReport: isSelected })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelected],
  )
  useEffect(() => {
    actions.getProfileNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ChnnlDashboardLayout>
      <SettingsPageContainer title="Email notifications">
        <Content>
          <DetailedSwitch
            isSelected={isSelected}
            onChange={setSelected}
            title="Receive monthly reports"
            id="organisation-monthly-reports-toggle"
            description={`Receive monthly reports via email on\nhow all chnnl organisations are doing`}
          />
        </Content>
        <StyledButton
          size="medium"
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleUpdateClick}>
          Update
        </StyledButton>
      </SettingsPageContainer>
    </ChnnlDashboardLayout>
  )
}

Notifications.propTypes = {
  actions: PropTypes.object,
}

Notifications.defaultProps = {
  actions: {},
}

export default Notifications
