import { NormalizedSchema, schema } from 'normalizr'

import {
  ROLE_GROUP_ADMIN,
  ROLE_ORG_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_SUPER_ADMIN,
  ROLE_PARTNER_ADMIN,
} from '../../constants/form'
import { Group } from '../groups/schema'
import { RequestProgressState } from '../progress'

export interface Admin {
  uuid: string // Admin entity
  userUuid: string // User entity
  fullName: string
  email: string
  accepted: boolean
  role: AdminRoles
  editRights: boolean
  assignedGroups?: Group[]
}

export type AdminRoles =
  | typeof ROLE_SUPER_ADMIN
  | typeof ROLE_ORG_OWNER
  | typeof ROLE_ORG_ADMIN
  | typeof ROLE_GROUP_ADMIN
  | typeof ROLE_PARTNER_ADMIN

export interface AdminsState {
  progress: {
    getOrganisationAdmins: RequestProgressState
  }
  admins: NormalizedSchema<{ [key: string]: Admin }, string>
}

export const adminSchema = new schema.Entity(
  'admins',
  {},
  { idAttribute: 'userUuid' },
)
export const adminListSchema = [adminSchema]
