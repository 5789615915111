import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { Notification } from 'modules/notifications/schema'
import { useState, useCallback } from 'react'

export const useSurveyMessage = (): {
  request: {
    data: Notification | null
    error: boolean
    isLoading: boolean
  }
  sendMessage: typeof sendMessage
} => {
  const [request, setRequest] = useState({
    data: null,
    error: false,
    isLoading: false,
  })

  const sendMessage = useCallback(
    async ({ message, surveyId }: { message: string; surveyId: string }) => {
      try {
        setRequest((prevState) => ({ ...prevState, isLoading: true }))
        const res = await axios.post(
          `${SAUCE_API_URL}/survey/${surveyId}/message`,
          {
            type: 'chnnl',
            message,
          },
        )

        setRequest({
          data: res.data.data[0].entity,
          isLoading: false,
          error: false,
        })
      } catch (e: unknown) {
        setRequest({
          data: null,
          isLoading: false,
          error: true,
        })
      }
    },
    [],
  )
  return { request, sendMessage }
}
