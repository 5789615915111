import initialState from 'reducers/initialState'

import { API_GET_ORGANISATIONS_REQUEST } from '../organisations/actions'

import {
  API_CREATE_GROUP_SUCCESS,
  API_UPDATE_GROUP_SUCCESS,
  API_GET_GROUPS_SUCCESS,
  API_GET_GROUP_MEMBERS_SUCCESS,
  API_DELETE_GROUP_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function groupsReducer(state = initialState.groups, action) {
  switch (action.type) {
    case API_CREATE_GROUP_SUCCESS:
    case API_UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.groups,
        },
      }
    case API_GET_GROUPS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.groups,
        },
      }
    case API_GET_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        selected: action.data,
      }
    case API_DELETE_GROUP_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newEntities = { ...state.entities }
      delete newEntities[action.data.uuid]

      return {
        ...state,
        entities: newEntities,
      }
    case API_GET_ORGANISATIONS_REQUEST:
      return {
        ...state,
        entities: [],
      }
    default:
      return state
  }
}
