import initialState from 'reducers/initialState'

import {
  API_GET_REFLECTIONS_SUCCESS,
  API_ADD_ORGANISATION_REFLECTION_SUCCESS,
  API_DELETE_REFLECTION_SUCCESS,
  API_UPDATE_REFLECTION_SUCCESS,
  API_CREATE_REFLECTION_SUCCESS,
} from './actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function reflectionsReducer(
  state = initialState.reflections,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action,
) {
  switch (action.type) {
    case API_GET_REFLECTIONS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.reflections,
        },
        orderedIds: action.data.result,
      }
    case API_ADD_ORGANISATION_REFLECTION_SUCCESS:
      return {
        ...state,
        entities: action.data.entities.reflections,
        orderedIds: action.data.result,
      }
    case API_CREATE_REFLECTION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.reflections,
        },
        orderedIds: [...action.data.result, ...state.orderedIds],
      }
    case API_UPDATE_REFLECTION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.reflections,
        },
      }
    case API_DELETE_REFLECTION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newEntities = { ...state.entities }
      delete newEntities[action.data.uuid]
      return {
        ...state,
        entities: newEntities,
        orderedIds: state.orderedIds.filter((v) => v !== action.data.uuid),
      }

    default:
      return state
  }
}
