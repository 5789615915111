// eslint-disable-next-line @typescript-eslint/naming-convention
export const ROLE_SUPER_ADMIN = 'superAdmin'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ROLE_ORG_OWNER = 'orgOwner'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ROLE_GROUP_ADMIN = 'groupAdmin'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ROLE_ORG_ADMIN = 'orgAdmin'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ROLE_PARTNER_ADMIN = 'partnerAdmin'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ADMIN_PERMISSION_VIEW_ONLY = 'VIEW_ONLY'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ADMIN_PERMISSION_CAN_EDIT = 'CAN_EDIT'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_DIRECTION_DESC = 'SORT_DIRECTION_DESC'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_DIRECTION_ASC = 'SORT_DIRECTION_ASC'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_ALPHABETICAL = 'alphabetical'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_LEAST_MEMBERS = 'leastMembers'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_MOST_MEMBERS = 'mostMembers'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_LEAST_ADMINS = 'leastAdmins'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SORT_MOST_ADMINS = 'mostAdmins'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_TYPE_ALL = 'JOURNAL_TYPE_ALL'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_TYPE_TO_REVIEW = 'JOURNAL_TYPE_TO_REVIEW'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_RED_FLAGGED = 'JOURNAL_RED_FLAGGED'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_YELLOW_FLAGGED = 'JOURNAL_YELLOW_FLAGGED'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_TYPE_PRIVATE = 'JOURNAL_TYPE_PRIVATE'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_TYPE_APPROVED = 'JOURNAL_TYPE_APPROVED'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNAL_TYPE_HIDDEN = 'JOURNAL_TYPE_HIDDEN'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIP_TYPE_ALL = 'TIP_TYPE_ALL'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIP_THEME_CALM = 'TIP_THEME_CALM'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIP_THEME_ACTIVE = 'TIP_THEME_ACTIVE'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIP_THEME_RESTFUL = 'TIP_THEME_RESTFUL'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIP_THEME_INTERESTED = 'TIP_THEME_INTERESTED'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TIP_THEME_CHEERFUL = 'TIP_THEME_CHEERFUL'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const INCIDENT_TYPE_ALL = 'INCIDENT_TYPE_ALL'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const INCIDENT_TYPE_TO_REVIEW = 'INCIDENT_TYPE_TO_REVIEW'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const INCIDENT_TYPE_SHARED = 'INCIDENT_TYPE_SHARED'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const INCIDENT_TYPE_HIDDEN = 'INCIDENT_TYPE_HIDDEN'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MOOD_OVERALL = 'Overall'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MOOD_CHEERFUL = 'Cheerful'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MOOD_CALM = 'Calm'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MOOD_ACTIVE = 'Active'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MOOD_RESTED = 'Restful'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MOOD_INTERESTED = 'Interested'

export type Moods =
  | typeof MOOD_OVERALL
  | typeof MOOD_CHEERFUL
  | typeof MOOD_CALM
  | typeof MOOD_ACTIVE
  | typeof MOOD_RESTED
  | typeof MOOD_INTERESTED

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ATTRIBUTE_GENDER = 'gender'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ATTRIBUTE_AGE_RANGE = 'age'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ATTRIBUTE_ETHNICITY = 'ethnicity'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TOGGLE_ALL = 'TOGGLE_ALL'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OVERVIEW_TYPE_WELLBEING = 'OVERVIEW_TYPE_WELLBEING'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const OVERVIEW_TYPE_APP_ACTIVITY = 'OVERVIEW_TYPE_APP_ACTIVITY'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CHATBOT_CATEGORY_TYPE = 'chat_bot'
