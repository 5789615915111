import { DMYFull, HumanReadableDatetime } from 'constants/date'
import {
  JOURNAL_TYPE_TO_REVIEW,
  JOURNAL_TYPE_APPROVED,
  JOURNAL_TYPE_HIDDEN,
  JOURNAL_TYPE_PRIVATE,
} from 'constants/form'

import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import Typography from '@weareroam/cake-ui-v1/Typography'
import JournalChecked from 'components/atoms/Icons/JournalChecked'
import JournalHidden from 'components/atoms/Icons/JournalHidden'
import JournalShared from 'components/atoms/Icons/JournalShared'
import JournalUnchecked from 'components/atoms/Icons/JournalUnchecked'
import StatusTag from 'components/atoms/StatusTag'
import MoreActions from 'components/molecules/MoreActions'
import { NotificationCell } from 'components/organisms/NotificationCell/NotificationCell'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledJournalTask = styled.div`
  position: relative;
  background-color: ${({ theme, redFlagged, yellowFlagged }) =>
    redFlagged
      ? theme.palette.journals.red
      : yellowFlagged
      ? theme.palette.journals.yellow
      : theme.palette.secondary.main};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

export const JournalState = styled.div`
  width: 5%;
  z-index: 2;
`

export const JournalEntry = styled.div`
  width: ${({ readOnly }) => (readOnly ? '40%' : '30%')};
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }

  svg {
    width: 25px;
    height: 25px;
  }
`

export const JournalFrom = styled.div`
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  padding-left: ${({ theme }) => theme.spacing.md}px;
`

export const JournalSentiment = styled.div`
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const JournalDate = styled.div`
  text-align: right;
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const JournalActions = styled.div`
  width: 5%;
  text-align: right;
  z-index: 2;
`

export const JournalFingerprint = styled.div`
  width: 15%;
  text-align: left;
  z-index: 2;
`

export const sentimentColours = {
  positive: 'success',
  neutral: 'quinary',
  negative: 'primary',
}

export const EntryText = styled(Typography)`
  && {
    display: inline;
  }
`

export const EditText = styled(Typography)`
  && {
    display: inline;
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`

const StyledNotificationCell = styled.div`
  flex: 1;
  padding-left: ${({ isJournalModerationView }) =>
    !isJournalModerationView ? '32px' : '0'};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function JournalTask({
  dataQa,
  className,
  status,
  shared,
  entry,
  hasEntryBeenEdited,
  sentiment,
  organisationName,
  groupName,
  date,
  createdAt,
  actions,
  onClick,
  checked,
  onCheck,
  name,
  value,
  readOnly,
  redFlagged,
  yellowFlagged,
  isJournalModerationView,
  journalReplies = [],
  userFingerprint = '',
}) {
  const handleOnClick = useCallback(
    () => {
      if (onClick) {
        onClick(value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  //Can only have one reply, this might change if there are more in future
  const acknowledged = journalReplies.some((reply) => reply.acknowledged)

  return (
    <StyledJournalTask
      redFlagged={redFlagged}
      yellowFlagged={yellowFlagged}
      className={className}
      data-qa={dataQa}>
      {!readOnly && <ClickArea onClick={handleOnClick} />}
      {!readOnly && (
        <JournalState>
          {status === JOURNAL_TYPE_PRIVATE && <JournalHidden />}
          {status === JOURNAL_TYPE_TO_REVIEW && (
            <StyledCheckbox
              icon={<JournalUnchecked />}
              checkedIcon={<JournalChecked />}
              onChange={onCheck}
              name={name}
              checked={checked}
              value={value}
            />
          )}
          {status === JOURNAL_TYPE_APPROVED && <JournalShared />}
          {status === JOURNAL_TYPE_HIDDEN && <JournalHidden />}
        </JournalState>
      )}
      {!readOnly && (
        <JournalFingerprint>
          <EntryText>{`${userFingerprint.slice(0, 3)}-${userFingerprint.slice(
            3,
            7,
          )}`}</EntryText>
        </JournalFingerprint>
      )}
      <JournalEntry readOnly={readOnly}>
        <EntryText variant="body1" component="span">
          {entry}
        </EntryText>
        {hasEntryBeenEdited && (
          <EditText variant="body2" component="span">
            Edited
          </EditText>
        )}
        {!shared && (
          <EditText variant="body2" component="span">
            Private
          </EditText>
        )}
      </JournalEntry>
      <StyledNotificationCell isJournalModerationView={isJournalModerationView}>
        <NotificationCell
          displayText={isJournalModerationView ? 'Sent' : 'Replied by chnnl'}
          acknowledged={acknowledged}
          createdAt={
            journalReplies && journalReplies[0]
              ? journalReplies[0].createdAt
              : null
          }
        />
      </StyledNotificationCell>
      {isJournalModerationView && (
        <JournalSentiment>
          {sentiment && (
            <StatusTag themeColor={sentimentColours[sentiment]}>
              {sentiment}
            </StatusTag>
          )}
        </JournalSentiment>
      )}
      <JournalFrom>
        <Typography variant="body2">
          <strong>{organisationName}</strong>
        </Typography>
        <Typography variant="body2">{groupName}</Typography>
      </JournalFrom>
      <JournalDate>
        <Typography variant="body2">
          <strong>{format(date, DMYFull)}</strong>
        </Typography>
        {!readOnly && (
          <Typography variant="body2">
            {format(createdAt, HumanReadableDatetime)}
          </Typography>
        )}
      </JournalDate>
      {!readOnly && (
        <JournalActions>
          {actions && (
            <MoreActions
              direction="vertical"
              color="primary"
              actions={actions}
            />
          )}
        </JournalActions>
      )}
    </StyledJournalTask>
  )
}

JournalTask.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  status: PropTypes.oneOf([
    JOURNAL_TYPE_PRIVATE,
    JOURNAL_TYPE_TO_REVIEW,
    JOURNAL_TYPE_APPROVED,
    JOURNAL_TYPE_HIDDEN,
  ]),
  shared: PropTypes.bool,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  entry: PropTypes.string,
  hasEntryBeenEdited: PropTypes.bool,
  organisationName: PropTypes.string,
  groupName: PropTypes.string,
  date: PropTypes.string,
  createdAt: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  redFlagged: PropTypes.bool,
  yellowFlagged: PropTypes.bool,
  sentiment: PropTypes.string,
  isJournalModerationView: PropTypes.bool,
  journalReplies: PropTypes.arrayOf(PropTypes.object),
  userFingerprint: PropTypes.string,
}

export default JournalTask
