// eslint-disable-next-line import/no-named-as-default
import NewDemoOrganisationForm from './NewDemoOrganisationForm'
import enhance from './NewDemoOrganisationForm.enhancer'

export default enhance(NewDemoOrganisationForm)

export interface FormValues {
  name: string
  email: string
}

export interface FormProps {
  className: string
  onSubmit: (values: FormValues) => void
  onClose: () => void
  name: string
  email: string
  initialValues: FormValues
}
