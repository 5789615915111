import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import MaterialTableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import EmptyState from 'components/molecules/EmptyState'
import { Modal } from 'components/molecules/Modal'
import { InterventionForm } from 'components/organisms/InterventionForm'
import { useDistressedUsers } from 'hooks/useDistressedUsers'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import { DistressedUserLine } from 'models/DistressedUserLine'
import React from 'react'
import styled from 'styled-components'

import { DistressedUsersHeader } from './DistressedUsers.header'
import { DistressedUserRow } from './DistressedUsers.row'

const StyledTable = styled(Table)`
  min-width: 900px;
  && {
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
`

const InterventionModal = styled(Modal)`
  min-width: 900px;
`

const HeadingLabel = styled(Typography)`
  && {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`

const TableHeader = ({
  children,
  align = 'right',
}: { children: React.ReactNode } & TableCellProps) => (
  <MaterialTableCell scope="col" component="th" align={align}>
    <HeadingLabel variant="body1">{children}</HeadingLabel>
  </MaterialTableCell>
)

const DistressedUsersTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableHeader align="left">User Fingerprint</TableHeader>
      <TableHeader>Avg Score</TableHeader>
      <TableHeader>Distressed Surveys</TableHeader>
      <TableHeader>
        <strong>Org</strong> / Group
      </TableHeader>
      <TableHeader>Most Recent Distressed Event</TableHeader>
      <TableHeader>Most Recent Message</TableHeader>
      <TableHeader># PNs</TableHeader>
    </TableRow>
  </TableHead>
)

type TabIds = 'intervention'

const tabs: {
  id: TabIds
  title: string
}[] = [{ id: 'intervention', title: 'Message User' }]

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DistressedUsers = (): React.ReactElement => {
  const [filteredOrgs, setFilteredOrgs] = React.useState<string[]>([])
  const [selectedLine, setSelectedLine] =
    React.useState<DistressedUserLine | null>(null)

  const { data, isLoading, hasError, handleChangeFilter, refetch } =
    useDistressedUsers(filteredOrgs)

  return (
    <ChnnlDashboardLayout>
      <InterventionModal
        maxWidth="md"
        tabs={tabs}
        open={!!selectedLine}
        hasCloseButton
        onClose={() => setSelectedLine(null)}>
        {selectedLine && (
          <InterventionForm
            onSuccess={refetch}
            data={selectedLine}
            onCancel={() => setSelectedLine(null)}
          />
        )}
      </InterventionModal>
      <ContentContainer>
        <DistressedUsersHeader
          onOrganistionsChange={(val) => setFilteredOrgs(val)}
          onFiltersChange={(val) => handleChangeFilter(val)}
        />
        <StyledTable>
          {isLoading && (
            <>
              <StyledTable>
                <DistressedUsersTableHeader />
              </StyledTable>
              <ProgressLoader fullWidth />
            </>
          )}
          {!isLoading && hasError && (
            <EmptyState
              title="Oh no! We’re not able to show this page right now."
              childComponent={
                <span>
                  We’ve been notified and will take a look but if the problem
                  persists then let us know.
                </span>
              }
            />
          )}
          {!isLoading && !hasError && (
            <StyledTable>
              <DistressedUsersTableHeader />
              <TableBody>
                {data.map((row, i) => (
                  <DistressedUserRow
                    key={i}
                    {...row}
                    onClick={() => setSelectedLine(row)}
                  />
                ))}
              </TableBody>
            </StyledTable>
          )}
        </StyledTable>
      </ContentContainer>
    </ChnnlDashboardLayout>
  )
}
