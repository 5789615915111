import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { partnerAdminListSchema } from './schema'

// Actions
export const API_GET_PARTNER_ADMINS_REQUEST = 'API_GET_PARTNER_ADMINS_REQUEST'
export const API_GET_PARTNER_ADMINS_SUCCESS = 'API_GET_PARTNER_ADMINS_SUCCESS'
export const API_GET_PARTNER_ADMINS_ERROR = 'API_GET_PARTNER_ADMINS_ERROR'
export const API_CREATE_PARTNER_ADMIN_REQUEST =
  'API_CREATE_PARTNER_ADMIN_REQUEST'
export const API_CREATE_PARTNER_ADMIN_SUCCESS =
  'API_CREATE_PARTNER_ADMIN_SUCCESS'
export const API_CREATE_PARTNER_ADMIN_ERROR = 'API_CREATE_PARTNER_ADMIN_ERROR'
export const API_DELETE_PARTNER_ADMIN_REQUEST =
  'API_DELETE_PARTNER_ADMIN_REQUEST'
export const API_DELETE_PARTNER_ADMIN_SUCCESS =
  'API_DELETE_PARTNER_ADMIN_SUCCESS'
export const API_DELETE_PARTNER_ADMIN_ERROR = 'API_DELETE_PARTNER_ADMIN_ERROR'

// Action creators

export function getPartnerAdmins(partnerUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_PARTNER_ADMINS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/partner/${partnerUuid}/admin`,
      )

      const data = normalize(
        response.data.data[0].output,
        partnerAdminListSchema,
      )

      dispatch({
        type: API_GET_PARTNER_ADMINS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_PARTNER_ADMINS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error while fetching the partner admins. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function createPartnerAdmin({
  partnerUuid,
  email,
  firstName,
  lastName,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_PARTNER_ADMIN_REQUEST })

    try {
      await axios.post(`${SAUCE_API_URL}/partner/${partnerUuid}/admin`, {
        email: email,
        firstName: firstName,
        lastName: lastName,
      })

      dispatch({
        type: API_CREATE_PARTNER_ADMIN_SUCCESS,
        data: {
          uuid: partnerUuid,
        },
      })

      dispatch(
        addToast({
          message: `Partner admin was successfully created.`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_CREATE_PARTNER_ADMIN_ERROR, error })
      dispatch(
        addToast({
          message: `Partner admin was unable to be created.`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export function deletePartnerAdmin(partnerUuid, partnerAdminUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_PARTNER_ADMIN_REQUEST })

    try {
      await axios.delete(
        `${SAUCE_API_URL}/partner/${partnerUuid}/admin/${partnerAdminUuid}`,
      )

      dispatch({
        type: API_DELETE_PARTNER_ADMIN_SUCCESS,
        data: {
          uuid: partnerUuid,
        },
      })

      dispatch(
        addToast({
          message: `Partner admin was successfully deleted.`,
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_DELETE_PARTNER_ADMIN_ERROR, error })
      dispatch(
        addToast({
          message: `Partner admin was unable to be deleted.`,
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

export const partnerOrganisationActions = {
  getPartnerAdmins,
  createPartnerAdmin,
  deletePartnerAdmin,
}
