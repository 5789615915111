import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function JournalSubmit(props) {
  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="molecule-/-backlog-/-table-headings"
          transform="translate(-11.000000, -4.000000)">
          <g id="Group" transform="translate(11.000000, 4.000000)">
            <circle id="Oval" fill="#3574FA" cx="13" cy="13" r="13" />
            <path
              d="M8,6.44126297 L8,11.13761 C8,11.3691378 8.17942611,11.5610558 8.41043054,11.5766153 L18.6994618,12.269639 C18.9419177,12.2859698 19.1252284,12.4957579 19.1088976,12.7382138 C19.0940993,12.9579177 18.9191657,13.1328513 18.6994618,13.1476496 L8.41043054,13.8406734 C8.17942611,13.8562328 8,14.0481509 8,14.2796787 L8,18.9760257 C8,19.219031 8.19699471,19.4160257 8.44,19.4160257 C8.50425048,19.4160257 8.56772142,19.4019545 8.62595213,19.3748011 L22.0663868,13.1074197 C22.2866243,13.0047212 22.3819086,12.7429298 22.27921,12.5226922 C22.2354922,12.4289391 22.1601399,12.3535867 22.0663868,12.3098689 L8.62595213,6.04248759 C8.40571456,5.93978906 8.14392314,6.03507328 8.04122462,6.25531085 C8.01407118,6.31354155 8,6.37701249 8,6.44126297 Z"
              id="Path-11"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default JournalSubmit
