import { useState, useMemo, useCallback } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useEntitiesSearch(entities, property) {
  const [searchFilter, setSearchFilter] = useState('')

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
  }, [])

  const filteredEntities = useMemo(() => {
    const searchNestedProperties = property ? property.includes('.') : false

    return entities.filter((entity) => {
      let entityPropertyToSearch = entity
      if (searchNestedProperties) {
        for (const nestedProperty of property.split('.')) {
          const nestedEntity = entityPropertyToSearch[nestedProperty]
          if (nestedEntity) {
            entityPropertyToSearch = nestedEntity
          }
        }
      }
      const lcEntity = searchNestedProperties
        ? entityPropertyToSearch.toLowerCase()
        : entity[property || 'name'].toLowerCase().toLowerCase()
      const lcSearchFilter = searchFilter.toLowerCase()

      return lcEntity.includes(lcSearchFilter)
    })
  }, [searchFilter, entities, property])

  return {
    searchFilter,
    handleChangeSearchFilter,
    filteredEntities,
  }
}

export default useEntitiesSearch
