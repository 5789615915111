import { DistressedUserLine } from 'models/DistressedUserLine'
import { User } from 'modules/user/schema'
import { connect } from 'react-redux'
import initialState from 'reducers/initialState'
import { bindActionCreators } from 'redux'

import { actions } from '../../../modules/journals/actions'
import { getUser } from '../../../modules/user/selectors'

import { InterventionForm as InterventionFormComponent } from './InterventionForm'

export interface InnerFormProps {
  onCancel: () => void
  onSuccess: () => void
  data: DistressedUserLine
  currentUser: User
}

export const InterventionForm = connect(
  (state: typeof initialState) => ({
    currentUser: getUser(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(InterventionFormComponent)
