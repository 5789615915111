import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'

import { pathwayListSchema } from './schema'

// Actions
export const API_GET_PATHWAYS_REQUEST = 'API_GET_PATHWAYS_REQUEST'
export const API_GET_PATHWAYS_SUCCESS = 'API_GET_PATHWAYS_SUCCESS'
export const API_GET_PATHWAYS_ERROR = 'API_GET_PATHWAYS_ERROR'

export const API_UPDATE_PATHWAY_REQUEST = 'API_UPDATE_PATHWAY_REQUEST'
export const API_UPDATE_PATHWAY_SUCCESS = 'API_UPDATE_PATHWAY_SUCCESS'
export const API_UPDATE_PATHWAY_ERROR = 'API_UPDATE_PATHWAY_ERROR'

export const API_RESET_PATHWAY_REQUEST = 'API_RESET_PATHWAY_REQUEST'
export const API_RESET_PATHWAY_SUCCESS = 'API_RESET_PATHWAY_SUCCESS'
export const API_RESET_PATHWAY_ERROR = 'API_RESET_PATHWAY_ERROR'

// Action creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPathways(orgUuid) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_PATHWAYS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/pathway`,
      )
      const data = normalize(
        response.data.data.map((pathway) => ({
          orgUuid,
          ...pathway,
        })),
        pathwayListSchema,
      )

      dispatch({
        type: API_GET_PATHWAYS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_PATHWAYS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the pathways. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updatePathway({ orgUuid, pathwayUuid, title, message, items }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_PATHWAY_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/pathway/${pathwayUuid}`,
        {
          title,
          message,
          items,
        },
      )
      const data = normalize(
        response.data.data.map((pathway) => ({
          orgUuid,
          ...pathway,
        })),
        pathwayListSchema,
      )
      dispatch({
        type: API_UPDATE_PATHWAY_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Pathway has been updated.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_PATHWAY_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error updating the pathway. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resetPathway({ orgUuid, pathwayUuid }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_PATHWAY_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/pathway/${pathwayUuid}/revert`,
      )
      const data = normalize(
        response.data.data.map((pathway) => ({
          orgUuid,
          ...pathway,
        })),
        pathwayListSchema,
      )

      dispatch({
        type: API_RESET_PATHWAY_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Pathway has been reset.',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: API_RESET_PATHWAY_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error resetting the pathway. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
