import { CountryDialCodes } from 'constants/CountryDialCodes'

import { createSelector } from 'reselect'

// selectors
export const getOrganisationsList = createSelector(
  (state) => state.organisations.entities,
  (organisations) => Object.values(organisations),
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const organisationDialCode = (code) => CountryDialCodes[code]
